<template>
  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <CreditProposalAssessmentCommitteeOverview
    v-else-if="record"
    :initial-record="record"
    :contact-persons="companyRecord?.contact_persons"
    :credit-proposal-contact-person="computedCreditProposalContactPerson"
    :revision-record="revisionRecord"
    :document-files="customerDocumentFiles"
    @save="updateLogbookAndRevision"
  />
</template>

<script setup>
import apiClient from '@/services/ApiClient';
import CreditProposalAssessmentCommitteeOverview from '@/forms/sales/sales-status/credit-proposal/CreditProposalAssessmentCommitteeOverview.vue';
import { useSetToast } from '@/composables/UseToast';
import { useSetAndMergeSalesStatusObject } from '@/composables/records/UseAssessmentCommittee';
import { useAmountSuffixConvertCentsToAmount } from '@/composables/UseNumberManipulation';
import {
  useGetCompany,
  useGetCreditProposalPreview,
  useGetProspectCreditProposalStatus,
  useSetCreditProposalPreview,
  useSetLogbookItems,
} from '@/composables/UseProspectViewData';
import { APPROVED } from '@/configs/constants/Status';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();
const record = ref(null);
const companyRecord = ref(null);
const revisionRecord = ref([]);
const customerDocumentFiles = ref(null);
const loading = ref(false);

const computedCreditProposalContactPerson = computed(() => {
  return structuredClone(companyRecord.value).contact_persons.find(
    (contactPerson) => contactPerson.person.id === record.value.data.sales_status?.credit_proposal?.person?.id
  );
});

watch(
  () => router.currentRoute.value,
  (to, from) => {
    if (from.meta.preview !== 'credit_proposal') {
      getAndSetCreditProposalPreview();
    }
    if (to.meta.preview !== 'credit_proposal') {
      useSetCreditProposalPreview('');
    }
  }
);

onBeforeMount(async () => {
  await getAndSetAssessmentCommittee();

  if (!useGetCreditProposalPreview()) {
    await getAndSetCreditProposalPreview();
  }
});

async function getAndSetAssessmentCommittee() {
  try {
    loading.value = true;
    record.value = await getAssessmentCommittee();

    record.value.revision = record.value.revision
      ? structuredClone(useAmountSuffixConvertCentsToAmount(record.value.revision))
      : {};
    revisionRecord.value = structuredClone(record.value.revision);
    companyRecord.value = useGetCompany();
    customerDocumentFiles.value = record.value.data.sales_status?.customer_documents?.files;

    record.value.sales_status = structuredClone(
      useAmountSuffixConvertCentsToAmount(useSetAndMergeSalesStatusObject(record.value.data))
    );
  } catch (error) {
    useSetToast(
      'error',
      t('toast.error.retrieving_credit_proposal_assessment_committee') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching credit proposal assessment committee data: ', error);
  } finally {
    loading.value = false;
  }
}

async function getAndSetCreditProposalPreview() {
  try {
    const creditProposalPreviewRecord = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/preview`
    );
    useSetCreditProposalPreview(creditProposalPreviewRecord);
  } catch (error) {
    useSetToast(
      'error',
      t('toast.error.retrieving_credit_proposal_preview') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching credit proposal preview: ', error);
  }
}

async function getAssessmentCommittee() {
  return await apiClient.request(
    'get',
    `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/assessment_committee`
  );
}

async function updateLogbookAndRevision(object) {
  if (useGetProspectCreditProposalStatus() === APPROVED) {
    await getAndSetAssessmentCommittee();
  }

  useSetLogbookItems(object.customerLogs);
}
</script>
