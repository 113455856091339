<template>
  <BaseInput
    v-model="computedModelValue"
    :class="`text-${props.textPosition}`"
    :errors="props.errors"
    :not-narrow="props.notNarrow"
    @input="updateValue"
    @paste="onPaste"
    @click="onClick"
    @focus="useOnFocus"
  />
</template>

<script setup>
import BaseInput from '@/components/form/BaseInput.vue';
import { useOnClick, useOnFocus, useOnPaste } from '@/composables/UseValidateNumberInput';
import useModelWrapper from '@/composables/UseComputedMethods';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },
  notInline: {
    type: Boolean,
    default: false,
  },
  notNarrow: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array,
    default: () => [],
  },
  decimals: {
    type: Number,
    default: 0,
  },
  textPosition: {
    type: String,
    default: 'end',
    validator(value) {
      return ['start', 'center', 'end'].includes(value);
    },
  },
});

const emit = defineEmits(['update:modelValue']);
const computedModelValue = useModelWrapper(props, emit, 'modelValue');

function onPaste(event) {
  let validatedText = useOnPaste(event, props.decimals);
  if (validatedText) {
    emit('update:modelValue', validatedText);
  }
}

function onClick(event) {
  useOnClick(event, props.decimals);
}

function updateValue(event) {
  emit('update:modelValue', event.target.value);
}
</script>
