<template>
  <InputGroup
    :id="labelId"
    v-model="computedModelValue"
    :inner-label="computedInnerLabel"
    type="text"
    :errors="props.errors"
    :show-error-messages="props.showErrorMessages"
  />
</template>

<script setup>
import InputGroup from '@/components/form/InputGroup.vue';
import useModelWrapper from '@/composables/UseComputedMethods';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  labelId: {
    type: String,
    default: null,
  },
  innerLabel: {
    type: String,
    default: '',
  },
  errors: {
    type: Array,
    default: () => [],
  },
  showErrorMessages: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue', 'update:innerLabel']);

const computedModelValue = useModelWrapper(props, emit, 'modelValue');
const computedInnerLabel = useModelWrapper(props, emit, 'innerLabel');
</script>
