<template>
  <LabelTextarea
    v-model="record.background_request_description"
    :errors="errorMessages['background_request_description']"
    :label-content="$t('sales.credit_proposal.notes.background_request')"
    :placeholder="$t('sales.credit_proposal.notes.what_is_the_background_of_the_request')"
    :label-columns="labelColumns"
    :input-columns="inputColumns"
    rows="6"
    class="mb-3"
  />
  <LabelTextarea
    v-model="record.main_risks"
    :errors="errorMessages['main_risks']"
    :label-content="$t('sales.credit_proposal.notes.main_risks')"
    :placeholder="$t('sales.credit_proposal.notes.what_are_the_main_risks')"
    :label-columns="labelColumns"
    :input-columns="inputColumns"
    rows="6"
    class="mb-3"
  />
  <LabelTextarea
    v-model="record.pricing_description"
    :errors="errorMessages['pricing_description']"
    :label-content="$t('sales.credit_proposal.notes.pricing')"
    :placeholder="$t('sales.credit_proposal.notes.pricing')"
    :label-columns="labelColumns"
    :input-columns="inputColumns"
    rows="6"
    class="mb-3"
  />
  <LabelTextarea
    v-model="record.decision_description"
    :errors="errorMessages['decision_description']"
    :label-content="$t('sales.credit_proposal.notes.decision')"
    :placeholder="$t('sales.credit_proposal.notes.what_is_the_decision')"
    :label-columns="labelColumns"
    :input-columns="inputColumns"
    rows="6"
    class="mb-3"
  />
  <LabelTextarea
    v-model="record.actions_to_be_taken"
    :errors="errorMessages['actions_to_be_taken']"
    :label-content="$t('sales.credit_proposal.notes.actions_still_to_be_taken')"
    :placeholder="$t('sales.credit_proposal.notes.what_actions_still_need_to_be_taken')"
    :label-columns="labelColumns"
    :input-columns="inputColumns"
    rows="6"
    wrapper-class="flex-grow-1"
  />
  <FormFooter
    :pending="loading"
    :disabled="_.isEqual(record, originalRecord) || loading"
    @save="handleSave"
    @cancel="handleCancel"
  />
</template>

<script setup>
import LabelTextarea from '@/components/form/LabelTextarea.vue';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import apiClient from '@/services/ApiClient';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import { ref, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { nanoid } from 'nanoid';
import { useRouter } from 'vue-router';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
});
const emit = defineEmits(['save']);

const router = useRouter();
const { t } = useI18n();

const record = ref(props.initialRecord);
const originalRecord = ref({});
const validationRules = ref({});
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const labelColumns = 'col-12 col-lg-4 col-xl-2';
const inputColumns = 'col-12 col-lg-5 col-xl-4';

onBeforeMount(async () => {
  useBeforeRouteLeave();
  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);
  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);
});

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  try {
    loading.value = true;
    let response;
    let recordCopy = structuredClone(record.value);
    recordCopy.deviating_provisions = JSON.stringify(record.value.deviating_provisions);
    recordCopy.file_ids = JSON.stringify(record.value.file_ids);

    if (!record.value.id) {
      response = await apiClient.request(
        'post',
        `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal`,
        null,
        recordCopy
      );
      useSetToast('success', t('toast.success.credit_proposal_notes_successfully_created'));
      record.value.id = response.data.id;
    } else {
      response = await apiClient.request(
        'put',
        `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/${record.value.id}`,
        null,
        recordCopy
      );
      useSetToast('success', t('toast.success.credit_proposal_notes_successfully_updated'));
    }

    emit('save', { customerLogs: response.sales_status.customer_log });

    record.value = useSetRecord(response.data, formId);
    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    if (!record.value.id) {
      useSetToast(
        'error',
        t('toast.error.creating_credit_proposal_notes') + ':' + '<br>' + error?.response?.data?.message
      );
      console.error('Error while updating credit proposal notes: ', error);
    } else {
      useSetToast(
        'error',
        t('toast.error.updating_credit_proposal_notes') + ':' + '<br>' + error?.response?.data?.message
      );
      console.error('Error while creating credit proposal notes: ', error);
    }
  } finally {
    loading.value = false;
  }
}

function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  errorMessages.value = [];
}
</script>
