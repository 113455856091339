import i18n from '@/lang/i18n-config';

const { t } = i18n.global;

const LEAD = 'LEAD';
const PROSPECT = 'PROSPECT';
const OFFER = 'OFFER';
const CREDIT_PROPOSAL = 'CREDIT_PROPOSAL';
const CONTRACT = 'CONTRACT';
const CLIENT = 'CLIENT';
const REJECTED = 'REJECTED';
const PASSED = 'PASSED';

const TRANSLATED_CUSTOMER_STATUS = {
  [LEAD]: t('sales.status.lead'),
  [PROSPECT]: t('sales.status.prospect'),
  [OFFER]: t('sales.status.offer'),
  [CREDIT_PROPOSAL]: t('sales.status.credit_proposal'),
  [CONTRACT]: t('sales.status.contract'),
  [CLIENT]: t('sales.status.client'),
};
const TRANSLATED_CUSTOMER_ABORT_STATUS = {
  [REJECTED]: t('sales.status.rejected'),
  [PASSED]: t('sales.status.passed'),
};

export {
  TRANSLATED_CUSTOMER_STATUS,
  TRANSLATED_CUSTOMER_ABORT_STATUS,
  CLIENT,
  CONTRACT,
  CREDIT_PROPOSAL,
  OFFER,
  PROSPECT,
  LEAD,
  REJECTED,
  PASSED,
};
export default {
  TRANSLATED_CUSTOMER_STATUS,
  TRANSLATED_CUSTOMER_ABORT_STATUS,
  CLIENT,
  CONTRACT,
  CREDIT_PROPOSAL,
  OFFER,
  PROSPECT,
  LEAD,
  REJECTED,
  PASSED,
};
