import i18n from '@/lang/i18n-config';

const { t } = i18n.global;

const COLLECTIONS_AND_OPERATIONS = t('constants.contact_types.collections_and_operations');
const CLIENT_MANAGEMENT = t('constants.contact_types.client_management');
const MANAGEMENT = t('constants.contact_types.management');
const MISC = t('constants.contact_types.misc');

export { COLLECTIONS_AND_OPERATIONS, CLIENT_MANAGEMENT, MANAGEMENT, MISC };
export default { COLLECTIONS_AND_OPERATIONS, CLIENT_MANAGEMENT, MANAGEMENT, MISC };
