<template>
  <BaseRow>
    <BaseColumn class="col-lg-4 col-12">
      <SearchCreditsafeCompany
        :sales-status-id="props.sales_status_id"
        @loading="setLoading"
        @load-companies="setCompanies"
      />
    </BaseColumn>
    <BaseSpinner
      v-if="loading"
      type="primary"
    />
    <BaseColumn
      v-else-if="!_.isEmpty(companies)"
      class="col-lg-8 col-12"
    >
      <CreditsafeTable
        :record="companies"
        @load-company="setCompany"
      />
    </BaseColumn>
  </BaseRow>
  <CreditsafeShowCompanyModal
    v-if="showCreditsafeShowModal && !_.isEmpty(company)"
    :record="company"
    :is-creditsafe-data="!_.isEmpty(creditsafeData)"
    @close="closeCreditsafeShowModal"
    @submit="setUseCreditsafeData"
  />
  <CreditsafeConfirmCompanyModal
    v-if="showCreditsafeConfirmModal"
    :initial-record="creditsafeData"
    :loading="loading"
    @close="closeCreditsafeConfirmModal"
    @cancel="cancelCreditsafeChanges"
    @previous="openCreditsafeShowModal"
    @submit="submitCreditsafeData"
  />
</template>

<script setup>
import SearchCreditsafeCompany from '@/forms/sales/sales-status/creditsafe/SearchCreditsafeCompany.vue';
import CreditsafeTable from '@/components/lists/CreditsafeTable.vue';
import CreditsafeShowCompanyModal from '@/forms/sales/sales-status/creditsafe/CreditsafeShowCompanyModal.vue';
import CreditsafeConfirmCompanyModal from '@/forms/sales/sales-status/creditsafe/CreditsafeConfirmCompanyModal.vue';
import apiClient from '@/services/ApiClient';
import { useEnumerationsStore } from '@/stores/enumerations';
import { useSetToast } from '@/composables/UseToast';
import { nl_NL } from '@/configs/constants/LocaleCodes';
import { BaseRow, BaseColumn, BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref } from 'vue';
import _ from 'lodash';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();
const enums = useEnumerationsStore();

const companies = ref({});
const company = ref({});
const showCreditsafeShowModal = ref(true);
const showCreditsafeConfirmModal = ref(false);
const creditsafeData = ref(null);
const loading = ref(false);
let originalCreditsafeData = ref(null);
let salesStatusRecord = null;

const props = defineProps({
  sales_status_id: {
    type: [Number, String],
    default: null,
  },
});

onBeforeMount(async () => {
  if (props.sales_status_id) {
    try {
      salesStatusRecord = await apiClient.request(
        'get',
        `/ifapi/sales_statuses/${props.sales_status_id}/customer_data`,
        {
          include: [
            'intermediary',
            'sales_status.all_statuses',
            'sales_status.company.address',
            'sales_status.company.company_person_contacts.contact_detail',
            'sales_status.company.company_person_contacts.contact_types',
            'sales_status.company.legal_form',
            'sales_status.customerData.accounting_software',
            'sales_status.customerData.customer_data_financial_facilities.financial_facility',
          ],
        }
      );
    } catch (error) {
      useSetToast('error', t('toast.error.retrieving_customer_data') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while fetching customer data: ', error);
    }
  }
});

async function submitCreditsafeData(creditsafeCompany) {
  let salesStatus = mapSalesStatusObject(creditsafeCompany);

  if (props.sales_status_id) {
    salesStatus = mergeWithFetchedSalesStatusRecord(salesStatus);
  }
  try {
    loading.value = true;
    let savedSalesStatus;

    if (!props.sales_status_id) {
      salesStatus.sales_status.currency = 'EUR';
      salesStatus.sales_status.correspondence_language = nl_NL;
      savedSalesStatus = await storeSalesStatus(salesStatus);
    } else {
      savedSalesStatus = await updateSalesStatus(salesStatus);
      savedSalesStatus = savedSalesStatus.sales_status;
    }

    await router.push({ name: 'edit sales status', params: { sales_status_id: savedSalesStatus.id } });
    setShowCreditsafeConfirmModal(false);
  } catch (error) {
    if (!props.sales_status_id) {
      useSetToast('error', t('toast.error.creating_customer_data') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while creating customer data: ', error);
    } else {
      useSetToast('error', t('toast.error.updating_customer_data') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while updating customer data: ', error);
    }
  } finally {
    loading.value = false;
  }
}

async function storeSalesStatus(salesStatus) {
  return await apiClient.request('post', '/ifapi/sales_statuses', null, salesStatus);
}
async function updateSalesStatus(salesStatus) {
  return await apiClient.request(
    'put',
    `/ifapi/sales_statuses/${props.sales_status_id}/customer_data`,
    { include: ['sales_status.company.legal_form'] },
    salesStatus
  );
}
function mapSalesStatusObject(creditsafeCompany) {
  const country = getCountry(creditsafeCompany);
  const legalForm = Object.entries(enums.data.legal_forms).find((legalForm) => {
    return legalForm[1] === creditsafeCompany.legalForm;
  });
  let address = creditsafeCompany?.street;

  if (creditsafeCompany?.houseNumber) {
    address = creditsafeCompany?.street + ' ' + creditsafeCompany?.houseNumber;
  }

  return {
    sales_status: {
      company: {
        name: creditsafeCompany.name,
        legal_form: {
          id: legalForm?.[0] ? parseInt(legalForm[0]) : null,
          name: creditsafeCompany.legalForm,
        },
        registered_office: creditsafeCompany.registeredOffice,
        phone: creditsafeCompany.telephone,
        website: creditsafeCompany.website,
        company_registration_number: creditsafeCompany.regNo,
        vat_number: creditsafeCompany.vatNo,
        address: {
          address: address,
          postal_code: creditsafeCompany.postalCode,
          city: creditsafeCompany.city,
          country_id: country?.id ? parseInt(country.id) : null,
        },
      },
    },
    customer_data: {
      branch: creditsafeCompany.branch,
    },
  };
}

function mergeWithFetchedSalesStatusRecord(salesStatusWithCreditSafeData) {
  return _.mergeWith({}, salesStatusRecord, salesStatusWithCreditSafeData, (a, b) => (!b ? a : undefined));
}

function getCountry(creditsafeCompany) {
  return enums.data.countries.find((country) => country.iso === creditsafeCompany.country);
}

function closeCreditsafeShowModal() {
  setShowCreditsafeShowModal(false);
  creditsafeData.value = null;
}
function closeCreditsafeConfirmModal() {
  setShowCreditsafeConfirmModal(false);
  creditsafeData.value = null;
}

function cancelCreditsafeChanges() {
  creditsafeData.value = structuredClone(originalCreditsafeData);
}

function setCompanies(object) {
  companies.value = object;
}

function setCompany(object) {
  company.value = object;
  setShowCreditsafeShowModal(true);
}

function setShowCreditsafeShowModal(boolean) {
  showCreditsafeShowModal.value = boolean;
}

function setShowCreditsafeConfirmModal(boolean) {
  showCreditsafeConfirmModal.value = boolean;
}

function setLoading(boolean) {
  loading.value = boolean;
}

async function setUseCreditsafeData() {
  setShowCreditsafeShowModal(false);
  setShowCreditsafeConfirmModal(true);
  if (!creditsafeData.value) {
    try {
      creditsafeData.value = await getCreditsafeCompanyData();
      if (!creditsafeData.value) {
        setShowCreditsafeShowModal(true);
        setShowCreditsafeConfirmModal(false);
        return;
      }
      originalCreditsafeData = structuredClone(creditsafeData.value);
    } catch (error) {
      useSetToast(
        'error',
        t('toast.error.retrieving_creditsafe_company') + ':' + '<br>' + error?.response?.data?.message
      );
      console.error('Error while fetching creditsafe company: ', error);
    }
  }
  if (creditsafeData.value && !creditsafeData.value.vatNo) {
    creditsafeData.value.vatNo = company.value.vatNo;
  }
}

function getCreditsafeCompanyData() {
  return apiClient.request('get', `/ifapi/integrations/creditsafe/companies/${company.value.id}`, {
    requestSource: getUrlParameters(),
  });
}

function getUrlParameters() {
  const language = company.value.country === 'NL' ? 'NL' : 'EN';
  return `?language=${language}`;
}

function openCreditsafeShowModal() {
  setShowCreditsafeConfirmModal(false);
  setShowCreditsafeShowModal(true);
}
</script>
