<template>
  <BaseRow v-if="!_.isEmpty(enums.data)">
    <ToastContainer />
    <ConfirmModal
      v-if="useConfirm.show"
      :show="useConfirm.show"
      :title="useConfirm.title"
      :message="useConfirm.message"
      @confirm="confirmModal"
      @close="closeModal"
    />
    <div class="side-nav">
      <div class="d-flex flex-column align-items-center">
        <div class="logo-box">
          <TheLogo
            :for-company="'impact-factoring'"
            :collapsed="true"
          />
        </div>
        <div class="nav-items">
          <RouterLink
            to="/sales"
            class="sidenav-item"
            :title="$t('intermediary.prospects')"
            :class="{ active: route.path.includes('/sales') }"
          >
            <i class="fa fa-landmark"></i>
          </RouterLink>
          <RouterLink
            to="/intermediaries"
            class="sidenav-item"
            :title="$t('intermediary.intermediaries')"
            :class="{ active: route.path.includes('/intermediaries') }"
          >
            <i class="fa fa-people-group"></i>
          </RouterLink>
        </div>
      </div>
      <div
        class="logout-item"
        title="Uitloggen"
      >
        <i
          class="fa fa-solid fa-right-from-bracket"
          @click="logout"
        />
      </div>
    </div>
    <BaseColumn class="main-content">
      <RouterView />
    </BaseColumn>
  </BaseRow>
</template>

<script setup>
import { useConfirmLeavingPage, useGetConfirm, useSetShowConfirm } from '@/composables/UseIsDirty';
import { useAuthorizationStore } from '@/stores/authorization';
import { useEnumerationsStore } from '@/stores/enumerations';
import ToastContainer from '@/components/toasts/ToastContainer.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import { BaseRow, BaseColumn, TheLogo } from '@impact-factoring/impact-branding';
import { RouterView, useRoute } from 'vue-router';
import { onBeforeMount } from 'vue';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const { t } = useI18n();
const enums = useEnumerationsStore();
const useConfirm = useGetConfirm();

onBeforeMount(async () => {
  await enums.fetchEnums([
    'current_user',
    'users',
    'companies',
    'intermediaries',
    'legal_forms',
    'currencies',
    'competences',
    'insurers',
    'average_payment_term_days',
    'countries',
    'contact_types',
  ]);

  await useAuthorizationStore().setCurrentUser(enums.data.current_user);
});

function confirmModal() {
  useConfirm.value.show = useSetShowConfirm(false);
  useConfirmLeavingPage();
}

function closeModal() {
  useSetShowConfirm(false);
}

async function logout() {
  try {
    await apiClient.request('post', '/logout').then(() => {
      window.location.href = `${process.env.VUE_APP_ACCOUNTS_SERVER_URL}/login`;
    });
  } catch (error) {
    useSetToast('error', t('toast.error.logging_out') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while logging out: ', error);
  }
}
</script>
