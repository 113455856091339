<template>
  <textarea
    v-bind="$attrs"
    v-model="computedModelValue"
    class="form-control"
    :class="{
      'form-control--narrow': !props.notNarrow,
      'is-invalid': props.errors && props.errors.length,
      resize: props.isResizable,
    }"
    :rows="$attrs.rows || 2"
  />
  <div
    v-if="errors.length"
    class="form-text text-danger error-messages"
  >
    {{ errors.join('\r\n') }}
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  notNarrow: {
    type: Boolean,
    default: false,
  },
  isResizable: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const computedModelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (!value) {
      emit('update:modelValue', null);
      return;
    }
    emit('update:modelValue', value);
  },
});
</script>
