<template>
  <BaseModal
    :show="showModal"
    :modal-title="$t('modal.confirm_creditsafe_data')"
    min-height="530px"
    custom-footer
    @close="closeModal"
  >
    <BaseSpinner
      v-if="_.isEmpty(record)"
      type="primary"
    />
    <template v-else>
      <div class="modal-text-spacing-left">
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('general.company_name') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.name }}</div>
      </div>
      <div>
        <div class="d-inline-flex creditsafe-icon-wrapper">
          <BaseIcon
            v-if="record.legalForm"
            class="creditsafe-icon-delete"
            icon="fa-solid fa-xmark"
            @click="removeValueFromRecord('legalForm')"
          />
        </div>
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('company.legal_form') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.legalForm }}</div>
      </div>
      <div>
        <div class="d-inline-flex creditsafe-icon-wrapper">
          <BaseIcon
            v-if="record.registeredOffice"
            class="creditsafe-icon-delete"
            icon="fa-solid fa-xmark"
            @click="removeValueFromRecord('registeredOffice')"
          />
        </div>
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('address.registered_office') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.registeredOffice }}</div>
      </div>
      <div>
        <div class="d-inline-flex creditsafe-icon-wrapper">
          <BaseIcon
            v-if="record.street || record.houseNumber"
            class="creditsafe-icon-delete"
            icon="fa-solid fa-xmark"
            @click="removeValueFromRecord('street')"
          />
        </div>
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('address.address_information') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">
          {{ record.street }} {{ record.houseNumber }}
        </div>
      </div>
      <div>
        <div class="d-inline-flex creditsafe-icon-wrapper">
          <BaseIcon
            v-if="record.postalCode"
            class="creditsafe-icon-delete"
            icon="fa-solid fa-xmark"
            @click="removeValueFromRecord('postalCode')"
          />
        </div>
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('address.postal_code') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.postalCode }}</div>
      </div>
      <div>
        <div class="d-inline-flex creditsafe-icon-wrapper">
          <BaseIcon
            v-if="record.city"
            class="creditsafe-icon-delete"
            icon="fa-solid fa-xmark"
            @click="removeValueFromRecord('city')"
          />
        </div>
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('address.city') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.city }}</div>
      </div>
      <div>
        <div class="d-inline-flex creditsafe-icon-wrapper">
          <BaseIcon
            v-if="record.country"
            class="creditsafe-icon-delete"
            icon="fa-solid fa-xmark"
            @click="removeValueFromRecord('country')"
          />
        </div>
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('address.country') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.country }}</div>
      </div>
      <div>
        <div class="d-inline-flex creditsafe-icon-wrapper">
          <BaseIcon
            v-if="record.telephone"
            class="creditsafe-icon-delete"
            icon="fa-solid fa-xmark"
            @click="removeValueFromRecord('telephone')"
          />
        </div>
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('general.phone') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.telephone }}</div>
      </div>
      <div>
        <div class="d-inline-flex creditsafe-icon-wrapper">
          <BaseIcon
            v-if="record.website"
            class="creditsafe-icon-delete"
            icon="fa-solid fa-xmark"
            @click="removeValueFromRecord('website')"
          />
        </div>
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('general.website') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.website }}</div>
      </div>
      <div>
        <div class="d-inline-flex creditsafe-icon-wrapper">
          <BaseIcon
            v-if="record.regNo"
            class="creditsafe-icon-delete"
            icon="fa-solid fa-xmark"
            @click="removeValueFromRecord('regNo')"
          />
        </div>
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('general.registration_number') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.regNo }}</div>
      </div>
      <div>
        <div class="d-inline-flex creditsafe-icon-wrapper">
          <BaseIcon
            v-if="record.vatNo"
            class="creditsafe-icon-delete"
            icon="fa-solid fa-xmark"
            @click="removeValueFromRecord('vatNo')"
          />
        </div>
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('general.vat_number') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.vatNo }}</div>
      </div>
      <div class="flex-grow-1">
        <div class="d-inline-flex creditsafe-icon-wrapper">
          <BaseIcon
            v-if="record.branch"
            class="creditsafe-icon-delete"
            icon="fa-solid fa-xmark"
            @click="removeValueFromRecord('branch')"
          />
        </div>
        <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('client.branch') }}</div>
        <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.branch }}</div>
      </div>
      <div class="text-description text-secondary pb-3">
        {{ $t('description.creditsafe_modal_description') }}
      </div>
    </template>
    <template
      v-if="!_.isEmpty(record)"
      #modalFooter
    >
      <SecondaryButton
        class="me-auto"
        @click="goBack"
      >
        {{ $t('ui.previous') }}
      </SecondaryButton>
      <SecondaryButton @click="cancel">{{ $t('ui.cancel') }}</SecondaryButton>
      <SaveButton
        :pending="loading"
        :disabled="loading"
        :label="$t('ui.save')"
        @submit="submit"
      />
    </template>
  </BaseModal>
</template>

<script setup>
import BaseIcon from '@/components/general/BaseIcon.vue';
import SaveButton from '@/components/buttons/SaveButton.vue';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import BaseModal from '@/components/BaseModal.vue';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { computed, ref } from 'vue';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close', 'previous', 'submit', 'cancel']);

const showModal = ref(true);

const record = computed(() => {
  return props.initialRecord;
});

function removeValueFromRecord(key) {
  record.value[key] = null;
  if (key === 'street') {
    record.value['houseNumber'] = null;
  }
}

function submit() {
  emit('submit', record.value);
}
function closeModal() {
  showModal.value = false;
  emit('close', showModal.value);
}
function goBack() {
  emit('previous');
}
function cancel() {
  emit('cancel');
}
</script>
