<template>
  <table
    class="table"
    :class="tableStyle"
  >
    <thead v-if="props.headers.length || $slots.headers">
      <tr>
        <th
          v-for="(header, key) in props.headers"
          :key="key"
          class="text-truncate"
        >
          {{ header }}
        </th>
        <slot name="headers" />
      </tr>
    </thead>
    <tbody>
      <slot />
    </tbody>
  </table>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  headers: {
    type: Array,
    default: () => [],
  },
  hoverable: {
    type: Boolean,
    default: false,
  },
  bordered: {
    type: Boolean,
    default: false,
  },
  borderless: {
    type: Boolean,
    default: false,
  },
  striped: {
    type: Boolean,
    default: false,
  },
});

const tableStyle = computed(() => {
  return {
    hover: props.hoverable,
    'table-bordered': props.bordered,
    'table-borderless': props.borderless,
    'table-striped': props.striped,
  };
});
</script>
