<template>
  <div class="overview-search-input overview-search">
    <div class="position-relative">
      <BaseInput
        v-model="searchValue"
        :placeholder="t('ui.placeholders.search')"
      />
      <i class="fa-solid fa-magnifying-glass icon-search position-absolute" />
    </div>
  </div>
  <OverviewTable
    class="table-intermediaries"
    size="lg"
  >
    <template #headers>
      <th @click="handleToggleColumn(toggle.keys.personName)">
        {{ t('modal.person') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.personName }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn(toggle.keys.companyName)">
        {{ t('general.company') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.companyName }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn(toggle.keys.email)">
        {{ t('general.email') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.email }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn(toggle.keys.mobile)">
        {{ t('general.mobile') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.mobile }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn(toggle.keys.tel)">
        {{ t('general.phone') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.tel }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn(toggle.keys.prospectsCount)">
        {{ t('intermediary.prospects') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.prospectsCount }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn(toggle.keys.prospectCustomersCount)">
        {{ t('navigation.clients') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.prospectCustomersCount }"
          icon="fa-solid fa-sort"
        />
      </th>
    </template>

    <tr
      v-for="intermediary in computedFilteredRecord"
      :key="intermediary.key"
      class="cursor-pointer"
      @click="goToIntermediary(intermediary.id)"
    >
      <td class="text-truncate">
        {{ intermediary.personName }}
      </td>
      <td class="text-truncate">
        {{ intermediary.companyName }}
      </td>
      <td class="text-truncate">
        {{ intermediary.email }}
      </td>
      <td class="text-truncate">
        {{ intermediary.mobile }}
      </td>
      <td class="text-truncate">
        {{ intermediary.tel }}
      </td>
      <td class="text-truncate">
        {{ intermediary.prospectsCount }}
      </td>
      <td class="text-truncate">
        {{ intermediary.prospectCustomersCount }}
      </td>
    </tr>
  </OverviewTable>
</template>

<script setup>
import OverviewTable from '@/components/tables/OverviewTable.vue';
import BaseIcon from '@/components/general/BaseIcon.vue';
import BaseInput from '@/components/form/BaseInput.vue';
import { useI18n } from 'vue-i18n';
import { computed, onBeforeMount, ref } from 'vue';
import _ from 'lodash';
import { useRouter } from 'vue-router';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
});

const router = useRouter();
const { t } = useI18n();

const record = ref(props.initialRecord);
const searchValue = ref(null);
const toggle = {
  key: 'personName',
  previousValue: 'personName',
  sortBy: 'asc',
  keys: {
    personName: 'personName',
    companyName: 'companyName',
    email: 'email',
    mobile: 'mobile',
    tel: 'tel',
    prospectsCount: 'prospectsCount',
    prospectCustomersCount: 'prospectCustomersCount',
  },
};

const computedFilteredRecord = computed(() => {
  let intermediaries = record.value.intermediaries;

  if (searchValue.value) {
    intermediaries = intermediaries.filter((intermediary) => {
      const index = Object.values(intermediary).findIndex((value) => {
        if (!value && value !== 0) {
          return false;
        }
        return formatSearchString(value).includes(formatSearchString(searchValue.value));
      });

      return index !== -1;
    });
  }
  return _.orderBy(intermediaries, toggle.key, toggle.sortBy);
});

onBeforeMount(() => {
  toggleColumn();
});

function handleToggleColumn(key) {
  toggle.key = key;

  if (toggle.sortBy === 'desc') {
    toggle.sortBy = 'asc';

    toggleColumn();
    return;
  }

  if (toggle.key === toggle.previousValue) {
    toggle.sortBy = 'desc';
  }

  toggleColumn();
}

function toggleColumn() {
  toggle.previousValue = toggle.key;
  record.value.intermediaries = _.orderBy(record.value.intermediaries, toggle.key, toggle.sortBy);
}

function goToIntermediary(id) {
  router.push({
    name: 'edit intermediaries',
    params: { id: id },
  });
}

function formatSearchString(string) {
  return string.toString().trim().toLowerCase().replaceAll('.', '').replaceAll(',', '').replaceAll('-', ' ');
}
</script>
