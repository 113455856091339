<template>
  <input
    v-bind="$attrs"
    v-model="computedModelValue"
    class="text-truncate"
    :class="[
      'form-control',
      {
        'form-control--narrow': !props.notNarrow,
        'fade-in-text': computedAnimateText,
        'is-invalid': props.errors && props.errors.length,
        wrapperClass,
      },
    ]"
    :placeholder="props.placeholder"
    @keydown.enter="onKeyDownEnter"
  />
  <ErrorMessages
    v-if="props.showErrorMessages"
    :errors="props.errors"
  />
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import ErrorMessages from '@/components/ErrorMessages.vue';
import { computed } from 'vue';

const props = defineProps({
  notNarrow: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  placeholder: {
    type: String,
    default: '',
  },
  errors: {
    type: Array,
    default: () => [],
  },
  showErrorMessages: {
    type: Boolean,
    default: true,
  },
  wrapperClass: {
    type: [String, Array, Object],
    default: null,
  },
  initialAnimateText: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'enter']);

const computedModelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (!value) {
      emit('update:modelValue', null);
      return;
    }
    emit('update:modelValue', value);
  },
});

const computedAnimateText = computed(() => {
  return props.initialAnimateText;
});

function onKeyDownEnter() {
  emit('enter');
}
</script>
