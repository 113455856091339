import i18n from '@/lang/i18n-config';

const { t } = i18n.global;

const MR = t('salutation.mr');
const MS = t('salutation.ms');
const UNSPECIFIED = t('salutation.unspecified');

export { MR, MS, UNSPECIFIED };
export default { MR, MS, UNSPECIFIED };
