<template>
  <ConfirmModal
    v-if="showLeavePageConfirmModal"
    :show="showLeavePageConfirmModal"
    :message="t('modal.warning_text_unsaved_changes_when_leaving_companies_form')"
    @confirm="handleConfirmLeaveForm"
    @close="closeLeavePageConfirmModal"
  />
  <div class="d-flex justify-content-end mt-4 pt-2">
    <ActionAddButton
      :label="$t('general.company')"
      @click="handleOpenCompanyCreateForm"
    />
  </div>
  <div class="table-sticky-head table-companies">
    <CompanyForm
      v-if="showCreateCompanyForm && record?.length"
      :initial-record="record[-1]"
      :initial-validation-rules="validationRules[0]"
      label-columns="col-xl-3 col-12"
      input-columns="col-xl-5 col-12"
      @save="handleEmitSave"
      @before-mount="handleEmitBeforeMount"
    />
    <DataTable
      v-if="record?.length"
      :headers="headers"
      :hoverable="false"
    >
      <template
        v-for="(company, key) in computedCompanies"
        :key="company"
      >
        <tr
          v-if="company.id"
          class="cursor-pointer"
          @click="handleToggleCompanyUpdateForm(company.id)"
        >
          <td class="text-truncate">
            {{ company.name }}
          </td>
          <td class="text-truncate">
            {{ company.city || '-' }}
          </td>
          <td class="text-truncate">
            {{ company.company_registration_number || '-' }}
          </td>
          <td class="action-icons">
            <BaseIcon icon="fa-solid fa-angle-down" />
          </td>
        </tr>
        <tr
          v-if="showUpdateCompanyForm && oldCompanyId === company.id"
          class="company-table-form"
        >
          <td colspan="4">
            <CompanyForm
              :initial-record="company"
              :initial-validation-rules="validationRules[key]"
              label-columns="col-xl-3 col-12"
              input-columns="col-xl-5 col-12"
              @update="handleEmitUpdate"
              @before-mount="handleEmitBeforeMount"
            />
          </td>
        </tr>
      </template>
    </DataTable>
  </div>
</template>

<script setup>
import CompanyForm from './CompanyForm.vue';
import ActionAddButton from '@/components/buttons/ActionAddButton.vue';
import DataTable from '@/components/tables/DataTable.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import { useGetOriginalProspectRecord, useGetProspectRecord, useRemoveProspectFormId } from '@/composables/UseIsDirty';
import { BaseIcon } from '@impact-factoring/impact-branding';
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { required } from '@vuelidate/validators';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['save', 'beforeMount']);

const { t } = useI18n();
const record = ref(props.initialRecord);
const headers = [t('general.name'), t('address.city'), t('general.company_registration_number'), ''];
const showCreateCompanyForm = ref(false);
const showUpdateCompanyForm = ref(false);
const showLeavePageConfirmModal = ref(false);
const oldCompanyId = ref(null);
const preConfirmCompanyId = ref(null);
const validationRules = ref(createValidationRules());
const companyFormId = ref(null);
let originalRecord = [];

const computedCompanies = computed(() => {
  let companies = structuredClone(record.value);
  return _.orderBy(companies, 'id', 'desc');
});

function createCompany() {
  return {
    name: null,
  };
}

function createValidationRules() {
  const validationRule = {
    name: { required },
  };

  return [validationRule];
}

function handleOpenCompanyCreateForm() {
  if (!_.isEqual(useGetProspectRecord(companyFormId.value), useGetOriginalProspectRecord(companyFormId.value))) {
    preConfirmCompanyId.value = null;
    showLeavePageConfirmModal.value = true;
    return;
  }

  addCompanyToRecord();
  openCreateCompanyForm();
}

function handleToggleCompanyUpdateForm(id) {
  if (!_.isEqual(useGetProspectRecord(companyFormId.value), useGetOriginalProspectRecord(companyFormId.value))) {
    preConfirmCompanyId.value = id;
    showLeavePageConfirmModal.value = true;
    return;
  }

  toggleCompanyForm(id);
}

function handleConfirmLeaveForm() {
  toggleCompanyForm(preConfirmCompanyId.value);

  preConfirmCompanyId.value = null;
  showLeavePageConfirmModal.value = false;
  useRemoveProspectFormId(companyFormId.value);
  record.value = structuredClone(originalRecord);
}

function toggleCompanyForm(id) {
  if (!id) {
    openCreateCompanyForm();
    return;
  }

  if (oldCompanyId.value === id) {
    showUpdateCompanyForm.value = false;
    oldCompanyId.value = null;
    return;
  }

  showUpdateCompanyForm.value = true;
  showCreateCompanyForm.value = false;
  oldCompanyId.value = id;
}

function openCreateCompanyForm() {
  oldCompanyId.value = null;
  showUpdateCompanyForm.value = false;
  showCreateCompanyForm.value = true;
}

function addCompanyToRecord() {
  if (!record.value.length || record.value[record.value.length - 1]?.id) {
    record.value = [...record.value, createCompany()];
    validationRules.value = createValidationRules();
  }
}

function closeLeavePageConfirmModal() {
  showLeavePageConfirmModal.value = false;
}

function handleEmitSave(data) {
  emit('save', data);
  record.value = [...record.value, data.company];
  originalRecord = structuredClone(record.value);

  toggleCompanyForm(record.value[record.value.length - 1].id);
  addCompanyToRecord();
}

function handleEmitUpdate(data) {
  emit('save', data);
  originalRecord = structuredClone(record.value);
  // addCompanyToRecord();
}

function handleEmitBeforeMount(formId) {
  emit('beforeMount', formId);
  originalRecord = structuredClone(record.value);
  companyFormId.value = formId;
}
</script>
