<template>
  <BaseModal
    v-if="showConfirmCreditProposalDataModal && creditProposalUpdateDataNotAllowed"
    :show="showConfirmCreditProposalDataModal"
    :modal-title="$t('ui.not_allowed')"
    size="md"
    custom-footer
    @confirm="closeCreditProposalModal"
    @close="closeCreditProposalModal"
  >
    {{ $t('modal.not_allowed_to_update_credit_proposal_data_while_ongoing_evaluation') }}
  </BaseModal>
  <div class="color-header mb-1">
    {{ t('sales.creditor_analyses.creditors_balance') }}
  </div>
  <div class="debtor-analyse-table-top-input mb-4 pb-1">
    <AmountInput
      v-model="record.total_creditors_amount"
      :errors="errorMessages['total_creditors_amount']"
      :inner-label="props.initialRecord.sales_status.currency"
      wrapper-class="impact-default-group-margin"
      @enter="handleSave"
    />
  </div>
  <ResponsiveTable
    :headers="record.creditor_analyse.length ? headers : []"
    borderless
    hoverable
    class="debtor-analyses-table creditor-analyses-table"
  >
    <template v-if="record.creditor_analyse.length">
      <tr
        v-for="(creditorAnalyseRule, key) in record.creditor_analyse"
        :key="key"
      >
        <td>
          <BaseInput
            v-model="creditorAnalyseRule.name"
            :errors="errorMessages[`creditor_analyse.${key}.name`]"
            @enter="handleSave"
          />
        </td>
        <td>
          <CompanyRegistrationNumberNumberInput
            v-model="creditorAnalyseRule.company_registration_number"
            :errors="errorMessages[`creditor_analyse.${key}.company_registration_number`]"
            @enter="handleSave"
          />
        </td>
        <td>
          <AmountInput
            v-model="creditorAnalyseRule.total_amount"
            :errors="errorMessages[`creditor_analyse.${key}.total_amount`]"
            :inner-label="props.initialRecord.sales_status.currency"
            @blur="setTotalCreditorAnalysesTotalAmount"
            @enter="handleSave"
          />
        </td>
        <td class="grow-textarea-on-focus">
          <BaseTextarea
            v-model="creditorAnalyseRule.nature_of_product"
            :errors="errorMessages[`creditor_analyse.${key}.nature_of_product`]"
            class="grow-on-focus"
            rows="1"
          />
        </td>
        <td class="grow-textarea-on-focus">
          <BaseTextarea
            v-model="creditorAnalyseRule.memo"
            :errors="errorMessages[`creditor_analyse.${key}.memo`]"
            class="grow-on-focus"
            rows="1"
          />
        </td>
        <td class="icons-column">
          <div @click="removeCreditor(key)">
            <i
              class="fa-regular fa-trash-can md"
              aria-hidden="true"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td />
        <td class="table-footer-th">
          <div class="fw-medium mt-3 ps-2">{{ t('ui.numbers.total') }}</div>
        </td>
        <td class="table-footer-th">
          <div class="fw-medium text-end mt-3 pe-2">
            {{ formattedTotalAmount }}
          </div>
        </td>
        <td />
        <td />
        <td />
      </tr>
    </template>
    <template #table-footer>
      <FormIconButton
        icon="fa-solid fa-plus"
        button-style="primary"
        :label-right="$t('sales.creditor_analyses.creditor')"
        class="mb-3"
        @click.prevent="addCreditor"
      />
    </template>
  </ResponsiveTable>
  <FormFooter
    class="mt-auto"
    :pending="loading"
    :disabled="_.isEqual(record, originalRecord) || loading"
    @save="handleSave"
    @cancel="handleCancel"
  />
</template>

<script setup>
import BaseInput from '@/components/form/BaseInput.vue';
import AmountInput from '@/components/form-controls/AmountInput.vue';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import CompanyRegistrationNumberNumberInput from '@/components/form-controls/CompanyRegistrationNumberNumberInput.vue';
import FormIconButton from '@/components/buttons/FormIconButton.vue';
import {
  useAmountSuffixConvertCentsToAmount,
  useAmountSuffixConvertAmountToCents,
  useAmountToCents,
  useCentsToAmount,
} from '@/composables/UseNumberManipulation';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import ResponsiveTable from '@/components/tables/ResponsiveTable.vue';
import apiClient from '@/services/ApiClient';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import BaseModal from '@/components/BaseModal.vue';
import { APPROVED, EVALUATION } from '@/configs/constants/Status';
import {
  useCompareSalesStatusObject,
  useSetAndMergeSalesStatusObject,
} from '@/composables/records/UseAssessmentCommittee';
import {
  useGetProspectCreditProposalOwner,
  useGetProspectCreditProposalStatus,
} from '@/composables/UseProspectViewData';
import { computed, onBeforeMount, ref } from 'vue';
import { minValue, required, requiredIf } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { nanoid } from 'nanoid';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
});
const emit = defineEmits(['save']);

const router = useRouter();
const { t } = useI18n();

const headers = [
  t('sales.creditor_analyses.creditor_name'),
  t('general.company_registration_number'),
  t('sales.creditor_analyses.total_amount'),
  t('sales.creditor_analyses.nature_of_product_or_service'),
  t('sales.creditor_analyses.particularities'),
];
const record = ref({
  total_creditors_amount: '',
  total_amount: '',
  creditor_analyse: [],
});
const originalRecord = ref({});
const validationRules = ref({});
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const showConfirmCreditProposalDataModal = ref(false);
const creditProposalUpdateDataNotAllowed =
  useGetProspectCreditProposalStatus() === EVALUATION && !useGetProspectCreditProposalOwner();
let originalCreditProposalObject = {};

const rules = computed(() => {
  return createRulesObject();
});

const formattedTotalAmount = computed(() => {
  if (!record.value.total_amount && parseInt(record.value.total_amount) !== 0) {
    return '';
  }
  return useCentsToAmount(record.value.total_amount.replace('.', ''));
});

onBeforeMount(async () => {
  useBeforeRouteLeave();
  if (props.initialRecord?.data) {
    record.value = props.initialRecord.data;
  }

  setTotalCreditorAnalysesTotalAmount();
  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);

  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }

  if (record.value.id && record.value.analyse) {
    validationRules.value = useMergeDefaultVuelidateValidationRules(rules.value, record.value);
    errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);
  }
});

async function handleSave() {
  setTotalCreditorAnalysesTotalAmount();

  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  if (creditProposalUpdateDataNotAllowed) {
    let latestCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));

    const creditProposalHasDataDifference = useCompareSalesStatusObject(
      originalCreditProposalObject,
      latestCreditProposalObject
    );

    if (
      creditProposalHasDataDifference ||
      originalCreditProposalObject.creditor_analyses.customer_creditor_analyse.length !==
        record.value.creditor_analyse.length
    ) {
      showConfirmCreditProposalDataModal.value = true;
      return;
    }
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(rules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  await save();
}

async function save() {
  if (!record.value.id) {
    await saveCreditorAnalyses();
  } else {
    await updateCreditorAnalyses();
  }
}
async function saveCreditorAnalyses() {
  try {
    loading.value = true;
    const recordCopy = structuredClone(record.value);

    const response = await apiClient.request(
      'post',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/creditor_analyses`,
      null,
      useAmountSuffixConvertAmountToCents(recordCopy)
    );
    useSetToast('success', t('toast.success.creditor_analyses_successfully_created'));
    emit('save', { customerLogs: response.sales_status.customer_log });

    record.value.creditor_analyse = useAmountSuffixConvertCentsToAmount(response.creditor_analyse);
    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast('error', t('toast.error.creating_creditor_analyses') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while creating creditor analyses: ', error);
  } finally {
    loading.value = false;
  }
}
async function updateCreditorAnalyses() {
  try {
    loading.value = true;
    const recordCopy = structuredClone(record.value);

    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/creditor_analyses/${record.value.id}`,
      null,
      useAmountSuffixConvertAmountToCents(recordCopy)
    );

    useSetToast('success', t('toast.success.creditor_analyses_successfully_updated'));
    emit('save', { customerLogs: response.sales_status.customer_log });

    record.value.creditor_analyse = useAmountSuffixConvertCentsToAmount(response.data);
    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast('error', t('toast.error.updating_creditor_analyses') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while updating creditor analyses: ', error);
  } finally {
    loading.value = false;
  }
}
function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }
  errorMessages.value = [];
}

function createRulesObject() {
  let creditorAnalyseRulesObject = {
    creditor_analyse: {},
  };

  record.value.creditor_analyse?.forEach((creditorAnalyseRule, key) => {
    Object.assign(creditorAnalyseRulesObject.creditor_analyse, {
      [key]: {
        name: { required },
        total_amount: { required },
      },
    });
  });

  if (!record.value.total_amount) {
    record.value.total_amount = '';
  }

  return {
    total_creditors_amount: {
      minValue: minValue(record.value.total_amount.toString().replaceAll('.', '').replace(',', '') / 100),
      requiredIf: requiredIf(record.value.creditor_analyse.length),
    },
    ...creditorAnalyseRulesObject,
  };
}
function setTotalCreditorAnalysesTotalAmount() {
  let totalAmount;
  record.value.creditor_analyse.forEach((analyse) => {
    if (!analyse.total_amount) {
      return;
    }
    if (!totalAmount && totalAmount !== 0) {
      totalAmount = useAmountToCents(analyse.total_amount);
    } else {
      totalAmount += useAmountToCents(analyse.total_amount);
    }
  });

  if (!totalAmount) {
    if (totalAmount !== 0) {
      record.value.total_amount = '';
    } else {
      record.value.total_amount = '0.00';
    }
    return;
  }

  record.value.total_amount = useCentsToAmount(totalAmount).replaceAll('.', '').replace(',', '.');
}

function addCreditor() {
  const creditorAnalyse = {
    id: null,
    name: null,
    company_registration_number: null,
    total_amount: '',
    nature_of_product: null,
    memo: null,
  };

  record.value.creditor_analyse = [...record.value.creditor_analyse, creditorAnalyse];
  validationRules.value = useMergeDefaultVuelidateValidationRules(rules.value, record.value);
}
async function removeCreditor(index) {
  record.value.creditor_analyse.splice(index, 1);

  setTotalCreditorAnalysesTotalAmount();

  if (Object.entries(errorMessages.value).length) {
    validationRules.value = useMergeDefaultVuelidateValidationRules(rules.value, record.value);
    errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);
  }
}

function getCreditProposalObject() {
  let creditorAnalyses = { creditor_analyses: structuredClone(record.value) };
  creditorAnalyses.creditor_analyses.customer_creditor_analyse = structuredClone(record.value.creditor_analyse);
  delete creditorAnalyses.creditor_analyses.creditor_analyse;

  return { sales_status: creditorAnalyses };
}

function closeCreditProposalModal() {
  showConfirmCreditProposalDataModal.value = false;
}
</script>
