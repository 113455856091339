<template>
  <div class="overview-search-input overview-search">
    <div class="position-relative">
      <BaseInput
        v-model="searchValue"
        :placeholder="t('ui.placeholders.search')"
      />
      <i class="fa-solid fa-magnifying-glass icon-search position-absolute" />
    </div>
  </div>
  <OverviewTable
    class="table-prospects"
    size="lg"
  >
    <template #headers>
      <th @click="handleToggleColumn('companyName')">
        {{ t('general.company_name') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.companyName }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn('status')">
        {{ t('ui.status') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.status }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn('scoringChancePercentage')">
        {{ t('offer.scoring_change') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.scoringChancePercentage }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn('statusTitle')">
        {{ t('sales.list.progress') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.statusTitle }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn('user')">
        {{ t('sales.list.account_manager') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.user }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn('leadChannel')">
        {{ t('sales.list.added_by') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.leadChannel }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn('intermediary')">
        {{ t('constants.lead_channels.intermediair') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.intermediary }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn('creditNeedAmount')">
        {{ t('sales.list.credit_requirement_level') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.creditNeedAmount }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn('createdAt')">
        {{ t('sales.list.created') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.createdAt }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn('updatedAt')">
        {{ t('sales.list.updated') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.updatedAt }"
          icon="fa-solid fa-sort"
        />
      </th>
    </template>
    <BaseSpinner
      v-if="loading"
      type="primary"
      class="mt-4"
    />
    <template v-if="!props.loading">
      <tr
        v-for="prospect in computedFilteredRecord"
        :key="prospect.id"
        class="cursor-pointer"
        @click="goToProspect(prospect.id)"
      >
        <td class="text-truncate">
          {{ prospect.companyName }}
        </td>
        <td class="text-truncate">
          {{ prospect.status }}
        </td>
        <td class="text-truncate">
          {{ prospect.scoringChancePercentage }}
        </td>
        <td class="text-truncate">
          {{ prospect.statusTitle }}
          <div
            v-if="prospect.statusTitle"
            class="base-tooltip"
          >
            <span class="base-tooltip-text">{{ prospect.statusTitle }}</span>
          </div>
        </td>
        <td class="text-truncate">
          {{ prospect.user }}
        </td>
        <td class="text-truncate">
          {{ prospect.leadChannel }}
        </td>
        <td class="text-truncate">
          {{ prospect.intermediary }}
        </td>
        <td class="text-truncate">
          {{ prospect.creditNeedAmount }}
        </td>
        <td class="text-truncate">
          {{ toLocaleDateString({ dateStyle: 'medium' }).format(dayjs(prospect.createdAt)) }}
        </td>
        <td class="text-truncate">
          {{ toLocaleDateString({ dateStyle: 'medium' }).format(dayjs(prospect.updatedAt)) }}
        </td>
      </tr>
    </template>
  </OverviewTable>
</template>

<script setup>
import { useCentsToAmount } from '@/composables/UseNumberManipulation';
import { toLocaleDateString } from '@/modules/formatters';
import OverviewTable from '@/components/tables/OverviewTable.vue';
import BaseIcon from '@/components/general/BaseIcon.vue';
import BaseInput from '@/components/form/BaseInput.vue';
import { useEnumerationsStore } from '@/stores/enumerations';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { useI18n } from 'vue-i18n';
import { computed, onBeforeMount, ref } from 'vue';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useRouter } from 'vue-router';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const { t } = useI18n();
const enums = useEnumerationsStore();

const record = ref(props.initialRecord);
const searchValue = ref(null);
const toggle = {
  key: 'updatedAt',
  previousValue: 'updatedAt',
  sortBy: 'desc',
  keys: {
    personName: 'personName',
    companies: 'companies',
    email: 'email',
    mobile: 'mobile',
    tel: 'tel',
    prospectsCount: 'prospectsCount',
    companyName: 'companyName',
    status: 'status',
    scoringChancePercentage: 'scoringChancePercentage',
    statusTitle: 'statusTitle',
    user: 'user',
    leadChannel: 'leadChannel',
    intermediary: 'intermediary',
    creditNeedAmount: 'creditNeedAmount',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
  },
};

const computedFilteredRecord = computed(() => {
  let prospects = record.value;

  if (searchValue.value) {
    prospects = prospects.filter((prospect) => {
      return Object.values(prospect).find((value) => {
        if (!value && value !== 0) {
          return false;
        }
        return formatSearchString(value).includes(formatSearchString(searchValue.value));
      });
    });
  }

  return prospects;
});

onBeforeMount(() => {
  record.value = mapRecord();
  toggleColumn();
});

function mapRecord() {
  return record.value.map((salesStatus) => {
    return {
      id: salesStatus.id,
      companyName: salesStatus.company.name_plus_legal_form,
      status: t(`sales.status.${getStatus(salesStatus)}`),
      scoringChancePercentage: salesStatus.offer.scoring_chance_percentage
        ? salesStatus.offer.scoring_chance_percentage + '%'
        : '',
      statusTitle: getStatusTitle(salesStatus.status_titles, salesStatus.customer_status_title_id),
      user: enums.data.users[salesStatus.customer_data.account_manager_user_id],
      leadChannel: salesStatus.customer_data.lead_channel
        ? t(`constants.lead_channels.${salesStatus.customer_data.lead_channel?.toLowerCase()}`)
        : '',
      intermediary: getIntermediaryName(salesStatus.customer_data.intermediary_id),
      creditNeedAmount: useCentsToAmount(salesStatus.customer_data.credit_need_amount),
      createdAt: salesStatus.customer_data.created_at,
      updatedAt: salesStatus.customer_data.updated_at,
    };
  });
}

function handleToggleColumn(key) {
  toggle.key = key;

  if (toggle.sortBy === 'desc') {
    toggle.sortBy = 'asc';

    toggleColumn();
    return;
  }

  if (toggle.key === toggle.previousValue) {
    toggle.sortBy = 'desc';
  }

  toggleColumn();
}

function toggleColumn() {
  toggle.previousValue = toggle.key;
  record.value = _.orderBy(record.value, toggle.key, toggle.sortBy);
}

function getStatus(salesStatus) {
  return salesStatus.statuses.find((status) => status.data.id === salesStatus.status.id).data.type.toLowerCase();
}

function getStatusTitle(titles, titleId) {
  return titles.find((statusTitle) => statusTitle.id === titleId)?.name;
}

function getIntermediary(intermediaryId) {
  return enums.data.intermediaries.find((intermediary) => {
    return intermediary.id === intermediaryId;
  });
}

function getIntermediaryName(intermediaryId) {
  if (!intermediaryId) {
    return;
  }

  const intermediary = getIntermediary(intermediaryId);
  let name = intermediary.last_name;

  if (intermediary.initials) {
    name = intermediary.initials + ' ' + intermediary.last_name;
  }

  return name;
}

function goToProspect(id) {
  router.push({
    name: 'edit sales status',
    params: { sales_status_id: id },
  });
}

function formatSearchString(string) {
  return string.toString().trim().toLowerCase().replaceAll('.', '').replaceAll(',', '').replaceAll('-', ' ');
}
</script>
