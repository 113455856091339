<template>
  <div
    :id="props.id"
    class="offcanvas"
    :class="position"
    tabindex="-1"
    :data-bs-scroll="!showBackdrop"
    :data-bs-backdrop="showBackdrop"
    data-bs-keyboard="false"
  >
    <div class="sidebar-body">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  placement: {
    type: String,
    default: 'right',
  },
  showBackdrop: {
    type: Boolean,
    default: false,
  },
});

const position = computed(() => {
  return {
    'offcanvas-start': props.placement === 'left',
    'offcanvas-end': props.placement === 'right',
    'offcanvas-top': props.placement === 'top',
    'offcanvas-bottom': props.placement === 'bottom',
  };
});
</script>
