<template>
  <div class="sidebar-wrapper">
    <a
      href="#creditProposalPreviewSidebar"
      type="button"
      data-bs-toggle="offcanvas"
      class="btn btn-sidebar btn-sidebar-right offer-preview"
      :class="{ active: isActive }"
    >
      {{ $t('ui.preview') }}
    </a>
    <BaseSidebar id="creditProposalPreviewSidebar">
      <BaseCard class="preview">
        <template #body>
          <div v-safe-html="props.creditProposalPreviewRecord" />
        </template>
      </BaseCard>
      <PrimaryButton
        class="ms-2 my-3"
        @click="download"
      >
        {{ t('ui.download_pdf') }}
      </PrimaryButton>
    </BaseSidebar>
  </div>
</template>

<script setup>
import BaseSidebar from '@/components/sidebar/BaseSidebar.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import { BaseCard } from '@impact-factoring/impact-branding';
import { useI18n } from 'vue-i18n';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const props = defineProps({
  creditProposalPreviewRecord: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['download']);

const isActive = ref(false);

onMounted(() => {
  const offCanvas = document.getElementById('creditProposalPreviewSidebar');

  offCanvas.addEventListener('show.bs.offcanvas', showOffCanvas);
  offCanvas.addEventListener('hide.bs.offcanvas', hideOffCanvas);
});

onBeforeUnmount(() => {
  const offCanvas = document.getElementById('creditProposalPreviewSidebar');

  offCanvas.removeEventListener('show.bs.offcanvas', showOffCanvas);
  offCanvas.removeEventListener('hide.bs.offcanvas', hideOffCanvas);
});

function showOffCanvas() {
  isActive.value = true;
}

function hideOffCanvas() {
  isActive.value = false;
}

const { t } = useI18n();

async function download() {
  emit('download');
}
</script>
