import { useNestedObjectKeysToDotNotation } from '@/composables/UseObjectManipulation';
import { formatNumber } from '@/modules/formatters';
import _ from 'lodash';

const formatOptions = {
  style: 'decimal',
  minimumFractionDigits: 2,
};

export function useAmountSuffixConvertAmountToCents(entity) {
  const objectKeys = useNestedObjectKeysToDotNotation(entity);
  objectKeys.forEach((key) => {
    const entityValue = _.get(entity, key);
    if (
      key.endsWith('_amount') &&
      typeof entityValue === 'string' &&
      (entityValue.includes('.') || entityValue.includes(','))
    ) {
      _.set(entity, key, parseInt(_.get(entity, key).replaceAll('.', '').replace(',', '')));
    }
  });
  return entity;
}

export function useAmountSuffixConvertCentsToAmount(entity) {
  const objectKeys = useNestedObjectKeysToDotNotation(entity);
  objectKeys.forEach((key) => {
    let entityValue = _.get(entity, key);
    if (key.endsWith('_amount') && !entityValue) {
      if (entityValue !== 0) {
        entityValue = '';
      } else {
        entityValue = '0.00';
      }
    }
    if (key.endsWith('_amount') && Number.isInteger(entityValue)) {
      entityValue = formatNumber((entityValue / 100).toString().replace('.', ','), formatOptions)
        .replaceAll('.', '')
        .replace(',', '.');
    }
    if (key.endsWith('_percentage') && Number.isInteger(entityValue)) {
      entityValue = entityValue.toString();
    }
    _.set(entity, key, entityValue);
  });
  return entity;
}

export function useAmountToCents(value) {
  return parseInt(value.replaceAll('.', '').replace(',', ''));
}

export function useCentsToAmount(value) {
  return formatNumber((value / 100).toString().replace('.', ','), formatOptions);
}
