<template>
  <LabelNumberInputGroup
    v-if="props.label"
    v-model="computedModelValue"
    inner-label="%"
    :label-content="props.label"
    :post-label="props.postLabel"
    :tooltip-content="props.tooltipContent"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    :wrapper-class="wrapperClass"
    @paste="onPaste"
    @click="onClick"
    @keydown="onKeyDown"
    @keyup="onKeyUp"
    @focus="onFocus"
    @blur="onBlur"
    @keydown.enter="onKeyDownEnter"
  >
    <template #extra>
      <slot name="extra" />
    </template>
  </LabelNumberInputGroup>
  <NumberInputGroup
    v-else
    v-model="computedModelValue"
    inner-label="%"
    @paste="onPaste"
    @click="onClick"
    @keydown="onKeyDown"
    @keyup="onKeyUp"
    @focus="onFocus"
    @blur="onBlur"
    @keydown.enter="onKeyDownEnter"
  />
</template>

<script setup>
import NumberInputGroup from '@/components/form/NumberInputGroup.vue';
import LabelNumberInputGroup from '@/components/form/LabelNumberInputGroup.vue';
import { useOnFocus, useOnPaste, useOnKeyDown, useOnKeyUp, useOnClick } from '@/composables/UseValidateNumberInput';
import { formatNumber } from '@/modules/formatters';
import { computed, ref } from 'vue';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  postLabel: {
    type: String,
    default: null,
  },
  labelColumns: {
    type: String,
    default: 'col-12 col-lg-4 col-xl-2',
  },
  inputColumns: {
    type: String,
    default: 'col-12 col-lg-3 col-xl-2',
  },
  wrapperClass: {
    type: String,
    default: null,
  },
  tooltipContent: {
    type: String,
    default: '',
  },
  decimals: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['update:modelValue', 'enter']);
const hasFocus = ref(false);

const formatOptions = {
  style: 'decimal',
  minimumFractionDigits: props.decimals,
};

const computedModelValue = computed({
  get() {
    if (props.modelValue) {
      if (!hasFocus.value) {
        return formatNumber(props.modelValue.replace('.', ','), formatOptions);
      }
      return props.modelValue.replace('.', ',');
    }

    return props.modelValue;
  },
  set(value) {
    if (!value) {
      emit('update:modelValue', null);
      return;
    }

    if (!hasFocus.value) {
      value = formatNumber(value.replace('.', ','), formatOptions).replaceAll('.', '').replace(',', '.');
    } else {
      value = value.replaceAll('.', '').replace(',', '.');
    }

    emit('update:modelValue', value);
  },
});

function onBlur(event) {
  hasFocus.value = false;
  computedModelValue.value = event.target.value;
}
function onFocus(event) {
  useOnFocus(event);
  hasFocus.value = true;
}

function onPaste(event) {
  let validatedText = useOnPaste(event, props.decimals);
  if (validatedText) {
    computedModelValue.value = validatedText;
  }
}
function onClick(event) {
  useOnClick(event, props.decimals);
}
function onKeyUp(event) {
  useOnKeyUp(event, props.decimals);
}
function onKeyDown(event) {
  computedModelValue.value = useOnKeyDown(event, props.decimals);
}
function onKeyDownEnter(event) {
  hasFocus.value = false;
  computedModelValue.value = event.target.value;
  hasFocus.value = true;
  emit('enter');
}
</script>
