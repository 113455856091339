export function formatList(list, options = {}, locale = 'nl-NL') {
  return new Intl.ListFormat(locale, options).format(list);
}

export function formatNumber(value, options) {
  value = value.replaceAll('.', '').replace(',', '.');

  if (number(value).$valid) {
    return new Intl.NumberFormat('nl-NL', options).format(value);
  }
}

export function bytesToReadableFormat() {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
    compactDisplay: 'short',
  });
}

export function toLocaleDateString(options) {
  options['timeZone'] = 'Europe/Amsterdam';
  return Intl.DateTimeFormat('nl-NL', options);
}

function number(value) {
  const regex = new RegExp('[0-9]*', 'g');
  const valid = regex.test(value);

  return {
    $valid: valid,
    $message: 'Getal is niet geldig',
  };
}
