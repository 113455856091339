import i18n from '@/lang/i18n-config';

const { t } = i18n.global;

const EMAIL_OUT = t('sales.progress_and_actions.email_out');
const EMAIL_IN = t('sales.progress_and_actions.email_in');
const PHONE_OUT = t('sales.progress_and_actions.phone_out');
const PHONE_IN = t('sales.progress_and_actions.phone_in');
const VISIT_PROSPECT = t('sales.progress_and_actions.visit_prospect');
const MEMO = t('sales.progress_and_actions.memo');

export { EMAIL_OUT, EMAIL_IN, PHONE_OUT, PHONE_IN, VISIT_PROSPECT, MEMO };
export default { EMAIL_OUT, EMAIL_IN, PHONE_OUT, PHONE_IN, VISIT_PROSPECT, MEMO };
