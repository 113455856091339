export default {
  default: 'YYYY-MM-DD',
  simpleDate: 'DD-MM-YYYY',
  dateTime: 'DD-MM-YYYY HH:mm',
  // dateTimeFileName: 'DD-MM-YY-HH-mm-ss-SSS',
  dateTimeFileName: 'DD-MM-YY-HH-mm-ss',
  time: 'HH:mm',
  simpleDateTime: 'llll',
  readableDate: "D MMM 'YY",
};
