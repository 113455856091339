import TabsView from '@/views/Router/TabsView';
import OrganogramCompanyView from '@/views/Sales/SalesStatus/Organogram/OrganogramCompanyView.vue';
import OrganogramShareholderView from '@/views/Sales/SalesStatus/Organogram/OrganogramShareholderView.vue';

export default {
  path: 'organogram',
  name: 'organogram',
  component: TabsView,
  redirect: { name: 'organogram company' },
  meta: {
    menutype: 'tabs',
  },
  children: [
    {
      path: 'company',
      name: 'organogram company',
      component: OrganogramCompanyView,
      meta: {
        title: 'company',
        menutype: 'tab',
      },
    },
    {
      path: 'shareholder',
      name: 'organogram shareholder',
      component: OrganogramShareholderView,
      meta: {
        title: 'company_shareholders',
        menutype: 'tab',
      },
    },
  ],
};
