<template>
  <div
    v-if="!props.inlineRow"
    class="form-check"
    :class="{ 'form-check-inline': props.inline, 'form-switch': roleSwitch, wrapperClass }"
  >
    <BaseCheckbox
      v-bind="attrs"
      :id="id"
      :class="{ 'is-invalid': props.errors.length }"
      :model-value="props.modelValue"
      @change="updateValue"
    />
    <label
      class="form-check-label"
      :for="id"
    >
      {{ props.labelContent }}
      <BaseTooltip
        v-if="props.tooltipContent"
        :content="props.tooltipContent"
      />
    </label>
  </div>
  <div
    v-else-if="props.inlineRow"
    class="form-check row d-flex ps-0 mb-0"
    :class="[inlineRowClasses, wrapperClass]"
  >
    <label
      class="form-check-label"
      :class="labelColumnClasses"
      :for="id"
    >
      {{ props.labelContent }}
      <BaseTooltip
        v-if="props.tooltipContent"
        :content="props.tooltipContent"
      />
    </label>
    <div :class="inputColumnClasses">
      <BaseCheckbox
        v-bind="attrs"
        :id="id"
        class="ms-0"
        :class="{ 'is-invalid': props.errors.length }"
        :model-value="props.modelValue"
        @change="updateValue"
      />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import BaseCheckbox from './BaseCheckbox.vue';
import BaseTooltip from '@/components/general/BaseTooltip.vue';
import { nanoid } from 'nanoid';
import { computed, onMounted, ref, useAttrs } from 'vue';

const props = defineProps({
  labelContent: {
    type: String,
    required: true,
  },
  labelColumns: {
    type: [String, Object, Array],
    default: null,
  },
  inputColumns: {
    type: [String, Object, Array],
    default: null,
  },
  wrapperClass: {
    type: [String, Array, Object],
    default: null,
  },
  rowClass: {
    type: [String, Object, Array],
    default: null,
  },
  tooltipContent: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  inlineRow: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const attrs = useAttrs();
const id = ref(null);

const labelColumnClasses = computed(() => {
  return [props.labelColumns ?? 'col-lg-3 col-xl-4 col-xxl-2', { 'text-danger': props.errors.length }];
});
const inputColumnClasses = computed(() => {
  return props.inputColumns ?? 'col-lg-9 col-xl-8 col-xxl-9';
});
const roleSwitch = computed(() => {
  return attrs.role === 'switch';
});
const inlineRowClasses = computed(() => {
  return [props.rowClass, { 'form-switch': roleSwitch.value }];
});

onMounted(() => {
  id.value = attrs.id ?? nanoid();
});

function updateValue(event) {
  emit('update:modelValue', event.target.checked);
}
</script>
