import apiClient from '@/services/ApiClient';

export async function useDownload(url, fileName, method = 'get', payload = null) {
  let file = await apiClient.request(method, url, null, payload, null, 'blob');
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([file]));
  link.download = fileName;
  link.click();
}

export async function useOpenInNewWindow(url) {
  const file = await apiClient.request('get', url, null, null, null, 'blob');
  const blob = new Blob([file], { type: 'application/pdf' });
  const objectUrl = URL.createObjectURL(blob);
  window.open(objectUrl, '_blank', 'noopener');
}
