<template>
  <ViewSpacing>
    <div class="d-flex justify-content-center w-full">
      <div class="table-overview-container table-prospects-container">
        <div class="action-bar">
          <ActionAddButton
            :label="$t('sales.status.prospect')"
            route-name="creditsafe search create"
          />
        </div>
        <CustomerList
          v-if="record?.data.length"
          :initial-record="record.data"
          :loading="loading"
        />
        <ListPagination
          v-if="record?.data.length && Object.keys(record?.pagination).length"
          :pagination="record?.pagination"
          @page-change="fetchData"
        />
      </div>
    </div>
  </ViewSpacing>
</template>

<script setup>
import ActionAddButton from '@/components/buttons/ActionAddButton.vue';
import CustomerList from '@/lists/sales/CustomerList.vue';
import ListPagination from '@/components/navigation/ListPagination.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import ViewSpacing from '@/components/ViewSpacing.vue';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const record = ref(null);
const loading = ref(false);

async function fetchData(page = 1) {
  try {
    loading.value = true;
    record.value = await apiClient.request('get', `/ifapi/sales_statuses?page=${page}`);
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_customer_data') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching customer data: ', error);
  } finally {
    loading.value = false;
  }
}

onBeforeMount(() => {
  fetchData();
});
</script>
