<template>
  <AppLink
    v-if="link"
    v-bind="$attrs"
    :link="link"
    class="btn"
    :class="buttonClasses"
  >
    <slot />
  </AppLink>
  <button
    v-else
    v-bind="$attrs"
    class="btn"
    :disabled="props.disabled"
    :class="buttonClasses"
  >
    <slot />
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import AppLink from './AppLink';
import { computed } from 'vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  buttonStyle: {
    type: String,
    default: null,
    validator(value) {
      return (
        ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link'].indexOf(value) !== -1
      );
    },
  },
  buttonSize: {
    type: String,
    default: null,
    validator(value) {
      return ['sm', 'lg'].indexOf(value) !== -1;
    },
  },
  link: {
    type: [String, Object],
    default: null,
  },
});

const buttonClasses = computed(() => {
  return {
    [`btn-${props.outline ? 'outline-' : ''}${props.buttonStyle}`]: props.buttonStyle,
    [`btn-${props.buttonSize}`]: props.buttonSize,
  };
});
</script>
