import PageView from '@/views/Router/PageView.vue';
import CustomerDataListView from '@/views/Sales/SalesStatus/CustomerData/CustomerDataListView.vue';
import AnnualFigures from '@/router/sales/sales-status/annual-figures';
import DebtorCreditorAnalyses from '@/router/sales/sales-status/debtor-creditor-analyses';
import Organogram from '@/router/sales/sales-status/organogram';
import Documents from '@/router/sales/sales-status/documents';
import Offers from '@/router/sales/sales-status/offers';
import CreditProposal from '@/router/sales/sales-status/credit-proposal';
import CreditsafeView from '@/views/Sales/SalesStatus/Creditsafe/CreditsafeView.vue';
import CreateCustomerDataForm from '@/views/Sales/SalesStatus/CustomerData/CreateCustomerDataView.vue';
import CustomerData from '@/router/sales/sales-status/customer-data';
import ProspectView from '@/views/Router/ProspectView.vue';

export default {
  path: 'customers',
  children: [
    {
      path: '',
      name: 'list sales statuses',
      component: CustomerDataListView,
    },
    {
      path: ':sales_status_id/creditsafe',
      name: 'creditsafe pageview update',
      component: PageView,
      children: [
        {
          path: 'search',
          name: 'creditsafe search update',
          component: CreditsafeView,
          props: true,
        },
      ],
    },
    {
      path: 'creditsafe',
      name: 'creditsafe pageview create',
      component: PageView,
      redirect: { name: 'creditsafe search create' },
      meta: {
        menutype: 'page',
      },
      children: [
        {
          path: 'search',
          name: 'creditsafe search create',
          component: CreditsafeView,
        },
      ],
    },
    {
      path: 'create',
      name: 'create customer',
      component: PageView,
      redirect: { name: 'create data' },
      meta: {
        menutype: 'page',
      },
      children: [
        {
          path: 'data',
          name: 'create data',
          component: CreateCustomerDataForm,
        },
      ],
    },
    {
      path: ':sales_status_id',
      name: 'edit sales status',
      component: ProspectView,
      redirect: { name: 'data' },
      meta: {
        menutype: 'prospect view',
      },
      children: [
        { ...CustomerData },
        { ...AnnualFigures },
        { ...DebtorCreditorAnalyses },
        { ...Organogram },
        { ...Documents },
        { ...Offers },
        { ...CreditProposal },
      ],
    },
  ],
};
