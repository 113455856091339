<template>
  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <OfferDetailsForm
    v-else-if="record"
    :initial-record="record"
    @save="updateLogbookAndPreview"
  />
</template>

<script setup>
import OfferDetailsForm from '@/forms/sales/sales-status/offer/OfferDetailsForm.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import { useAmountSuffixConvertCentsToAmount } from '@/composables/UseNumberManipulation';
import { useGetOfferPreview, useSetLogbookItems, useSetOfferPreview } from '@/composables/UseProspectViewData';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();

const record = ref(null);
const loading = ref(false);

watch(
  () => router.currentRoute.value,
  (to, from) => {
    if (from.meta.preview !== 'offer') {
      getAndSetOfferPreview();
    }
    if (to.meta.preview !== 'offer') {
      useSetOfferPreview('');
    }
  }
);

onBeforeMount(async () => {
  try {
    loading.value = true;
    record.value = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer`
    );

    if (!record.value?.transaction_financing?.id) {
      record.value.transaction_financing = {
        is_in_offer_preview: false,
        maximum_annual_revenue_percentage: null,
        maximum_purchase_orders_revenue_percentage: null,
        transaction_limit_amount: '',
        setup_fee_amount: '',
        monthly_transaction_interest_rate_percentage: null,
        maximum_monthly_duration_individual_transaction: null,
      };
    } else {
      record.value.transaction_financing.is_in_offer_preview = !!record.value.transaction_financing.is_in_offer_preview;
    }

    record.value.add_factoring_fee_abroad = !!record.value.add_factoring_fee_abroad;

    record.value = useAmountSuffixConvertCentsToAmount(record.value);
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_offer_details') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching offer details: ', error);
  } finally {
    loading.value = false;
  }
  if (!useGetOfferPreview()) {
    await getAndSetOfferPreview();
  }
});

async function getAndSetOfferPreview() {
  try {
    const offerPreviewRecord = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/preview`
    );
    useSetOfferPreview(offerPreviewRecord);
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_offer_preview') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching offer preview: ', error);
  }
}

function updateLogbookAndPreview(object) {
  useSetLogbookItems(object.customerLogs);
  if (object?.offerPreview) {
    useSetOfferPreview(object.offerPreview);
  }
}
</script>
