<template>
  <CreateIntermediaryModal
    v-if="showCreateIntermediaryModal"
    @close="closeCreateIntermediaryModal"
    @save="setRecord"
  />
  <ViewSpacing>
    <div class="d-flex justify-content-center w-full">
      <div class="table-overview-container table-intermediaries-container">
        <div class="action-bar">
          <ActionAddButton
            :label="$t('customer_data.intermediary')"
            @click="openCreateIntermediaryModal"
          />
        </div>
        <BaseSpinner
          v-if="loading"
          type="primary"
        />
        <IntermediariesList
          v-else-if="record.intermediaries?.length"
          :initial-record="record"
        />
      </div>
    </div>
  </ViewSpacing>
</template>

<script setup>
import ActionAddButton from '@/components/buttons/ActionAddButton.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import IntermediariesList from '@/lists/intermediaries/IntermediariesList.vue';
import CreateIntermediaryModal from '@/forms/Intermediaries/CreateIntermediaryModal.vue';
import ViewSpacing from '@/components/ViewSpacing.vue';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const record = ref({});
const loading = ref(false);
const showCreateIntermediaryModal = ref(false);

onBeforeMount(async () => {
  try {
    loading.value = true;

    const response = await apiClient.request('get', '/ifapi/intermediaries');
    record.value.intermediaries = mapIntermediariesAndCompanies(response.intermediaries);
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_intermediaries') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching intermediaries: ', error);
  } finally {
    loading.value = false;
  }
});

function openCreateIntermediaryModal() {
  showCreateIntermediaryModal.value = true;
}

function closeCreateIntermediaryModal() {
  showCreateIntermediaryModal.value = false;
}

function setRecord(intermediary) {
  record.value.intermediaries = [...record.value?.intermediaries, mapIntermediaries([intermediary])[0]];
}

function mapIntermediaries(intermediariesRecord) {
  return intermediariesRecord?.map((intermediary) => {
    if (intermediary?.companies?.length) {
      return mapIntermediariesAndCompanies([intermediary])[0];
    }

    if (!intermediary?.personName) {
      return {
        id: intermediary.id,
        personName: intermediary.person.name,
        companyName: null,
        email: intermediary.person.contact_detail.email_address,
        mobile: intermediary.person.contact_detail.phone_primair,
        tel: intermediary.person.contact_detail.phone_secundair,
        prospectsCount: 0,
        prospectCustomersCount: 0,
      };
    }

    return intermediary;
  });
}

function mapIntermediariesAndCompanies(intermediariesRecord) {
  let intermediaries = [];

  intermediariesRecord?.forEach((intermediary) => {
    let intermediaryRecord;

    if (!intermediary.companies.length) {
      intermediaryRecord = {
        id: intermediary.id,
        personName: intermediary.person.name,
        email: intermediary.person.contact_detail.email_address,
        mobile: intermediary.person.contact_detail.phone_primair,
        tel: intermediary.person.contact_detail.phone_secundair,
        prospectsCount: 0,
        prospectCustomersCount: 0,
      };

      intermediaries = [...intermediaries, intermediaryRecord];
      return;
    }

    intermediary.companies.forEach((company) => {
      if (!intermediary?.personName) {
        intermediaryRecord = {
          id: intermediary.id,
          personName: intermediary.person.name,
          companyName: company.name,
          email: intermediary.person.contact_detail.email_address,
          mobile: intermediary.person.contact_detail.phone_primair,
          tel: intermediary.person.contact_detail.phone_secundair,
          prospectsCount: company?.prospects_count || 0,
          prospectCustomersCount: company?.prospect_customers_count || 0,
        };
      }

      intermediaries = [...intermediaries, intermediaryRecord];
    });
  });
  return intermediaries;
}
</script>
