<template>
  <BaseRow :class="props.classes">
    <BaseColumn v-bind="$attrs">
      <slot />
    </BaseColumn>
  </BaseRow>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { BaseRow, BaseColumn } from '@impact-factoring/impact-branding';

const props = defineProps({
  classes: {
    type: String,
    default: '',
  },
});
</script>
