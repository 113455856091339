<template>
  <FormControlWrapper
    v-bind="props"
    :for="id"
  >
    <BaseInput
      v-bind="$attrs"
      :id="id"
      :type="type"
      :errors="props.errors"
      :not-narrow="props.notNarrow"
      :model-value="props.modelValue"
      @input="updateValue"
    />
    <template #extra>
      <slot
        v-if="$slots.extra"
        name="extra"
      />
    </template>
  </FormControlWrapper>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import FormControlWrapper from './FormControlWrapper.vue';
import BaseInput from './BaseInput.vue';
import { nanoid } from 'nanoid';
import { computed, onMounted, ref, useAttrs } from 'vue';

const props = defineProps({
  labelContent: {
    type: String,
    required: true,
  },
  postLabel: {
    type: String,
    default: null,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  extraColumns: {
    type: String,
    default: null,
  },
  wrapperClass: {
    type: [String, Array, Object],
    default: null,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  notInline: {
    type: Boolean,
    default: false,
  },
  notNarrow: {
    type: Boolean,
    default: false,
  },
  tooltipContent: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const id = ref(null);
const attrs = useAttrs();

const type = computed(() => {
  return attrs.type ?? 'text';
});

onMounted(() => {
  id.value = attrs.id ?? nanoid();
});

function updateValue(event) {
  if (!event.target.value) {
    emit('update:modelValue', null);
    return;
  }
  emit('update:modelValue', event.target.value);
}
</script>
