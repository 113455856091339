import nl_NL from '@/lang/nl_NL';
import en_GB from '@/lang/en_GB';
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  legacy: false,
  locale: 'nl_NL',
  fallbackLocale: 'nl_NL',
  messages: {
    nl_NL,
    en_GB,
  },
});

export default i18n;
