<template>
  <ResponsiveTable
    borderless
    hoverable
    class="mt-3 table-creditsafe-debtor-results"
  >
    <template #headers>
      <th>{{ $t('general.name') }}</th>
      <th>{{ $t('general.company_registration_number') }}</th>
      <th>{{ $t('address.address_information') }}</th>
      <th>{{ $t('address.street') }}</th>
    </template>

    <template
      v-for="(company, key) in props.record"
      :key="key"
    >
      <tr
        :class="{ 'background-active': selectedCreditsafeCompanyKey === key }"
        @click="openCreditsafeDataDropdown(key)"
      >
        <td class="text-truncate">{{ company.name }}</td>
        <td class="text-truncate">{{ company.regNo }}</td>
        <td class="text-truncate">{{ company.postalCode }}, {{ company.city }}</td>
        <td class="text-truncate">{{ company.street }} {{ company.houseNumber }}</td>
      </tr>
      <tr v-if="selectedCreditsafeCompanyKey === key">
        <td class="creditsafe-search-results-collapse">
          <div>
            <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.id') }}</div>
            <div class="d-inline-flex ms-4 creditsafe-modal-value">{{ company.id }}</div>
          </div>
          <div>
            <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.company_name') }}</div>
            <div class="d-inline-flex ms-4 creditsafe-modal-value">{{ company.name }}</div>
          </div>
          <div>
            <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.registration_name') }}</div>
            <div class="d-inline-flex ms-4 creditsafe-modal-value">{{ company.name }}</div>
          </div>
          <div>
            <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.registration_number') }}</div>
            <div class="d-inline-flex ms-4 creditsafe-modal-value">{{ company.regNo }}</div>
          </div>
          <div>
            <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.vat_number') }}</div>
            <div class="d-inline-flex ms-4 creditsafe-modal-value">{{ company.vatNo }}</div>
          </div>
          <div class="d-flex">
            <div class="creditsafe-modal-subtitle">{{ $t('address.contact_address') }}</div>
            <div class="ms-4">
              <div class="text-break">{{ company.street }} {{ company.houseNumber }}</div>
              <div class="text-break">{{ company.postalCode }} {{ company.city }}</div>
            </div>
          </div>
          <div>
            <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('ui.status') }}</div>
            <div class="d-inline-flex ms-4">{{ company.status }}</div>
          </div>
          <div class="flex-grow-1">
            <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.last_adjustment') }}</div>
            <div class="d-inline-flex ms-4">{{ company.dateOfLatestChange }}</div>
          </div>
          <PrimaryButton
            class="mt-2"
            :loading="props.loading"
            @click="handleFetchCreditsafeCompany(company)"
          >
            {{ $t('general.retrieve') }}
          </PrimaryButton>
        </td>
      </tr>
    </template>
  </ResponsiveTable>
</template>

<script setup>
import ResponsiveTable from '@/components/tables/ResponsiveTable.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import { ref } from 'vue';

const props = defineProps({
  record: {
    type: Object,
    default: () => ({}),
  },
  show: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['submit']);

const selectedCreditsafeCompanyKey = ref(null);

function openCreditsafeDataDropdown(key) {
  if (selectedCreditsafeCompanyKey.value !== key) {
    selectedCreditsafeCompanyKey.value = key;
    return;
  }
  selectedCreditsafeCompanyKey.value = null;
}

function handleFetchCreditsafeCompany(company) {
  emit('submit', company);
}
</script>
