import i18n from '@/lang/i18n-config';

const { t } = i18n.global;

const APPROVED = 'APPROVED';
const CONDITIONAL = 'CONDITIONAL';
const REJECTED = 'REJECTED';
const EVALUATION = 'EVALUATION';
const RESET = 'RESET';
const REMOVED = 'REMOVED';

const TRANSLATED_STATUS = {
  [APPROVED]: t('constants.status.approved'),
  [CONDITIONAL]: t('constants.status.conditionally'),
  [REJECTED]: t('constants.status.rejected'),
  [RESET]: t('constants.status.reset'),
  [REMOVED]: t('constants.status.removed'),
};

export { TRANSLATED_STATUS, APPROVED, CONDITIONAL, REJECTED, EVALUATION, RESET, REMOVED };
export default { TRANSLATED_STATUS, APPROVED, CONDITIONAL, REJECTED, EVALUATION, RESET, REMOVED };
