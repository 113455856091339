import i18n from '@/lang/i18n-config';

const { t } = i18n.global;

const COMMERCE = t('constants.categories.commerce');
const OFFER = t('constants.categories.offer');
const CREDIT_PROPOSAL = t('constants.categories.credit_proposal');
const MISCELLANEOUS = t('constants.categories.miscellaneous');

export { COMMERCE, OFFER, CREDIT_PROPOSAL, MISCELLANEOUS };
export default { COMMERCE, OFFER, CREDIT_PROPOSAL, MISCELLANEOUS };
