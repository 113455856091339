// Components / Views
import NotFoundView from '@/views/NotFoundView.vue';

// routes
import Sales from '@/router/sales';
import Intermediaries from '@/router/intermediaries';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { ...Sales },
  { ...Intermediaries },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
