<template>
  <BaseModal
    v-if="props.showModal"
    :show="props.showModal"
    :modal-title="!record?.id ? $t('modal.create_note') : $t('modal.update_note')"
    size="sm"
    custom-footer
    @confirm="handleSave"
    @close="closeAndResetModal"
  >
    <div class="fw-normal mt-2">
      {{ $t('description.description') }}
    </div>
    <BaseTextarea
      v-model="record.description"
      :errors="errorMessages['description']"
      rows="10"
    />
    <FormFooter
      :pending="loading"
      :disabled="_.isEqual(record, originalRecord) || loading"
      class="d-flex justify-content-end footer-secondary"
      @save="handleSave"
      @cancel="handleCancel"
    />
  </BaseModal>
</template>

<script setup>
import BaseModal from '@/components/BaseModal.vue';
import { useSetToast } from '@/composables/UseToast';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import apiClient from '@/services/ApiClient';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { useSetOriginalProspectRecord, useSetProspectRecord } from '@/composables/UseIsDirty';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { required } from '@vuelidate/validators';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  showModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close', 'save']);

const route = useRoute();
const { t } = useI18n();
const record = ref({
  id: null,
  note_id: null,
  description: null,
});
const originalRecord = ref({});
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const validationRules = ref({
  description: { required },
});

onBeforeMount(async () => {
  if (props.initialRecord?.id) {
    record.value = props.initialRecord;
  }
  originalRecord.value = useSetOriginalProspectRecord(record.value, formId);
});

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  try {
    loading.value = true;

    let response;
    if (!record.value.id) {
      response = await saveNote();
      useSetToast('success', t('toast.success.note_successfully_created'));
    } else {
      response = await updateNote();
      useSetToast('success', t('toast.success.note_successfully_updated'));
    }
    emit('save', response);

    closeModal();
  } catch (error) {
    if (!record.value.id) {
      useSetToast('error', t('toast.error.creating_note') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while creating note: ', error);
    } else {
      useSetToast('error', t('toast.error.updating_note') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while updating note: ', error);
    }
  } finally {
    loading.value = false;
  }
}

function handleCancel() {
  record.value = useSetProspectRecord(originalRecord.value, formId);
}

function closeModal() {
  handleCancel();
  emit('close');
}

function closeAndResetModal() {
  closeModal();
  emit('save', originalRecord.value);
}

async function saveNote() {
  return await apiClient.request('post', `/ifapi/intermediaries/${route.params.id}/notes`, null, record.value);
}

async function updateNote() {
  return await apiClient.request(
    'put',
    `/ifapi/intermediaries/${route.params.id}/notes/${record.value.id}`,
    null,
    record.value
  );
}
</script>
