<template>
  <ResponsiveTable
    bordered
    hoverable
  >
    <template #headers>
      <th
        v-for="(header, key) in headers"
        :key="key"
      >
        {{ header }}
      </th>
    </template>
    <tr
      v-for="(company, key) in props.record"
      :key="key"
      class="align-middle cursor-pointer"
      @click="showCompany(company)"
    >
      <td>
        {{ company.regNo }}
      </td>
      <td>
        {{ company.vatNo }}
      </td>
      <td>
        {{ company.name }}
      </td>
      <td>
        <template v-if="company.street">
          {{ company.street + ' ' }}
        </template>
        <template v-if="company.street">
          {{ company.houseNumber }}
        </template>
      </td>
      <td>
        {{ company.postalCode }}
      </td>
      <td>
        {{ company.city }}
      </td>
      <td>
        {{ company.country }}
      </td>
    </tr>
  </ResponsiveTable>
</template>

<script setup>
import ResponsiveTable from '@/components/tables/ResponsiveTable.vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  record: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['loadCompany']);

const { t } = useI18n();
const headers = [
  t('general.registration_number'),
  t('general.vat_number'),
  t('general.name'),
  t('address.address_information'),
  t('address.postal_code'),
  t('address.city'),
  t('address.country'),
];

function showCompany(company) {
  emit('loadCompany', company);
}
</script>
