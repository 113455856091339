<template>
  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <div
    v-else-if="record"
    class="d-flex flex-column h-100"
  >
    <AnnualFiguresForm
      :initial-record="record"
      @save="updateLogbook"
    />
  </div>
</template>

<script setup>
import AnnualFiguresForm from '@/forms/sales/sales-status/annual-figures/AnnualFiguresForm.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import { useAmountSuffixConvertCentsToAmount } from '@/composables/UseNumberManipulation';
import { useSetLogbookItems } from '@/composables/UseProspectViewData';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();
const record = ref(null);
const loading = ref(false);

onBeforeMount(async () => {
  try {
    loading.value = true;
    record.value = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/annual_figures`
    );

    record.value = useAmountSuffixConvertCentsToAmount(record.value);

    if (!record.value.data.length) {
      record.value.data = createAnnualFiguresRecord();
    }
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_annual_figures') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching annual figures: ', error);
  } finally {
    loading.value = false;
  }
});

function createAnnualFiguresRecord() {
  return getYears().map((year) => {
    return {
      year: year,
      revenue_amount: '',
      gross_profit_amount: '',
      gross_margin_percentage: null,
      opex_amount: '',
      ebitda_amount: '',
      net_profit_amount: '',
    };
  });
}

function getYears() {
  const currentYear = new Date().getFullYear();
  return [
    (currentYear - 4).toString() + 'A',
    (currentYear - 3).toString() + 'A',
    (currentYear - 2).toString() + 'A',
    (currentYear - 1).toString() + 'A',
    currentYear.toString() + 'P',
    (currentYear + 1).toString() + 'P',
    (currentYear + 2).toString() + 'P',
  ];
}

function updateLogbook(object) {
  useSetLogbookItems(object.customerLogs);
}
</script>
