<template>
  <div
    ref="dropdown"
    class="search-input-dropdown multiselect-dropdown"
  >
    <div
      v-for="(item, index) in computedItems"
      :key="index"
      tabindex="0"
      class="text-truncate"
      :class="{ 'bg-selected': selectedItemIds.includes(item.id) }"
      @click="handleClickedItem(item)"
      @keydown.enter="handleClickedItem(item)"
      @mousedown.prevent
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script setup>
// import { useFocusItem, useGoDown, useGoUp } from '@/composables/UseHotkeyNodeSelection';
import { computed, ref } from 'vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  initialSelectedItems: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['clickedItem', 'loadDropdownRefs']);

const dropdown = ref(null);

const computedItems = computed(() => {
  // if (dropdown.value?.children) {
  //   emit('loadDropdownRefs', dropdown.value.children);
  // }
  return props.data;
});

const selectedItemIds = computed(() => {
  return props.initialSelectedItems.map((selectedItem) => {
    if (!isNaN(parseInt(selectedItem.id))) {
      return parseInt(selectedItem.id);
    }

    return selectedItem.id;
  });
});

function handleClickedItem(item) {
  if (!isNaN(parseInt(item.id))) {
    item.id = parseInt(item.id);
  }

  emit('clickedItem', item);
}

// function handleHoveredItem(event) {
//   useFocusItem(event);
// }
// function goUp(event) {
//   useGoUp(event, dropdown.value.children);
// }
// function goDown(event) {
//   useGoDown(event, dropdown.value.children);
// }
</script>
