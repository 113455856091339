import TabsView from '@/views/Router/TabsView.vue';
import OfferDetailsView from '@/views/Sales/SalesStatus/Offer/OfferDetailsView.vue';
import OfferCertaintiesListView from '@/views/Sales/SalesStatus/Offer/OfferCertaintiesListView.vue';
import OfferDocumentFormatView from '@/views/Sales/SalesStatus/Offer/OfferDocumentFormatView.vue';
import OfferRequiredDocumentsListView from '@/views/Sales/SalesStatus/Offer/OfferRequiredDocumentsListView.vue';

export default {
  path: 'offer',
  name: 'offer',
  component: TabsView,
  redirect: { name: 'offer details' },
  meta: {
    preview: 'offer',
    menutype: 'tabs',
  },
  children: [
    {
      path: 'new',
      name: 'new offer',
      component: OfferDetailsView,
    },
    {
      path: 'details',
      name: 'offer details',
      component: OfferDetailsView,
      meta: {
        title: 'details',
        menutype: 'tab',
      },
    },
    {
      path: 'document-format',
      name: 'offer document format',
      component: OfferDocumentFormatView,
      meta: {
        title: 'offer_document_format',
        menutype: 'tab',
      },
    },
    {
      path: 'certainties',
      name: 'offer certainties',
      component: OfferCertaintiesListView,
      meta: {
        title: 'offer_certainties',
        menutype: 'tab',
      },
    },
    {
      path: 'required-documents',
      name: 'offer required documents',
      component: OfferRequiredDocumentsListView,
      meta: {
        title: 'offer_required_documents',
        menutype: 'tab',
      },
    },
  ],
};
