<template>
  <div>
    <BaseModal
      :show="props.show"
      :modal-title="$t('modal.confirm_creditsafe_data')"
      min-height="680px"
      modal-dialog-classes="creditsafe-debtor-modal"
      custom-footer
      @close="closeModal"
    >
      <BaseSpinner
        v-if="_.isEmpty(record)"
        type="primary"
      />
      <template v-else>
        <p class="fw-semibold mb-2">{{ $t('client.company_details') }}</p>
        <div class="modal-text-spacing-left">
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('general.company_name') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ originalRecord.name }}</div>
        </div>
        <div class="modal-text-spacing-left">
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('company.legal_form') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ originalRecord.legalForm }}</div>
        </div>
        <div class="modal-text-spacing-left">
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('address.address_information') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">
            {{ originalRecord.street }} {{ originalRecord.houseNumber }}
          </div>
        </div>
        <div class="modal-text-spacing-left">
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('address.postal_code') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ originalRecord.postalCode }}</div>
        </div>
        <div class="modal-text-spacing-left">
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('address.city') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ originalRecord.city }}</div>
        </div>
        <div class="modal-text-spacing-left">
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('address.country') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ originalRecord.country }}</div>
        </div>
        <div class="modal-text-spacing-left">
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('general.phone') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ originalRecord.telephone }}</div>
        </div>
        <div class="modal-text-spacing-left">
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('general.website') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ originalRecord.website }}</div>
        </div>
        <div class="modal-text-spacing-left">
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('general.registration_number') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ originalRecord.regNo }}</div>
        </div>
        <div class="modal-text-spacing-left">
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('general.vat_number') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ originalRecord.vatNo }}</div>
        </div>
        <div class="modal-text-spacing-left">
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('client.branch') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ originalRecord.branch }}</div>
        </div>
        <p class="fw-semibold mb-2 mt-3">{{ $t('sales.debtor_analyses.debtor_data') }}</p>
        <div>
          <div class="d-inline-flex creditsafe-icon-wrapper">
            <BaseIcon
              v-if="record.regNo"
              class="creditsafe-icon-delete"
              icon="fa-solid fa-xmark"
              @click="removeValueFromRecord('regNo')"
            />
          </div>
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('general.registration_number') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.regNo }}</div>
        </div>
        <div>
          <div class="d-inline-flex creditsafe-icon-wrapper">
            <BaseIcon
              v-if="record.country"
              class="creditsafe-icon-delete"
              icon="fa-solid fa-xmark"
              @click="removeValueFromRecord('country')"
            />
          </div>
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">{{ $t('address.country') }}</div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.country }}</div>
        </div>
        <div class="flex-grow-1">
          <div class="d-inline-flex creditsafe-icon-wrapper">
            <BaseIcon
              v-if="record.creditsafeScore"
              class="creditsafe-icon-delete"
              icon="fa-solid fa-xmark"
              @click="removeValueFromRecord('creditsafeScore')"
            />
          </div>
          <div class="d-inline-flex ms-2 creditsafe-modal-subtitle">
            {{ $t('sales.debtor_analyses.creditsafe_score') }}
          </div>
          <div class="d-inline-flex ms-4 creditsafe-modal-confirm-value">{{ record.creditsafeScore }}</div>
        </div>
        <div class="description text-secondary pb-3">
          {{ $t('description.creditsafe_modal_description') }}
        </div>
      </template>
      <template
        v-if="!_.isEmpty(record)"
        #modalFooter
      >
        <SecondaryButton
          class="me-auto"
          @click="goBack"
        >
          {{ $t('ui.previous') }}
        </SecondaryButton>
        <SecondaryButton @click="cancel">{{ $t('ui.cancel') }}</SecondaryButton>
        <SaveButton
          :pending="false"
          :label="$t('ui.save')"
          @submit="submit"
        />
      </template>
    </BaseModal>
  </div>
</template>

<script setup>
import BaseIcon from '@/components/general/BaseIcon.vue';
import SaveButton from '@/components/buttons/SaveButton.vue';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import BaseModal from '@/components/BaseModal.vue';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { computed, ref } from 'vue';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  show: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close', 'previous', 'submit', 'cancel']);

const showModal = ref(true);

const record = computed(() => {
  return props.initialRecord;
});

const originalRecord = structuredClone(props.initialRecord);

function removeValueFromRecord(key) {
  record.value[key] = null;
  if (key === 'street') {
    record.value['houseNumber'] = null;
  }
}

function submit() {
  emit('submit', record.value);
}
function closeModal() {
  showModal.value = false;
  emit('close', showModal.value);
}
function goBack() {
  emit('previous');
}
function cancel() {
  record.value.regNo = originalRecord.regNo;
  record.value.country = originalRecord.country;
  record.value.creditsafeScore = originalRecord.creditsafeScore;
}
</script>
