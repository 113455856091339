<template>
  <BaseColumn class="p-0 sidebar-nav">
    <div class="sticky-top">
      <div class="prospect-name-box text-truncate">
        {{ prospectCompany.name_plus_legal_form }}
      </div>
      <div class="prospect-nav-title-border" />
      <SubNav>
        <div class="prospect-sub-nav mt-4 pt-1">
          <RouterLink
            :to="{ name: 'data' }"
            class="prospect-sub-nav-item"
            :class="{ active: route.path.includes('/data') }"
          >
            <div class="content-wrapper">
              {{ $t('navigation.master_data') }}
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'annual figures' }"
            class="prospect-sub-nav-item"
            :class="{ active: route.path.includes('/annual-figures') }"
          >
            <div class="content-wrapper">
              {{ $t('navigation.annual_figures') }}
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'debtor and creditor analyses' }"
            class="prospect-sub-nav-item"
            :class="{ active: route.path.includes('/debtor-creditor-analyses') }"
          >
            <div class="content-wrapper">
              {{ $t('navigation.debtor_creditor_analyses') }}
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'organogram company' }"
            class="prospect-sub-nav-item"
            :class="{ active: route.path.includes('/organogram/company') }"
          >
            <div class="content-wrapper">
              {{ $t('navigation.organogram') }}
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'documents' }"
            class="prospect-sub-nav-item"
            :class="{ active: route.path.includes('/documents') }"
          >
            <div class="content-wrapper">
              {{ $t('navigation.documents') }}
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'offer' }"
            class="prospect-sub-nav-item"
            :class="{ active: route.path.includes('/offer') }"
          >
            <div class="content-wrapper">
              {{ $t('navigation.offer') }}
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'credit proposal' }"
            class="prospect-sub-nav-item"
            :class="{ active: route.path.includes('/credit-proposal') }"
          >
            <div class="content-wrapper">
              {{ $t('navigation.credit_proposal') }}
            </div>
          </RouterLink>
          <RouterLink
            :to="props.prospectStatus.credit_proposal?.status !== APPROVED ? '' : { name: '' }"
            class="prospect-sub-nav-item"
            :class="{
              active: route.path.includes('/sales-status-contract'),
              disabled: props.prospectStatus.credit_proposal?.status !== APPROVED,
            }"
          >
            <div class="content-wrapper">
              {{ $t('navigation.contract') }}
            </div>
          </RouterLink>
          <template v-if="route.name === 'creditsafe search update'">
            <RouterLink
              :to="{ name: 'creditsafe search update' }"
              class="prospect-sub-nav-item"
            >
              <div class="content-wrapper">
                {{ $t('navigation.creditsafe') }}
              </div>
            </RouterLink>
          </template>
        </div>
      </SubNav>
    </div>
  </BaseColumn>
</template>

<script setup>
import { APPROVED } from '@/configs/constants/Status';
import { useGetCompany } from '@/composables/UseProspectViewData';
import { BaseColumn, SubNav } from '@impact-factoring/impact-branding';
import { useRoute } from 'vue-router';
import { ref } from 'vue';

const props = defineProps({
  prospectStatus: {
    type: Object,
    default: () => ({}),
  },
});

const route = useRoute();
const prospectCompany = ref(useGetCompany());
</script>
