<template>
  <IconButton
    button-style="primary"
    :label-left="props.label"
    :icon="saveIcon"
    :disabled="disabled"
    @click="emit('submit')"
  />
  <slot />
</template>

<script setup>
import IconButton from '@/components/buttons/IconButton.vue';
import { computed } from 'vue';

const props = defineProps({
  pending: {
    type: Boolean,
    required: true,
  },
  label: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
  },
});
const emit = defineEmits(['submit']);

const saveIcon = computed(() => {
  return props.pending ? 'fa-solid fa-spinner fa-spin-pulse' : null;
});
</script>
