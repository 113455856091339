<template>
  <div>
    <a
      href="#logbookSidebar"
      type="button"
      data-bs-toggle="offcanvas"
      class="btn btn-sidebar btn-sidebar-right"
      :class="{ active: isActive }"
    >
      {{ $t('sales.log.title') }}
    </a>
    <BaseSidebar
      id="logbookSidebar"
      backdrop-disabled
    >
      <LogbookCard
        v-if="props.initialRecord?.customer_log?.length"
        :initial-record="props.initialRecord"
        @before-mount="handleEmitBeforeMount"
      />
    </BaseSidebar>
  </div>
</template>

<script setup>
import LogbookCard from '@/components/sales/LogbookCard.vue';
import BaseSidebar from '@/components/sidebar/BaseSidebar.vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['save', 'beforeMount']);

const isActive = ref(false);

onMounted(() => {
  const offCanvas = document.getElementById('logbookSidebar');

  offCanvas.addEventListener('show.bs.offcanvas', showOffCanvas);
  offCanvas.addEventListener('hide.bs.offcanvas', hideOffCanvas);
});

onBeforeUnmount(() => {
  const offCanvas = document.getElementById('logbookSidebar');

  offCanvas.removeEventListener('show.bs.offcanvas', showOffCanvas);
  offCanvas.removeEventListener('hide.bs.offcanvas', hideOffCanvas);
});

function showOffCanvas() {
  isActive.value = true;
}

function hideOffCanvas() {
  isActive.value = false;
}

function handleEmitBeforeMount(formId) {
  emit('beforeMount', formId);
}
</script>
