<template>
  <div>
    <OverviewTable class="table-intermediary-prospects">
      <template #headers>
        <th @click="handleToggleColumn('name')">
          {{ t('intermediary.prospect_company') }}
          <BaseIcon
            :class="{ active: toggle.key === toggle.keys.name }"
            icon="fa-solid fa-sort"
          />
        </th>
        <th @click="handleToggleColumn('status')">
          {{ t('ui.status') }}
          <BaseIcon
            :class="{ active: toggle.key === toggle.keys.status }"
            icon="fa-solid fa-sort"
          />
        </th>
      </template>
      <tr
        v-for="prospect in computedFilteredRecord"
        :key="prospect.id"
        class="cursor-pointer"
        @click="goToProspect(prospect.id)"
      >
        <td class="text-truncate">
          {{ prospect.name }}
        </td>
        <td class="text-truncate">
          {{ prospect.status }}
        </td>
      </tr>
    </OverviewTable>
  </div>
</template>

<script setup>
import OverviewTable from '@/components/tables/OverviewTable.vue';
import BaseIcon from '@/components/general/BaseIcon.vue';
import { TRANSLATED_CUSTOMER_STATUS } from '@/configs/constants/CustomerStatus';
import { useI18n } from 'vue-i18n';
import { computed, onBeforeMount, ref } from 'vue';
import _ from 'lodash';
import { useRouter } from 'vue-router';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
});

const router = useRouter();
const { t } = useI18n();

const record = ref(props.initialRecord);
const toggle = {
  key: 'name',
  previousValue: 'name',
  sortBy: 'asc',
  keys: {
    name: 'name',
    status: 'status',
  },
};

const computedFilteredRecord = computed(() => {
  const prospects = mapIntermediaryProspects(record.value);
  return _.orderBy(prospects, toggle.key, toggle.sortBy);
});

onBeforeMount(() => {
  toggleColumn();
});

function mapIntermediaryProspects() {
  return record.value.map((prospect) => {
    // todo status should include abort status and 'Oud klant'
    const status = TRANSLATED_CUSTOMER_STATUS[prospect.status];
    if (status) {
      prospect.status = TRANSLATED_CUSTOMER_STATUS[prospect.status];
    }

    return prospect;
  });
}

function handleToggleColumn(key) {
  toggle.key = key;

  if (toggle.sortBy === 'desc') {
    toggle.sortBy = 'asc';

    toggleColumn();
    return;
  }

  if (toggle.key === toggle.previousValue) {
    toggle.sortBy = 'desc';
  }

  toggleColumn();
}

function toggleColumn() {
  toggle.previousValue = toggle.key;
  record.value = _.orderBy(record.value, toggle.key, toggle.sortBy);
}

function goToProspect(salesStatusId) {
  router.push({
    name: 'edit sales status',
    params: { sales_status_id: salesStatusId },
  });
}
</script>
