import { useDotNotationStringToObject, useNestedObjectKeysToDotNotation } from '@/composables/UseObjectManipulation';
import useVuelidate from '@vuelidate/core';
import { helpers, maxValue, minValue, numeric } from '@vuelidate/validators';
import _ from 'lodash';

export async function useVuelidateValidation(validationRules, entity) {
  const vuelidate = useVuelidate(validationRules, entity);
  const { withAsync } = helpers;
  const errorMessages = [];

  await withAsync(vuelidate.value.$validate()).$validator.then((response) => {
    if (response) {
      return true;
    }
    for (const validationError of vuelidate.value.$errors) {
      if (validationError.$message) {
        const message = validationError.$message || validationError.$response.$message;
        if (errorMessages[validationError.$propertyPath]) {
          errorMessages[validationError.$propertyPath].push(message);
          continue;
        }
        errorMessages[validationError.$propertyPath] = [message];
      }
    }
  });
  return errorMessages;
}

export function useMergeDefaultVuelidateValidationRules(validationRules, entity) {
  const objectKeys = useNestedObjectKeysToDotNotation(entity);
  let defaultValidationRules = null;

  objectKeys.forEach((key) => {
    if (key.endsWith('_percentage')) {
      const rule = { numeric, minValue: minValue(0), maxValue: maxValue(100) };
      const createdObject = useDotNotationStringToObject(key, rule);
      defaultValidationRules = _.merge(defaultValidationRules, createdObject);
    }
    if (key.endsWith('_amount')) {
      const rule = { numeric, minValue: minValue(0) };
      const createdObject = useDotNotationStringToObject(key, rule);
      defaultValidationRules = _.merge(defaultValidationRules, createdObject);
    }
  });
  if (!defaultValidationRules) {
    return validationRules;
  }
  return _.merge(defaultValidationRules, validationRules);
}
