export function useNestedObjectKeysToDotNotation(object, prefix = '') {
  return Object.keys(object).reduce((result, key) => {
    if (typeof object[key] === 'object' && object[key] !== null) {
      return [...result, ...useNestedObjectKeysToDotNotation(object[key], prefix + key + '.')];
    }
    return [...result, prefix + key];
  }, []);
}

export function useDotNotationStringToObject(key, value) {
  const previousObject = {};
  let container = previousObject;
  key.split('.').map((k, i, values) => {
    container = container[k] = i === values.length - 1 ? value : {};
  });
  return previousObject;
}

export function useAppendToKeyValueObject(entities, id, name) {
  const index = Object.values(entities).findIndex((intermediary) => {
    return intermediary === name;
  });

  if (index === -1 && id) {
    entities = {
      ...entities,
      [id]: name,
    };
  }

  return entities;
}
