<template>
  <select
    v-bind="$attrs"
    :id="props.labelId"
    v-model="computedModelValue"
    class="form-select"
    :class="{
      'form-select--narrow': !props.notNarrow,
      'is-invalid': props.errors && props.errors.length,
      'w-100': props.fullWidth,
      'select-placeholder': !computedModelValue,
    }"
  >
    <option
      v-if="placeholder"
      value=""
      class="select-placeholder"
    >
      {{ placeholder }}
    </option>
    <template v-if="props.options && props.fieldName">
      <option
        v-for="(option, key) in props.options"
        :key="key"
        :value="option.id || option.value || key"
      >
        {{ option[props.fieldName] }}
      </option>
    </template>
    <template v-else-if="props.options && !props.fieldName">
      <option
        v-for="(option, key) in props.options"
        :key="key"
        :value="key"
      >
        {{ option }}
      </option>
    </template>
    <slot v-else />
  </select>
  <ErrorMessages
    v-if="props.showErrorMessages"
    :errors="props.errors"
  />
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import ErrorMessages from '@/components/ErrorMessages.vue';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  labelId: {
    type: String,
    default: null,
  },
  notNarrow: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: true,
  },
  options: {
    type: [Object, Array],
    default: null,
  },
  modelValue: {
    type: [String, Number, Object],
    default: null,
  },
  initialPlaceholder: {
    type: [String, Boolean],
    default: '',
  },
  fieldName: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
  showErrorMessages: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();
const emit = defineEmits(['update:modelValue']);
const placeholder = ref(props.initialPlaceholder);

const computedModelValue = computed({
  get() {
    if (!props.modelValue) {
      return '';
    }
    return props.modelValue;
  },
  set(value) {
    if (!value) {
      emit('update:modelValue', null);
      return;
    }
    const emitValue = isNaN(value) ? value : parseInt(value);
    emit('update:modelValue', emitValue);
  },
});

onMounted(() => {
  if (!props.initialPlaceholder && props.initialPlaceholder !== false) {
    placeholder.value = t('ui.placeholders.make_a_choice');
  }
});
</script>
