<template>
  <RouterLink
    v-if="isVueRouterInstalled && !isExternalLink"
    v-bind="$attrs"
    active-class="active"
    :class="linkClass"
    :to="routeObject"
  >
    <slot />
  </RouterLink>
  <a
    v-else
    v-bind="$attrs"
    :class="linkClass"
    :href="link"
  >
    <slot />
  </a>
</template>

<script>
import { getCurrentInstance } from 'vue';

export default {
  name: 'AppLink',
  inheritAttrs: false,
  props: {
    link: {
      type: [String, Object],
      required: true,
    },
    type: {
      type: String,
      default: null,
    },
  },
  setup() {
    const isVueRouterInstalled = !!getCurrentInstance().appContext.config.globalProperties.$router;

    return {
      isVueRouterInstalled,
    };
  },
  computed: {
    isExternalLink() {
      return typeof this.link === 'string' && this.link.startsWith('http');
    },
    linkClass() {
      return this.type ? `link-${this.type}` : '';
    },
    routeObject() {
      return Object.hasOwn(this.link, 'name') ? this.link : { name: this.link };
    },
  },
};
</script>
