<template>
  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <CustomerDataForm
    v-else-if="record"
    :initial-record="record"
    @save="updateLogbook"
  />
</template>

<script setup>
import apiClient from '@/services/ApiClient';
import CustomerDataForm from '@/forms/sales/sales-status/customer-data/CustomerDataForm.vue';
import { useSetToast } from '@/composables/UseToast';
import { useAmountSuffixConvertCentsToAmount } from '@/composables/UseNumberManipulation';
import { useSetLogbookItems } from '@/composables/UseProspectViewData';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';

const router = useRouter();
const { t } = useI18n();

const record = ref(null);
const loading = ref(false);

onBeforeMount(async () => {
  try {
    loading.value = true;
    record.value = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/customer_data`,
      {
        include: [
          'sales_status.company',
          'sales_status.company.address',
          'accounting_software',
          'customer_data_financial_facilities.financial_facility',
          'intermediary',
        ],
      }
    );

    setRecordValues();

    record.value = useAmountSuffixConvertCentsToAmount(record.value);
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_customer_data') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching customer data: ', error);
  } finally {
    loading.value = false;
  }
});

// should be removed when other legacy `sales_status` endpoints are refactored (inconsistent relational data manipulation in back-end)
function setRecordValues() {
  record.value.customer_data['intermediary'] = record.value.intermediary;
  record.value.customer_data['accounting_software'] = record.value.accounting_software;
  record.value.customer_data['customer_data_financial_facilities'] = record.value.customer_data_financial_facilities;

  record.value.customer_data['customer_data_financial_facilities'].data = _.sortBy(
    record.value.customer_data['customer_data_financial_facilities'].data,
    'id'
  );

  record.value.customer_data['customer_data_financial_facilities'].data.forEach((facility) => {
    facility.facility_types.map((typeId) => {
      return parseInt(typeId);
    });
  });

  delete record.value.intermediary;
  delete record.value.accounting_software;
  delete record.value.customer_data_financial_facilities;
}

function updateLogbook(object) {
  useSetLogbookItems(object.customerLogs);
}
</script>
