<template>
  <div
    class="row d-flex ps-0 mb-0"
    :class="[inlineRowClasses, wrapperClass]"
  >
    <label
      class="col-form-label form-label--narrow"
      :class="labelColumns"
      :for="labelId"
    >
      {{ props.labelContent }}
      <BaseTooltip
        v-if="props.tooltipContent"
        :content="props.tooltipContent"
      />
    </label>
    <div
      :class="inputColumns"
      class="d-flex align-items-center"
    >
      <ToggleSwitch
        :model-value="props.modelValue"
        :label-id="labelId"
        @change="updateValue"
      />
    </div>
  </div>
</template>

<script setup>
import BaseTooltip from '@/components/general/BaseTooltip.vue';
import ToggleSwitch from '@/components/form/ToggleSwitch.vue';
import { nanoid } from 'nanoid';
import { computed, useAttrs } from 'vue';

const props = defineProps({
  labelContent: {
    type: String,
    required: true,
  },
  labelColumns: {
    type: [String, Object, Array],
    default: 'col-md-3 col-12',
  },
  inputColumns: {
    type: [String, Object, Array],
    default: 'col-lg-9 col-xl-8 col-xxl-9',
  },
  wrapperClass: {
    type: [String, Array, Object],
    default: null,
  },
  rowClass: {
    type: [String, Object, Array],
    default: null,
  },
  tooltipContent: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const attrs = useAttrs();
const labelId = nanoid();

const roleSwitch = computed(() => {
  return attrs.role === 'switch';
});
const inlineRowClasses = computed(() => {
  return [props.rowClass, { 'form-switch': roleSwitch.value }];
});

function updateValue(event) {
  emit('update:modelValue', event.target.checked);
}
</script>
