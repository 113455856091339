import i18n from '@/lang/i18n-config';

const { t } = i18n.global;

const WORKING_CAPITAL = t('facility_types.working_capital');
const LOAN = t('facility_types.loan');
const OG_LOAN = t('facility_types.og_loan');
const RC = t('facility_types.rc');
const FACTORING = t('facility_types.factoring');
const LEASE = t('facility_types.lease');

export { WORKING_CAPITAL, LOAN, OG_LOAN, RC, FACTORING, LEASE };
export default { WORKING_CAPITAL, LOAN, OG_LOAN, RC, FACTORING, LEASE };
