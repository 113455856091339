import axios from 'axios';

const errorHandler = function (error) {
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 400: // bad request
      case 401: // unauthenticated
        if (error.response.data.message === 'Unauthenticated.') {
          window.location.href = `${process.env.VUE_APP_ACCOUNTS_SERVER_URL}/login`;
        }
        break;
      case 403: // forbidden
      case 422: // unprocessable entity
      case 500: // internal server error
      case 503: // service unavailable
      default:
    }
  }
};

const api = {
  async request(method, url, params, payload, headers, responseType) {
    // todo global replace (after git commit/push)
    try {
      if (url.startsWith('/ifapi')) {
        url = url.replace('/ifapi', '/api/v1');
      }

      let request = {
        url: url,
        method: method,
        params: {},
        headers: headers,
        responseType: responseType,
      };

      if (params) {
        request.params = { ...request.params, ...params };
      }
      if (payload) {
        request.data = payload;
      }

      const response = await axios.request(request);

      return response.data;
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  },

  async patch(url, payload) {
    try {
      return this.request('patch', url, null, payload);
    } catch (error) {
      errorHandler(error);
    }
  },
};

export default api;
