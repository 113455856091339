import { useNestedObjectKeysToDotNotation } from '@/composables/UseObjectManipulation';
import { useAmountToCents, useCentsToAmount } from '@/composables/UseNumberManipulation';
import _ from 'lodash';

const salesStatusData = {
  sales_status: {
    id: null,
    currency: null,
    company: {
      name: null,
      legal_form: null,
      name_plus_legal_form: null,
      company_registration_number: null,
      website: null,
      address: {
        address: null,
        postal_code: null,
        city: null,
      },
    },
    customer_data: {
      branch: null,
      annual_revenue_amount: null,
      debtors_count: null,
      invoices_count: null,
      average_invoice_amount: null,
      default_payment_term_days: null,
      average_payment_term_days: null,
      customer_data_financial_facilities: [
        {
          facility_amount: '',
          facility_types: null,
          financial_facility: {
            name: null,
          },
        },
      ],
    },
    credit_proposal: {
      limit_costs: null,
      limit_costs_description: null,
      seasonal_influences: null,
      seasonal_influences_description: null,
      billing_interval: null,
      debtors_appear_as_creditors: null,
      debtors_appear_as_creditors_description: null,
      nature_of_billing: null,
      bonus_or_payment_discounts: null,
      bonus_or_payment_discounts_description: null,
      credit_insured_debtors: null,
      credit_insured_debtors_description: null,
      write_offs_debtors_past_three_years: null,
      doubtful_debtors: null,
      doubtful_debtors_description: null,
      general_delivery_and_payment_conditions: null,
      portfolio_concentrations: null,
      portfolio_concentrations_description: null,
      order_to_cash_process: null,
      general_description: null,
      funding_request_reason: null,
      requested_funding_description: null,
      financials_description: null,
      file_ids: [],
      person_id: null,
      contact_persons: {
        id: null,
        job_title: null,
        person: {
          id: null,
          name: null,
        },
        contact_detail: {
          phone_primair: null,
          email_address: null,
        },
      },
      deviating_provisions: [
        {
          deviating_key: null,
          deviating_percentage: null,
          deviating_debtors: [
            {
              name: null,
            },
          ],
        },
      ],
    },
    offer: {
      max_financing_limit_amount: null,
      financing_rate_percentage: null,
      factor_commission_percentage: null,
      add_factoring_fee_abroad: false,
      factoring_fee_abroad_percentage: null,
      minimum_factor_commission_amount: null,
      euribor_interest_term_months: null,
      raise_advance_interest_rate_percentage: null,
      credit_provision_percentage: null,
      setup_fee_amount: null,
      duration_months: null,
      notice_period_months: null,
      own_risk_amount: null,
      transaction_financing: {
        is_in_offer_preview: false,
        transaction_limit_amount: '',
        monthly_transaction_interest_rate_percentage: null,
        setup_fee_amount: '',
        maximum_purchase_orders_revenue_percentage: null,
        maximum_annual_revenue_percentage: null,
        maximum_monthly_duration_individual_transaction: null,
      },
    },
    certainty_acts: [
      {
        certainty_rule_id: null,
        title_certainty_parsed: null,
        selected: false,
      },
    ],
    annual_figures: [
      {
        id: null,
        year: null,
        revenue_amount: '',
        gross_profit_amount: '',
        gross_margin_percentage: null,
        opex_amount: '',
        ebitda_amount: '',
        net_profit_amount: '',
      },
    ],
    debtor_analyses: {
      total_amount: 0,
      total_balance_percentage: 0,
      total_limit_amount: 0,
      debtor_analyse_rules: [
        {
          id: null,
          debtor_name: null,
          grade_score: null,
          creditsafe_score: null,
          total_amount: '',
          total_balance_percentage: null,
          limit_amount: '',
          memo: null,
        },
      ],
    },
    creditor_analyses: {
      total_amount: 0,
      customer_creditor_analyse: [
        {
          id: null,
          name: null,
          total_amount: '',
          nature_of_product: null,
          memo: null,
        },
      ],
    },
    organogram: [
      {
        id: null,
        company_id: null,
        person_id: null,
        card_id: null,
        parent_card_id: null,
        name: null,
      },
    ],
  },
};

export function useSetAndMergeSalesStatusObject(object) {
  const salesStatus = structuredClone(salesStatusData.sales_status);

  salesStatus.credit_proposal.deviating_provisions = mapCreditProposalDeviatingProvisions(
    object.sales_status.credit_proposal?.deviating_provisions,
    object.sales_status.debtor_analyse?.debtor_analyse_rules ||
      object.sales_status.debtor_analyses?.debtor_analyse_rules
  );

  object.sales_status.certainty_acts = mapSelectedCertaintyActs(object.sales_status.certainty_acts);
  salesStatus.certainty_acts = structuredClone(object.sales_status.certainty_acts);
  salesStatus.credit_proposal.file_ids = object.sales_status?.credit_proposal?.file_ids
    ? [...object.sales_status.credit_proposal.file_ids]
    : [];
  salesStatus.annual_figures = setArrayObjects(object.sales_status?.annual_figures, salesStatus.annual_figures);
  salesStatus.organogram = setArrayObjects(object.sales_status?.organogram, salesStatus.organogram);

  salesStatus.customer_data.customer_data_financial_facilities = mapFinancialFacilities(
    object.sales_status.customer_data?.customer_data_financial_facilities,
    salesStatus.customer_data.customer_data_financial_facilities
  );

  object.sales_status.debtor_analyses = mapDebtorAnalyses(object.sales_status.debtor_analyses);
  salesStatus.debtor_analyses.debtor_analyse_rules = setArrayObjects(
    object.sales_status.debtor_analyses?.debtor_analyse_rules,
    salesStatus.debtor_analyses.debtor_analyse_rules
  );

  object.sales_status.creditor_analyses = mapCreditorAnalyses(object.sales_status.creditor_analyses);
  salesStatus.creditor_analyses.customer_creditor_analyse = setArrayObjects(
    object.sales_status.creditor_analyses?.customer_creditor_analyse,
    salesStatus.creditor_analyses.customer_creditor_analyse
  );

  mergeSalesStatusObject(object, salesStatus);

  return salesStatus;
}

export function useCompareSalesStatusObject(source, target) {
  const sourceObjectKeys = useNestedObjectKeysToDotNotation(source);
  const targetObjectKeys = useNestedObjectKeysToDotNotation(target);

  const findInSource = sourceObjectKeys.findIndex((key) => {
    let sourceValue = _.get(source, key);
    const targetValue = _.get(target, key);

    if (sourceValue !== targetValue) {
      return true;
    }
  });

  const findInTarget = targetObjectKeys.findIndex((key) => {
    let sourceValue = _.get(source, key);
    const targetValue = _.get(target, key);

    if (sourceValue !== targetValue) {
      return true;
    }
  });

  if (findInSource !== -1 || findInTarget !== -1) {
    return true;
  }
  return false;
}

function setArrayObjects(source, target) {
  if (!source?.length) {
    return [];
  }

  const data = [];
  for (let i = 0; i < source.length; i++) {
    data[i] = structuredClone(target[0]);
  }

  return data;
}

function mergeSalesStatusObject(object, salesStatus) {
  const objectKeys = useNestedObjectKeysToDotNotation(salesStatus);

  objectKeys.forEach((key) => {
    const salesStatusValue = _.get(salesStatus, key);
    let recordValue = _.get(object.sales_status, key);

    if (salesStatusValue !== recordValue) {
      if (recordValue === undefined) {
        recordValue = salesStatusValue;
      }

      _.set(salesStatus, key, recordValue);
    }
  });
}

function mapCreditProposalDeviatingProvisions(source, debtorAnalyseRules) {
  if (!source?.length) {
    return [];
  }

  return source.map((provision, key) => {
    if (!source[key].deviating_debtors.length) {
      source[key].deviating_debtors = [];
      return provision;
    }

    const deviatingDebtors = provision.deviating_debtors.map((deviatingDebtor) => {
      const debtor = debtorAnalyseRules.find((debtor) => {
        return debtor.id === deviatingDebtor?.id || debtor.id === deviatingDebtor;
      });

      return { id: debtor.id, name: debtor.debtor_name };
    });

    provision.deviating_debtors = _.sortBy(deviatingDebtors, 'name');
    return provision;
  });
}

function mapFinancialFacilities(source, target) {
  if (!source?.length) {
    return [];
  }

  return source.map((facility) => {
    facility = structuredClone(target[0]);
    facility.facility_types = structuredClone(target[0].facility_types);
    facility.financial_facility = structuredClone(target[0].financial_facility);
    return facility;
  });
}

function mapSelectedCertaintyActs(source) {
  if (!source?.length) {
    return [];
  }

  return source
    .filter((certaintyAct) => certaintyAct.selected)
    .map((certaintyAct) => {
      return {
        certainty_rule_id: certaintyAct.certainty_rule_id,
        title_certainty_parsed: certaintyAct.title_certainty_parsed,
        selected: certaintyAct.selected,
      };
    });
}

function mapDebtorAnalyses(debtorAnalyse) {
  let debtorAnalyseRules = debtorAnalyse?.debtor_analyse_rules;

  if (!debtorAnalyseRules) {
    return [];
  }

  debtorAnalyseRules = _.orderBy(
    debtorAnalyseRules,
    (obj) => {
      return typeof obj.total_amount === 'string' ? useAmountToCents(obj.total_amount) : obj.total_amount;
    },
    'desc'
  );

  let totalBalancePercentage = 0;
  let debtorTotalAmount = 0;
  let debtorTotalLimitAmount = 0;

  debtorAnalyse.debtor_analyse_rules = debtorAnalyseRules
    .filter((rule) => {
      return rule?.debtor_name;
    })
    .filter((rule, key) => {
      if (key < 5) {
        if (rule.total_balance_percentage) {
          totalBalancePercentage += parseInt(rule.total_balance_percentage);
        }

        debtorTotalAmount +=
          typeof rule.total_amount === 'string' ? useAmountToCents(rule.total_amount) : rule.total_amount;
        debtorTotalLimitAmount +=
          typeof rule.limit_amount === 'string' ? useAmountToCents(rule.limit_amount) : rule.limit_amount;
      }
      return key < 5;
    });

  debtorAnalyse.total_balance_percentage = totalBalancePercentage;

  if (debtorTotalAmount) {
    debtorAnalyse.total_amount = useCentsToAmount(debtorTotalAmount);
  }
  if (debtorTotalLimitAmount) {
    debtorAnalyse.total_limit_amount = useCentsToAmount(debtorTotalLimitAmount);
  }

  return debtorAnalyse;
}

function mapCreditorAnalyses(creditorAnalyses) {
  let creditorAnalyse = creditorAnalyses?.customer_creditor_analyse;

  if (!creditorAnalyse) {
    return [];
  }

  creditorAnalyse = _.orderBy(
    creditorAnalyse,
    (obj) => (typeof obj.total_amount === 'string' ? useAmountToCents(obj.total_amount) : obj.total_amount),
    'desc'
  );

  let creditorTotalAmount = 0;

  creditorAnalyses.customer_creditor_analyse = creditorAnalyse
    .filter((rule) => {
      return rule?.name;
    })
    .filter((rule, key) => {
      if (key < 5) {
        creditorTotalAmount +=
          typeof rule.total_amount === 'string' ? useAmountToCents(rule.total_amount) : rule.total_amount;
      }
      return key < 5;
    });

  if (creditorTotalAmount) {
    creditorAnalyses.total_amount = useCentsToAmount(creditorTotalAmount);
  }
  return creditorAnalyses;
}
