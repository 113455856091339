<template>
  <SearchInput
    v-model="record.recipient.id"
    field-name="id"
    :errors="errorMessages['recipient.id']"
    :entities="computedContactPersons"
    :initial-placeholder="$t('ui.placeholders.search_person')"
    :label-content="$t('offer.addressee')"
  />
  <LabelCheckbox
    v-model="record.use_informal_salutation"
    :label-content="$t('offer.informal_salutation')"
    :errors="errorMessages['use_informal_salutation']"
    wrapper-class="mt-2 mb-2"
    inline-row
  />
  <LabelTextarea
    v-model="record.personalized_intro"
    :label-content="$t('offer.personalized_introduction')"
    :errors="errorMessages['personalized_intro']"
    input-columns="col-12 col-lg-5 col-xl-4"
    wrapper-class="mb-2 pb-1"
    rows="9"
  />
  <MultiselectSearchInput
    v-model="record.signer_ids"
    :entities="computedCompetencePersons"
    :return-array-with-ids="true"
    :initial-placeholder="$t('ui.placeholders.search_person')"
    :label-content="$t('offer.signers')"
    :tooltip-content="$t('tooltip.persons_with_authorised_signatory')"
    :errors="errorMessages['signer_ids']"
  />
  <FormFooter
    :pending="loading"
    :disabled="_.isEqual(record, originalRecord) || loading"
    class="mt-auto"
    @save="handleSave"
    @cancel="handleCancel"
  />
</template>

<script setup>
import SearchInput from '@/components/form-controls/SearchInput/SearchInput.vue';
import LabelCheckbox from '@/components/form/LabelCheckbox.vue';
import LabelTextarea from '@/components/form/LabelTextarea.vue';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import apiClient from '@/services/ApiClient';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import { useMapContactPersons } from '@/composables/UseProspectViewData';
import MultiselectSearchInput from '@/components/form-controls/Multiselect/MultiselectSearchInput.vue';
import { ref, onBeforeMount, computed } from 'vue';
import { nanoid } from 'nanoid';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  contactPersons: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['save']);

const router = useRouter();
const { t } = useI18n();

const record = ref(props.initialRecord);
const originalRecord = ref({});
const validationRules = ref({});
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();

const computedContactPersons = computed(() => {
  unsetRecipientIdIfSelectedContactPersonDoesNotExists();
  return useMapContactPersons(props.contactPersons);
});

const computedCompetencePersons = computed(() => {
  signerIsExistingContactPerson();
  return props.contactPersons
    .filter(
      (contactPerson) =>
        contactPerson?.person?.id &&
        contactPerson?.person?.name &&
        (contactPerson.person.competence === 'co_sign' || contactPerson.person.competence === 'independent_sign')
    )
    .reduce((acc, item) => {
      acc[item.person.id] = item.person.name;
      return acc;
    }, {});
});

onBeforeMount(async () => {
  useBeforeRouteLeave();
  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);
});

function validateHasAtLeastTwoCoSignPersons() {
  const coSigners = record.value.signer_ids.filter((signerId) => {
    return props.contactPersons.find((contactPerson) => {
      return parseInt(contactPerson.person.id) === signerId && contactPerson.person.competence === 'co_sign';
    });
  });

  if (coSigners.length === 1 && coSigners.length === record.value.signer_ids.length) {
    errorMessages.value['signer_ids'] = [t('vuelidate.error.minimum_of_2_co_sign_is_required')];
    return;
  }

  delete errorMessages.value['signer_ids'];
}

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);

  validateHasAtLeastTwoCoSignPersons();

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  try {
    loading.value = true;

    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/document`,
      {
        include: ['recipient'],
      },
      structuredClone(record.value)
    );
    useSetToast('success', t('toast.success.offer_document_format_successfully_updated'));
    emit('save', {
      customerLogs: response.customer_log,
      offerPreview: response.offer_preview,
    });

    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.updating_offer_document_format') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while updating offer document format: ', error);
  } finally {
    loading.value = false;
  }
}
function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  errorMessages.value = [];
}

function unsetRecipientIdIfSelectedContactPersonDoesNotExists() {
  const contactPersons = props.contactPersons;

  if (!Number.isInteger(record.value.recipient?.id)) {
    return;
  }

  const recipient = contactPersons.find((contactPerson) => {
    return parseInt(contactPerson.person.id) === parseInt(record.value.recipient.id);
  });

  if (!recipient) {
    record.value.recipient.id = null;
    record.value.recipient.name = null;
    originalRecord.value.recipient.id = null;
    originalRecord.value.recipient.name = null;
  }
}

function signerIsExistingContactPerson() {
  record.value.signer_ids = record.value.signer_ids?.filter((signerId) => {
    return props.contactPersons.find((contactPerson) => {
      return (
        parseInt(contactPerson.person.id) === signerId &&
        contactPerson?.person?.id &&
        contactPerson?.person?.name &&
        (contactPerson.person.competence === 'co_sign' || contactPerson.person.competence === 'independent_sign')
      );
    });
  });

  originalRecord.value.signer_ids = originalRecord.value.signer_ids?.filter((signerId) => {
    return props.contactPersons.find((contactPerson) => {
      return (
        parseInt(contactPerson.person.id) === signerId &&
        contactPerson?.person?.id &&
        contactPerson?.person?.name &&
        (contactPerson.person.competence === 'co_sign' || contactPerson.person.competence === 'independent_sign')
      );
    });
  });
}
</script>
