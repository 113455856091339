<template>
  <BaseModal
    v-if="props.show"
    :show="props.show"
    :modal-title="modalTitle"
    size="sm"
    modal-dialog-classes="modal-confirm"
    @confirm="confirm"
    @close="close"
  >
    {{ props.message }}
    <slot />
  </BaseModal>
</template>

<script setup>
import BaseModal from '@/components/BaseModal.vue';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['confirm', 'close']);

const { t } = useI18n();
const modalTitle = ref(props.title);

onBeforeMount(() => {
  if (!modalTitle.value) {
    modalTitle.value = t('ui.confirm');
  }
});

function confirm() {
  emit('confirm', props.id);
}

function close() {
  emit('close');
}
</script>
