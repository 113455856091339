<template>
  <BaseRow class="credit-proposal-assessment content-with-offcanvas">
    <BaseColumn class="col-12 col-lg-6 pb-4 mb-3">
      <IconButton
        v-if="!record.data.sales_status.credit_proposal?.evaluations?.length"
        :label-right="$t('sales.credit_proposal.request_evaluation')"
        button-style="primary"
        outline
        @click="toggleSendToCommissionModal"
      />
      <span
        v-else
        class="d-flex align-items-center info-description h-auto mb-4"
      >
        <BaseIcon
          icon="fa-solid fa-info"
          class="icon-static me-2"
        />
        <span>{{ $t('description.adjusted_data_is_marked_in_red') }}</span>
      </span>
      <div class="title">
        <span>
          {{ t('sales.credit_proposal.proposal.data_applicant') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('edit sales status')"
        />
      </div>
      <BaseTable
        borderless
        hoverable
        class="table-credit-proposal-assessment"
      >
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('company.name_plus_legal_form')"
          >
            {{ t('general.company_name') }}
          </td>
          <td class="w-50 text-break">
            {{ record.sales_status.company.name_plus_legal_form }}
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('company.company_registration_number')"
          >
            {{ t('general.company_registration_number') }}
          </td>
          <td class="w-50 text-break">
            {{ record.sales_status.company.company_registration_number }}
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('company.address.address') ||
              compareKeyWithRevision('company.address.postal_code') ||
              compareKeyWithRevision('company.address.city')
            "
          >
            {{ t('sales.credit_proposal.proposal.visiting_address') }}
          </td>
          <td class="w-50 text-break">
            <template v-if="record.sales_status.company.address.address">
              {{ record.sales_status.company.address.address }},
            </template>
            {{ record.sales_status.company.address.postal_code }}
            {{ record.sales_status.company.address.city }}
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('company.website')"
          >
            {{ t('general.website') }}
          </td>
          <td class="w-50 text-break">
            {{ record.sales_status.company.website }}
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('customer_data.branch')"
          >
            {{ t('sales.credit_proposal.proposal.business_activity') }}
          </td>
          <td class="w-50 text-break">
            {{ record.sales_status.customer_data.branch }}
          </td>
        </tr>
        <tr>
          <td class="w-50">
            <div class="mt-2 fw-semibold">{{ t('navigation.contactperson') }}</div>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('credit_proposal.person.name')"
          >
            {{ t('general.name') }}
          </td>
          <td class="w-50 text-break">
            {{ props.creditProposalContactPerson?.person?.name }}
          </td>
        </tr>
        <tr></tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('credit_proposal.person.company_person_contact.job_title')"
          >
            {{ t('contact_persons.job_title') }}
          </td>
          <td class="w-50 text-break">
            {{ TRANSLATED_JOB_TITLES[props.creditProposalContactPerson?.job_title] }}
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('credit_proposal.person.company_person_contact.contact_detail.phone_primair')
            "
          >
            {{ t('general.phone') }}
          </td>
          <td class="w-50 text-break">
            {{ props.creditProposalContactPerson?.contact_detail?.phone_primair }}
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('credit_proposal.person.company_person_contact.contact_detail.email_address')
            "
          >
            {{ t('general.email') }}
          </td>
          <td class="w-50 text-break">
            {{ props.creditProposalContactPerson?.contact_detail?.email_address }}
          </td>
        </tr>
      </BaseTable>

      <div class="title">
        <span>
          {{ t('sales.credit_proposal.proposal.factoring_rates_and_provisions') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('offer details')"
        />
      </div>
      <BaseTable
        borderless
        class="table-credit-proposal-assessment"
      >
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('offer.max_financing_limit_amount')"
          >
            {{ t('offer.maximum_purchase_price') }}
          </td>
          <td class="w-50 text-break">
            {{ numberFormatter(record.sales_status.offer.max_financing_limit_amount) }}
            <template v-if="record.sales_status.offer.max_financing_limit_amount">
              {{ record.sales_status.currency }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('offer.financing_rate_percentage')"
          >
            {{ t('offer.maximum_advance') }}
          </td>
          <td class="w-50 text-break">
            <template v-if="record.sales_status.offer.financing_rate_percentage">
              {{ numberFormatter(record.sales_status.offer.financing_rate_percentage, 2) }}%
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('offer.factor_commission_percentage')"
          >
            {{ t('sales.credit_proposal.proposal.factoring_fee_inland') }}
          </td>
          <td class="w-50 text-break">
            <template v-if="record.sales_status.offer.factor_commission_percentage">
              {{ numberFormatter(record.sales_status.offer.factor_commission_percentage, 4) }}%
            </template>
          </td>
        </tr>
        <tr
          v-if="
            record.sales_status.offer.add_factoring_fee_abroad &&
            record.sales_status.offer.factoring_fee_abroad_percentage
          "
        >
          <td
            class="w-50"
            :class="{
              'text-danger':
                compareKeyWithRevisionCheckboxValue('offer.add_factoring_fee_abroad') ||
                compareKeyWithRevision('offer.factoring_fee_abroad_percentage'),
            }"
          >
            {{ t('offer.factoring_fee_abroad') }}
          </td>
          <td class="w-50 text-break">
            <template v-if="record.sales_status.offer.add_factoring_fee_abroad">
              {{ numberFormatter(record.sales_status.offer.factoring_fee_abroad_percentage, 4) }}%
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('offer.minimum_factor_commission_amount')"
          >
            {{ t('offer.minimum_factoring_fee_per_contract_year') }}
          </td>
          <td class="w-50 text-break">
            {{ numberFormatter(record.sales_status.offer.minimum_factor_commission_amount) }}
            <template v-if="record.sales_status.offer.minimum_factor_commission_amount">
              {{ record.sales_status.currency }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('offer.euribor_interest_term_months') ||
              compareKeyWithRevision('offer.raise_advance_interest_rate_percentage')
            "
          >
            {{ t('sales.credit_proposal.proposal.interest_per_year') }}
          </td>
          <td class="w-50 text-break">
            <template
              v-if="
                record.sales_status.offer.euribor_interest_term_months &&
                record.sales_status.offer.raise_advance_interest_rate_percentage
              "
            >
              {{
                t('sales.credit_proposal.proposal.euribor_interest_term_plus_raise_advance_interest_rate', {
                  months: record.sales_status.offer.euribor_interest_term_months,
                  percentage: numberFormatter(record.sales_status.offer.raise_advance_interest_rate_percentage, 3),
                })
              }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('offer.credit_provision_percentage')"
          >
            {{ t('offer.credit_provision') }}
          </td>
          <td class="w-50 text-break">
            <template v-if="record.sales_status.offer.credit_provision_percentage">
              {{
                t('sales.credit_proposal.proposal.credit_provision_percentage_per_month', {
                  percentage: numberFormatter(record.sales_status.offer.credit_provision_percentage, 5),
                })
              }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('offer.setup_fee_amount')"
          >
            {{ t('offer.setup_fee') }}
          </td>
          <td class="w-50 text-break">
            {{ numberFormatter(record.sales_status.offer.setup_fee_amount) }}
            <template v-if="record.sales_status.offer.setup_fee_amount">{{ record.sales_status.currency }}</template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('offer.duration_months') || compareKeyWithRevision('offer.notice_period_months')
            "
          >
            {{ t('sales.credit_proposal.proposal.duration_contract') }}
          </td>
          <td class="w-50 text-break">
            <template
              v-if="record.sales_status.offer.duration_months || record.sales_status.offer.notice_period_months"
            >
              {{
                t(
                  'sales.credit_proposal.proposal.duration_contract_in_years_and_months_with_a_notice_period_of_months',
                  {
                    duration_years: record.sales_status.offer.duration_months / 12,
                    duration_months: record.sales_status.offer.duration_months,
                    notice_months: record.sales_status.offer.notice_period_months,
                  }
                )
              }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('offer.own_risk_amount')"
          >
            {{ t('offer.own_risk_credit_insurance') }}
          </td>
          <td class="w-50 text-break">
            <template v-if="record.sales_status.offer.own_risk_amount">
              {{
                t('sales.credit_proposal.proposal.own_risk_credit_insurance_per_year_excl_tax', {
                  amount: numberFormatter(record.sales_status.offer.own_risk_amount),
                  currency: record.sales_status.currency,
                })
              }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('credit_proposal.limit_costs') ||
              compareKeyWithRevision('credit_proposal.limit_costs_description')
            "
          >
            {{ t('sales.credit_proposal.costs_limit') }}
          </td>
          <td class="w-50 text-break white-space-pre-wrap">
            <template
              v-if="
                record.sales_status.credit_proposal?.limit_costs &&
                record.sales_status.credit_proposal?.limit_costs_description
              "
            >
              {{ costsLimitOptions[record.sales_status.credit_proposal.limit_costs] }},
            </template>

            <template v-else-if="record.sales_status.credit_proposal?.limit_costs">
              {{ costsLimitOptions[record.sales_status.credit_proposal.limit_costs] }}
            </template>

            {{ record.sales_status.credit_proposal?.limit_costs_description }}
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="{
              'text-danger': compareDeviatingProvisionsRecordWithRevisionLength() || hasDeviatingProvisionsDifference,
            }"
          >
            {{ t('sales.credit_proposal.deviating_provisions') }}
          </td>
          <td class="w-50 text-break">
            <template v-if="record.sales_status.credit_proposal?.deviating_provisions.length">
              <div
                v-for="(deviatingProvision, key) in record.sales_status.credit_proposal.deviating_provisions"
                :key="key"
              >
                <template v-if="deviatingProvision?.deviating_key || deviatingProvision?.deviating_percentage">
                  <div
                    class="fw-semibold"
                    :class="{ 'mt-3': key }"
                  >
                    {{
                      deviatingProvision.deviating_key === 'maximum_concentration_percentage'
                        ? $t('sales.credit_proposal.deviating_provisions_options.maximum_concentration_percentage') +
                          ':'
                        : ''
                    }}
                  </div>
                  <span
                    :class="
                      compareDeviatingProvisionsWithRevision(
                        'credit_proposal.deviating_provisions.' + key,
                        'deviating_percentage'
                      )
                    "
                  >
                    {{ deviatingProvision.deviating_percentage ? deviatingProvision.deviating_percentage + '%' : '-' }}
                  </span>
                </template>
                <template v-if="deviatingProvision?.deviating_debtors.length">
                  <div class="fw-semibold mt-1">{{ $t('sales.debtor_analyses.debtors') }}:</div>
                  <div
                    v-for="(debtor, index) in deviatingProvision.deviating_debtors"
                    :key="index"
                  >
                    <span
                      :class="
                        compareDeviatingProvisionDebtorsWithRevision(
                          'credit_proposal.deviating_provisions.' + key + '.deviating_debtors',
                          'name',
                          debtor.id
                        )
                      "
                    >
                      <template v-if="index !== deviatingProvision.deviating_debtors.length - 1">
                        {{ debtor.name }},
                      </template>
                      <template v-else>{{ debtor.name }}</template>
                    </span>
                  </div>
                </template>
              </div>
            </template>
          </td>
        </tr>
      </BaseTable>

      <div class="title">
        <span :class="compareKeyWithRevisionCheckboxValue('offer.transaction_financing.is_in_offer_preview')">
          {{ t('sales.credit_proposal.proposal.rates_and_provisions_for_transaction_financing') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('offer')"
        />
      </div>
      <BaseTable
        v-if="record.sales_status.offer.transaction_financing.is_in_offer_preview"
        borderless
        class="table-credit-proposal-assessment"
      >
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('offer.transaction_financing.is_in_offer_preview') ||
              compareKeyWithRevision('offer.transaction_financing.transaction_limit_amount')
            "
          >
            {{ t('offer.maximum_financing_limit') }}
          </td>
          <td class="w-50 text-break">
            {{ numberFormatter(record.sales_status.offer.transaction_financing.transaction_limit_amount) }}
            <template v-if="record.sales_status.offer.transaction_financing.transaction_limit_amount">
              {{ record.sales_status.currency }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('offer.transaction_financing.is_in_offer_preview') ||
              compareKeyWithRevision('offer.transaction_financing.monthly_transaction_interest_rate_percentage')
            "
          >
            {{ t('offer.monthly_interest_rate') }}
          </td>
          <td class="w-50 text-break">
            <template
              v-if="record.sales_status.offer.transaction_financing.monthly_transaction_interest_rate_percentage"
            >
              {{
                t('sales.credit_proposal.proposal.percentage_based_on_daily_interest_payment', {
                  percentage:
                    record.sales_status.offer.transaction_financing.monthly_transaction_interest_rate_percentage,
                })
              }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('offer.transaction_financing.is_in_offer_preview') ||
              compareKeyWithRevision('offer.transaction_financing.setup_fee_amount')
            "
          >
            {{ t('offer.setup_fee') }}
          </td>
          <td class="w-50 text-break">
            {{ numberFormatter(record.sales_status.offer.transaction_financing.setup_fee_amount) }}
            <template v-if="record.sales_status.offer.transaction_financing.setup_fee_amount">
              {{ record.sales_status.currency }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('offer.transaction_financing.is_in_offer_preview') ||
              compareKeyWithRevision('offer.transaction_financing.maximum_purchase_orders_revenue_percentage')
            "
          >
            {{ t('sales.credit_proposal.proposal.maximum_advance_on_purchase_value') }}
          </td>
          <td class="w-50 text-break">
            <template v-if="record.sales_status.offer.transaction_financing.maximum_purchase_orders_revenue_percentage">
              {{ record.sales_status.offer.transaction_financing.maximum_purchase_orders_revenue_percentage }}%
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('offer.transaction_financing.is_in_offer_preview') ||
              compareKeyWithRevision('offer.transaction_financing.maximum_annual_revenue_percentage')
            "
          >
            {{ t('offer.maximum_percentage_annual_revenue') }}
          </td>
          <td class="w-50 text-break">
            <template v-if="record.sales_status.offer.transaction_financing.maximum_annual_revenue_percentage">
              {{
                numberFormatter(record.sales_status.offer.transaction_financing.maximum_annual_revenue_percentage, 2)
              }}%
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('offer.transaction_financing.is_in_offer_preview') ||
              compareKeyWithRevision('offer.transaction_financing.maximum_monthly_duration_individual_transaction')
            "
          >
            {{ t('sales.credit_proposal.proposal.maximum_term_per_transaction') }}
          </td>
          <td class="w-50 text-break">
            <template
              v-if="record.sales_status.offer.transaction_financing.maximum_monthly_duration_individual_transaction"
            >
              {{
                t(
                  'ui.time.month',
                  record.sales_status.offer.transaction_financing.maximum_monthly_duration_individual_transaction
                )
              }}
            </template>
          </td>
        </tr>
      </BaseTable>
      <template v-else>-</template>

      <div class="title">
        <span>
          {{ t('sales.credit_proposal.proposal.data') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('edit sales status')"
        />
      </div>
      <BaseTable
        borderless
        class="table-credit-proposal-assessment"
      >
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('customer_data.annual_revenue_amount')"
          >
            {{ t('sales.credit_proposal.proposal.annual_sales_excl_tax') }}
          </td>
          <td class="w-50 text-break">
            {{ numberFormatter(record.sales_status.customer_data.annual_revenue_amount) }}
            <template v-if="record.sales_status.customer_data.annual_revenue_amount">
              {{ record.sales_status.currency }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('customer_data.debtors_count')"
          >
            {{ t('customer_data.debtors_count') }}
          </td>
          <td class="w-50 text-break">
            {{ record.sales_status.customer_data.debtors_count }}
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('customer_data.invoices_count')"
          >
            {{ t('customer_data.invoices_count') }}
          </td>
          <td class="w-50 text-break">
            {{ record.sales_status.customer_data.invoices_count }}
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('customer_data.average_invoice_amount')"
          >
            {{ t('sales.credit_proposal.proposal.average_invoice_value') }}
          </td>
          <td class="w-50 text-break">
            {{ numberFormatter(record.sales_status.customer_data.average_invoice_amount) }}
            <template v-if="record.sales_status.customer_data.average_invoice_amount">
              {{ record.sales_status.currency }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('customer_data.default_payment_term_days')"
          >
            {{ t('customer_data.default_payment_term_days') }}
          </td>
          <td class="w-50 text-break">
            <template v-if="record.sales_status.customer_data.default_payment_term_days">
              {{
                t('ui.time.average_days', {
                  days: record.sales_status.customer_data.default_payment_term_days,
                })
              }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('customer_data.average_payment_term_days')"
          >
            {{ t('sales.credit_proposal.proposal.average_payment_term') }}
          </td>
          <td class="w-50 text-break">
            <template v-if="record.sales_status.customer_data.default_payment_term_days">
              {{
                t('ui.time.average_days', {
                  days: record.sales_status.customer_data.average_payment_term_days,
                })
              }}
            </template>
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="
              compareKeyWithRevision('credit_proposal.seasonal_influences') ||
              compareKeyWithRevision('credit_proposal.seasonal_influences_description')
            "
          >
            {{ t('sales.credit_proposal.are_there_seasonal_influences') }}
          </td>
          <td class="w-50 text-break white-space-pre-wrap">
            <template
              v-if="
                record.sales_status.credit_proposal?.seasonal_influences &&
                record.sales_status.credit_proposal?.seasonal_influences_description
              "
            >
              {{ yesNoOptions[record.sales_status.credit_proposal.seasonal_influences] }},
            </template>

            <template v-else-if="record.sales_status.credit_proposal?.seasonal_influences">
              {{ yesNoOptions[record.sales_status.credit_proposal.seasonal_influences] }}
            </template>

            {{ record.sales_status.credit_proposal?.seasonal_influences_description }}
          </td>
        </tr>
        <tr>
          <td
            class="w-50"
            :class="compareKeyWithRevision('credit_proposal.billing_interval')"
          >
            {{ t('sales.credit_proposal.what_is_the_billing_interval') }}
          </td>
          <td class="w-50 text-break">
            {{ billingIntervalOptions[record.sales_status.credit_proposal?.billing_interval] }}
          </td>
        </tr>
      </BaseTable>

      <div class="title">
        <span
          :class="{
            'text-danger': compareRecordWithRevisionLength('certainty_acts') || hasCertaintiesDifference,
          }"
        >
          {{ t('sales.credit_proposal.proposal.certainties') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('offer certainties')"
        />
      </div>
      <BaseTable
        v-if="Object.keys(record.sales_status.certainty_acts).length"
        borderless
        class="table-credit-proposal-assessment table-credit-proposal-assessment-certainties"
      >
        <tr
          v-for="certainty in Object.entries(record.sales_status.certainty_acts)"
          :key="certainty"
        >
          <td :class="compareCertaintiesWithRevision('certainty_acts', certainty[1].certainty_rule_id)">
            <span v-safe-html="certainty[1].title_certainty_parsed" />
          </td>
        </tr>
      </BaseTable>
      <template v-else>-</template>

      <div class="title">
        <span>
          {{ t('sales.credit_proposal.proposal.factorability') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('credit proposal details')"
        />
      </div>
      <BaseTable
        borderless
        class="table-credit-proposal-assessment table-factorability"
      >
        <tr>
          <td
            class="factorability-title"
            :class="
              compareKeyWithRevision('credit_proposal.debtors_appear_as_creditors') ||
              compareKeyWithRevision('credit_proposal.debtors_appear_as_creditors_description')
            "
          >
            {{ t('sales.credit_proposal.are_there_debtors_who_also_appear_as_creditors') }}
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-4 white-space-pre-wrap">
              <template v-if="record.sales_status.credit_proposal?.debtors_appear_as_creditors">
                <template v-if="record.sales_status.credit_proposal?.debtors_appear_as_creditors_description">
                  {{ yesNoOptions[record.sales_status.credit_proposal?.debtors_appear_as_creditors] }},
                </template>

                <template v-else>
                  {{ yesNoOptions[record.sales_status.credit_proposal?.debtors_appear_as_creditors] }}
                </template>
              </template>

              {{ record.sales_status.credit_proposal?.debtors_appear_as_creditors_description }}

              <template
                v-if="
                  !record.sales_status.credit_proposal?.debtors_appear_as_creditors &&
                  !record.sales_status.credit_proposal?.debtors_appear_as_creditors_description
                "
              >
                -
              </template>
            </div>
          </td>
        </tr>
        <tr>
          <td
            class="factorability-title"
            :class="compareKeyWithRevision('credit_proposal.nature_of_billing')"
          >
            {{ t('sales.credit_proposal.nature_of_billing') }}
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-4 white-space-pre-wrap">
              {{ natureOfBillingOptions[record.sales_status.credit_proposal?.nature_of_billing] || '   -' }}
            </div>
          </td>
        </tr>
        <tr>
          <td
            class="factorability-title"
            :class="
              compareKeyWithRevision('credit_proposal.bonus_or_payment_discounts') ||
              compareKeyWithRevision('credit_proposal.bonus_or_payment_discounts_description')
            "
          >
            {{ t('sales.credit_proposal.are_bonuses_and_or_payment_discounts_given') }}
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-4 white-space-pre-wrap">
              <template v-if="record.sales_status.credit_proposal?.bonus_or_payment_discounts">
                <template v-if="record.sales_status.credit_proposal?.bonus_or_payment_discounts_description">
                  {{ yesNoOptions[record.sales_status.credit_proposal?.bonus_or_payment_discounts] }},
                </template>

                <template v-else>
                  {{ yesNoOptions[record.sales_status.credit_proposal?.bonus_or_payment_discounts] }}
                </template>
              </template>

              {{ record.sales_status.credit_proposal?.bonus_or_payment_discounts_description }}

              <template
                v-if="
                  !record.sales_status.credit_proposal?.bonus_or_payment_discounts &&
                  !record.sales_status.credit_proposal?.bonus_or_payment_discounts_description
                "
              >
                -
              </template>
            </div>
          </td>
        </tr>
        <tr>
          <td
            class="factorability-title"
            :class="
              compareKeyWithRevision('credit_proposal.credit_insured_debtors') ||
              compareKeyWithRevision('credit_proposal.credit_insured_debtors_description')
            "
          >
            {{ t('sales.credit_proposal.are_the_debtors_credit_insured') }}
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-4 white-space-pre-wrap">
              <template v-if="record.sales_status.credit_proposal?.credit_insured_debtors">
                <template v-if="record.sales_status.credit_proposal?.credit_insured_debtors_description">
                  {{ yesNoOptions[record.sales_status.credit_proposal?.credit_insured_debtors] }},
                </template>

                <template v-else>
                  {{ yesNoOptions[record.sales_status.credit_proposal?.credit_insured_debtors] }}
                </template>
              </template>

              {{ record.sales_status.credit_proposal?.credit_insured_debtors_description }}

              <template
                v-if="
                  !record.sales_status.credit_proposal?.credit_insured_debtors &&
                  !record.sales_status.credit_proposal?.credit_insured_debtors_description
                "
              >
                -
              </template>
            </div>
          </td>
        </tr>
        <tr>
          <td
            class="factorability-title"
            :class="compareKeyWithRevision('credit_proposal.write_offs_debtors_past_three_years')"
          >
            {{ t('sales.credit_proposal.what_were_the_write_offs_on_debtors_in_the_past_three_financial_years') }}
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-4 white-space-pre-wrap">
              {{ record.sales_status.credit_proposal?.write_offs_debtors_past_three_years || '   -' }}
            </div>
          </td>
        </tr>
        <tr>
          <td
            class="factorability-title"
            :class="
              compareKeyWithRevision('credit_proposal.doubtful_debtors') ||
              compareKeyWithRevision('credit_proposal.doubtful_debtors_description')
            "
          >
            {{ t('sales.credit_proposal.are_there_any_bad_debts_at_the_moment') }}
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-4 white-space-pre-wrap">
              <template v-if="record.sales_status.credit_proposal?.doubtful_debtors">
                <template v-if="record.sales_status.credit_proposal?.doubtful_debtors_description">
                  {{ yesNoOptions[record.sales_status.credit_proposal?.doubtful_debtors] }},
                </template>

                <template v-else>
                  {{ yesNoOptions[record.sales_status.credit_proposal?.doubtful_debtors] }}
                </template>
              </template>

              {{ record.sales_status.credit_proposal?.doubtful_debtors_description }}

              <template
                v-if="
                  !record.sales_status.credit_proposal?.doubtful_debtors &&
                  !record.sales_status.credit_proposal?.doubtful_debtors_description
                "
              >
                -
              </template>
            </div>
          </td>
        </tr>
        <tr>
          <td
            class="factorability-title"
            :class="compareKeyWithRevision('credit_proposal.general_delivery_and_payment_conditions')"
          >
            {{ t('sales.credit_proposal.which_general_terms_and_conditions_of_delivery_and_payment_are_used') }}
          </td>
        </tr>
        <tr>
          <td>
            <div class="mb-4 white-space-pre-wrap">
              {{ record.sales_status.credit_proposal?.general_delivery_and_payment_conditions || '   -' }}
            </div>
          </td>
        </tr>
        <tr></tr>
        <tr>
          <td
            class="factorability-title"
            :class="
              compareKeyWithRevision('credit_proposal.portfolio_concentrations') ||
              compareKeyWithRevision('credit_proposal.portfolio_concentrations_description')
            "
          >
            {{ t('sales.credit_proposal.are_there_concentrations_in_the_portfolio') }}
          </td>
        </tr>
        <tr>
          <td class="white-space-pre-wrap">
            <template v-if="record.sales_status.credit_proposal?.portfolio_concentrations">
              <template v-if="record.sales_status.credit_proposal?.portfolio_concentrations_description">
                {{ yesNoOptions[record.sales_status.credit_proposal?.portfolio_concentrations] }},
              </template>

              <template v-else>
                {{ yesNoOptions[record.sales_status.credit_proposal?.portfolio_concentrations] }}
              </template>
            </template>

            {{ record.sales_status.credit_proposal?.portfolio_concentrations_description }}

            <template
              v-if="
                !record.sales_status.credit_proposal?.portfolio_concentrations &&
                !record.sales_status.credit_proposal?.portfolio_concentrations_description
              "
            >
              -
            </template>
          </td>
        </tr>
      </BaseTable>
      <div
        class="title pt-4"
        :class="{
          'mb-1': topFiveDebtors?.length,
        }"
      >
        <span
          :class="{
            'text-danger':
              compareRecordWithRevisionLength('debtor_analyses.debtor_analyse_rules') || hasDebtorAnalysesDifference,
          }"
        >
          {{ t('sales.credit_proposal.proposal.top_five_debtors') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('debtor analyses')"
        />
      </div>
      <BaseTable
        v-if="topFiveDebtors?.length"
        :headers="debtorHeaders"
        borderless
        class="table-credit-proposal-assessment table-debtor-analyse-rules"
      >
        <tr
          v-for="(debtorAnalyseRule, key) in topFiveDebtors"
          :key="key"
        >
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareDebtorAnalysesWithRevision(
                  'debtor_analyses.debtor_analyse_rules',
                  'debtor_name',
                  debtorAnalyseRule.id
                )
              "
            >
              {{ debtorAnalyseRule.debtor_name }}
            </div>
          </td>
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareDebtorAnalysesWithRevision(
                  'debtor_analyses.debtor_analyse_rules',
                  'grade_score',
                  debtorAnalyseRule.id
                )
              "
            >
              {{ debtorAnalyseRule.grade_score || '-' }}
            </div>
            <div
              v-if="key === topFiveDebtors.length - 1"
              class="box-shadow-border"
            />
          </td>
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareDebtorAnalysesWithRevision(
                  'debtor_analyses.debtor_analyse_rules',
                  'creditsafe_score',
                  debtorAnalyseRule.id
                )
              "
            >
              {{ debtorAnalyseRule.creditsafe_score || '-' }}
            </div>
            <div
              v-if="key === topFiveDebtors.length - 1"
              class="box-shadow-border"
            />
          </td>
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareDebtorAnalysesWithRevision(
                  'debtor_analyses.debtor_analyse_rules',
                  'total_amount',
                  debtorAnalyseRule.id
                )
              "
            >
              {{ numberFormatter(debtorAnalyseRule.total_amount) || '-' }}
            </div>
            <div
              v-if="key === topFiveDebtors.length - 1"
              class="box-shadow-border"
            />
          </td>
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareDebtorAnalysesWithRevision(
                  'debtor_analyses.debtor_analyse_rules',
                  'total_balance_percentage',
                  debtorAnalyseRule.id
                )
              "
            >
              {{ debtorAnalyseRule.total_balance_percentage || '-' }}
            </div>
            <div
              v-if="key === topFiveDebtors.length - 1"
              class="box-shadow-border"
            />
          </td>
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareDebtorAnalysesWithRevision(
                  'debtor_analyses.debtor_analyse_rules',
                  'limit_amount',
                  debtorAnalyseRule.id
                )
              "
            >
              {{ numberFormatter(debtorAnalyseRule.limit_amount) || '-' }}
            </div>
            <div
              v-if="key === topFiveDebtors.length - 1"
              class="box-shadow-border"
            />
          </td>
          <td class="text-truncate">
            <div
              :class="
                compareDebtorAnalysesWithRevision('debtor_analyses.debtor_analyse_rules', 'memo', debtorAnalyseRule.id)
              "
              class="text-truncate"
            >
              {{ debtorAnalyseRule.memo || '-' }}
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div>
              {{ t('ui.numbers.total') }}
            </div>
          </td>
          <td></td>
          <td>
            <div :class="compareKeyWithRevision('debtor_analyses.total_amount')">
              {{ record.sales_status.debtor_analyses.total_amount }}
            </div>
          </td>
          <td>
            <div :class="compareKeyWithRevision('debtor_analyses.total_balance_percentage')">
              {{ record.sales_status.debtor_analyses.total_balance_percentage }}
            </div>
          </td>
          <td>
            <div :class="compareKeyWithRevision('debtor_analyses.total_limit_amount')">
              {{ record.sales_status.debtor_analyses.total_limit_amount }}
            </div>
          </td>
          <td></td>
        </tr>
      </BaseTable>
      <template v-else>-</template>

      <div
        class="title"
        :class="{
          'mb-1': record.sales_status.creditor_analyses?.customer_creditor_analyse?.length,
        }"
      >
        <span
          :class="{
            'text-danger':
              compareRecordWithRevisionLength('creditor_analyses.customer_creditor_analyse') ||
              hasCreditorAnalysesDifference,
          }"
        >
          {{ t('sales.credit_proposal.proposal.top_five_creditors') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('creditor analyses')"
        />
      </div>
      <BaseTable
        v-if="topFiveCreditors.length"
        :headers="creditorHeaders"
        borderless
        class="table-credit-proposal-assessment table-debtor-analyse-rules table-creditor-analyse-rules"
      >
        <tr
          v-for="(creditorAnalyseRule, key) in topFiveCreditors"
          :key="key"
        >
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareCreditorAnalysesWithRevision(
                  'creditor_analyses.customer_creditor_analyse',
                  'name',
                  creditorAnalyseRule.id
                )
              "
            >
              {{ creditorAnalyseRule.name || '-' }}
            </div>
            <div
              v-if="key === topFiveCreditors.length - 1"
              class="box-shadow-border"
            />
          </td>
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareCreditorAnalysesWithRevision(
                  'creditor_analyses.customer_creditor_analyse',
                  'total_amount',
                  creditorAnalyseRule.id
                )
              "
            >
              {{ numberFormatter(creditorAnalyseRule.total_amount) || '-' }}
            </div>
            <div
              v-if="key === topFiveCreditors.length - 1"
              class="box-shadow-border"
            />
          </td>
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareCreditorAnalysesWithRevision(
                  'creditor_analyses.customer_creditor_analyse',
                  'nature_of_product',
                  creditorAnalyseRule.id
                )
              "
            >
              {{ creditorAnalyseRule.nature_of_product || '-' }}
            </div>
          </td>
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareCreditorAnalysesWithRevision(
                  'creditor_analyses.customer_creditor_analyse',
                  'memo',
                  creditorAnalyseRule.id
                )
              "
            >
              {{ creditorAnalyseRule.memo || '-' }}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {{ t('ui.numbers.total') }}
            </div>
          </td>
          <td>
            <div :class="compareKeyWithRevision('creditor_analyses.total_amount')">
              {{ record.sales_status.creditor_analyses.total_amount || '-' }}
            </div>
          </td>
          <td></td>
        </tr>
      </BaseTable>
      <template v-else>-</template>

      <div class="title pt-4">
        <span :class="compareKeyWithRevision('credit_proposal.order_to_cash_process')">
          {{ t('sales.credit_proposal.describe_the_order_to_cash_process') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('credit proposal details')"
        />
      </div>
      <div class="white-space-pre-wrap">{{ record.sales_status.credit_proposal?.order_to_cash_process || '-' }}</div>
      <div class="title">
        <span :class="compareKeyWithRevision('credit_proposal.general_description')">
          {{ t('sales.credit_proposal.general') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('credit proposal details')"
        />
      </div>
      <div class="white-space-pre-wrap">{{ record.sales_status.credit_proposal?.general_description || '-' }}</div>

      <div class="title">
        <span>
          {{ t('sales.organogram.company_organogram') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('organogram company')"
        />
      </div>
      <template v-if="loadingCompanyOrganogram">
        <BaseSpinner type="primary" />
      </template>
      <template
        v-else-if="
          !organogramCompanyRecord?.cards?.length ||
          (organogramCompanyRecord?.cards?.length === 1 &&
            !loadingCompanyOrganogram &&
            !organogramCompanyRecord?.cards[0].company_id &&
            !organogramCompanyRecord?.cards[0].person_id)
        "
        >-</template
      >
      <CreditProposalAssessmentCommitteeOrganogramForm
        v-else-if="organogramCompanyRecord?.cards?.length && !loadingCompanyOrganogram"
        :contact-persons="contactPersons"
        :initial-record="organogramCompanyRecord"
      />

      <div class="title">
        <span>
          {{ t('sales.organogram.shareholder_organogram') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('organogram shareholder')"
        />
      </div>
      <template v-if="loadingShareholderOrganogram">
        <BaseSpinner type="primary" />
      </template>
      <template
        v-else-if="
          !organogramShareholderRecord?.cards?.length ||
          (organogramShareholderRecord?.cards?.length === 1 &&
            !loadingShareholderOrganogram &&
            !organogramShareholderRecord?.cards[0].company_id &&
            !organogramShareholderRecord?.cards[0].person_id)
        "
        >-</template
      >
      <CreditProposalAssessmentCommitteeOrganogramForm
        v-else-if="organogramShareholderRecord?.cards?.length && !loadingShareholderOrganogram"
        :contact-persons="contactPersons"
        :initial-record="organogramShareholderRecord"
      />

      <div class="title">
        <span :class="compareKeyWithRevision('credit_proposal.funding_request_reason')">
          {{ t('sales.credit_proposal.reason_for_funding_request') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('credit proposal details')"
        />
      </div>
      <div class="white-space-pre-wrap">{{ record.sales_status.credit_proposal?.funding_request_reason || '-' }}</div>

      <div
        class="title"
        :class="{ 'mb-1': hasFinancialFacilities }"
      >
        <span
          :class="{
            'text-danger':
              compareFinancialFacilitiesWithRevisionLength('customer_data.customer_data_financial_facilities') ||
              hasFinancialFacilitiesDifference,
          }"
        >
          {{ t('sales.credit_proposal.current_financial_facilities') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('edit sales status')"
        />
      </div>
      <BaseTable
        v-if="hasFinancialFacilities"
        :headers="currentFinancialFacilityHeaders"
        borderless
        class="table-credit-proposal-assessment table-financial-facilities"
      >
        <tr
          v-for="(financialFacility, key) in record.sales_status.customer_data.customer_data_financial_facilities"
          :key="key"
        >
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareFinancialFacilitiesWithRevision(
                  'customer_data.customer_data_financial_facilities.' + key + '.financial_facility.name'
                )
              "
            >
              {{ financialFacility.financial_facility?.name || '-' }}
            </div>
          </td>
          <td class="text-truncate">
            <div
              class="text-truncate"
              :class="
                compareFinancialFacilitiesWithRevision(
                  'customer_data.customer_data_financial_facilities.' + key + '.facility_amount'
                )
              "
            >
              {{ numberFormatter(financialFacility?.facility_amount) || '-' }}
            </div>
          </td>
          <td class="text-truncate">
            <div class="text-truncate">
              <template v-if="!Object.entries(financialFacility.facility_types).length">-</template>
              <span
                v-for="(facilityType, facilityTypeKey) in Object.entries(financialFacility.facility_types)"
                :key="facilityTypeKey"
                :class="
                  compareFinancialFacilitiesWithRevision(
                    'customer_data.customer_data_financial_facilities.' + key + '.facility_types.' + facilityTypeKey
                  )
                "
              >
                {{ facilityType[1]
                }}<template v-if="facilityTypeKey !== Object.keys(financialFacility.facility_types).length - 1">
                  {{ ', ' }}
                </template>
                <template v-if="facilityTypeKey === Object.keys(financialFacility.facility_types).length - 2">
                  {{ ' ' + t('general.and').toLowerCase() + ' ' }}
                </template>
              </span>
            </div>
          </td>
        </tr>
      </BaseTable>
      <template v-else>-</template>
      <div class="title">
        <span :class="compareKeyWithRevision('credit_proposal.requested_funding_description')">
          {{ t('sales.credit_proposal.requested_funding') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('credit proposal details')"
        />
      </div>
      <div class="white-space-pre-wrap">
        {{ record.sales_status.credit_proposal?.requested_funding_description || '-' }}
      </div>
      <div class="title">
        <span :class="compareKeyWithRevision('credit_proposal.financials_description')">
          {{ t('sales.credit_proposal.financials') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('credit proposal details')"
        />
      </div>
      <div class="white-space-pre-wrap">
        {{ record.sales_status.credit_proposal?.financials_description || '-' }}
      </div>
      <div
        class="title"
        :class="{ 'mb-1': hasAnnualFigures !== -1 }"
      >
        <span
          :class="{
            'text-danger': compareRecordWithRevisionLength('annual_figures') || hasAnnualFiguresDifference,
          }"
        >
          {{ t('sales.annual_figures.title') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('annual figures')"
        />
      </div>
      <ResponsiveTable
        v-if="hasAnnualFigures !== -1"
        borderless
        class="table-credit-proposal-assessment table-annual-figures"
      >
        <template #headers>
          <th scope="col">
            {{ t('sales.annual_figures.in_currency_times_thousand', { currency: record.sales_status.currency }) }}
          </th>
          <th
            v-for="annualFigure in record.sales_status.annual_figures"
            :key="annualFigure"
          >
            {{ annualFigure.year }}
          </th>
        </template>
        <tr>
          <th
            scope="row"
            class="table-text-spacing-left"
          >
            {{ t('sales.annual_figures.revenue') }}
          </th>
          <td
            v-for="(annualFigure, key) in record.sales_status.annual_figures"
            :key="key"
            class="table-text-spacing-left"
          >
            <span :class="compareAnnualFiguresWithRevision('annual_figures', 'revenue_amount', annualFigure.id)">
              {{ numberFormatter(annualFigure.revenue_amount) || '-' }}
            </span>
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            class="table-text-spacing-left"
          >
            {{ t('sales.annual_figures.gross_profit') }}
          </th>
          <td
            v-for="(annualFigure, key) in record.sales_status.annual_figures"
            :key="key"
            class="table-text-spacing-left"
          >
            <span :class="compareAnnualFiguresWithRevision('annual_figures', 'gross_profit_amount', annualFigure.id)">
              {{ numberFormatter(annualFigure.gross_profit_amount) || '-' }}
            </span>
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            class="table-text-spacing-left"
          >
            {{ t('sales.annual_figures.gross_margin_percentage') }}
          </th>
          <td
            v-for="(annualFigure, key) in record.sales_status.annual_figures"
            :key="key"
            class="table-text-spacing-left"
          >
            <template
              v-if="annualFigure.gross_margin_percentage || parseInt(annualFigure.gross_margin_percentage) === 0"
            >
              <span
                :class="compareAnnualFiguresWithRevision('annual_figures', 'gross_margin_percentage', annualFigure.id)"
              >
                {{ annualFigure.gross_margin_percentage }}%
              </span>
            </template>
            <template v-else>-</template>
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            class="table-text-spacing-left"
          >
            {{ t('sales.annual_figures.opex') }}
          </th>
          <td
            v-for="(annualFigure, key) in record.sales_status.annual_figures"
            :key="key"
            class="table-text-spacing-left"
          >
            <span :class="compareAnnualFiguresWithRevision('annual_figures', 'opex_amount', annualFigure.id)">
              {{ numberFormatter(annualFigure.opex_amount) || '-' }}
            </span>
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            class="table-text-spacing-left"
          >
            {{ t('sales.annual_figures.ebitda') }}
          </th>
          <td
            v-for="(annualFigure, key) in record.sales_status.annual_figures"
            :key="key"
            class="table-text-spacing-left"
          >
            <span :class="compareAnnualFiguresWithRevision('annual_figures', 'ebitda_amount', annualFigure.id)">
              {{ numberFormatter(annualFigure.ebitda_amount) || '-' }}
            </span>
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            class="table-text-spacing-left"
          >
            {{ t('sales.annual_figures.net_profit') }}
          </th>
          <td
            v-for="(annualFigure, key) in record.sales_status.annual_figures"
            :key="key"
            class="table-text-spacing-left"
          >
            <span :class="compareAnnualFiguresWithRevision('annual_figures', 'net_profit_amount', annualFigure.id)">
              {{ numberFormatter(annualFigure.net_profit_amount) || '-' }}
            </span>
          </td>
        </tr>
      </ResponsiveTable>
      <template v-else>-</template>

      <div class="title">
        <span
          :class="{
            'text-danger': compareRecordWithRevisionLength('credit_proposal.file_ids') || hasDocumentFilesDifference,
          }"
        >
          {{ t('sales.credit_proposal.attachments') }}
        </span>
        <BaseIcon
          icon="fa-solid fa-pencil cursor-pointer fa-sm ms-1 px-2 py-1"
          @click="goToPage('credit proposal details')"
        />
      </div>
      <div
        v-if="record.sales_status.credit_proposal.file_ids.length"
        class="attachments"
      >
        <div
          v-for="(id, key) in record.sales_status.credit_proposal.file_ids"
          :key="key"
        >
          <FileTag
            v-if="getSelectedFile(id)"
            :file-key="key"
            :file-name="getSelectedFile(id)?.file_name"
            :error="!compareDocumentFilesWithRevision('credit_proposal.file_ids', id)"
            @click="openFile(getSelectedFile(id).url)"
          />
        </div>
      </div>
      <template v-else>-</template>
    </BaseColumn>
    <BaseColumn class="col-12 col-lg-6 pb-4 mb-3">
      <BaseModal
        v-if="showSendToCommissionModal && !record.data.sales_status.credit_proposal?.evaluations?.length"
        :show="showSendToCommissionModal && !record.data.sales_status.credit_proposal?.evaluations?.length"
        :modal-title="$t('sales.credit_proposal.send_credit_proposal_to_commission')"
        custom-footer
        size="md"
        @close="toggleSendToCommissionModal"
      >
        <div class="fw-medium mb-1">
          {{ $t('general.message') }}
        </div>
        <BaseTextarea
          v-model="evaluationData.email_description"
          rows="5"
        />
        <template #modalFooter>
          <SecondaryButton
            :disabled="evaluationData.users.length < 1 || loadingSendToCommission"
            @click.prevent="toggleSendToCommissionModal"
          >
            {{ t('ui.cancel') }}
          </SecondaryButton>
          <PrimaryButton
            :disabled="evaluationData.users.length < 1 || loadingSendToCommission"
            :loading="loadingSendToCommission"
            @click.prevent="handleSendToCommission"
          >
            {{ $t('ui.send') }}
          </PrimaryButton>
        </template>
      </BaseModal>
      <CreditProposalEvaluationForm
        v-if="record.data.sales_status.credit_proposal?.evaluations?.length"
        :initial-record="record.data.sales_status || {}"
        @save="handleEmitSave"
      />
    </BaseColumn>
  </BaseRow>
</template>

<script setup>
import BaseTable from '@/components/tables/BaseTable.vue';
import ResponsiveTable from '@/components/tables/ResponsiveTable.vue';
import { formatNumber } from '@/modules/formatters';
import CreditProposalEvaluationForm from '@/forms/sales/sales-status/credit-proposal/CreditProposalEvaluationForm.vue';
import BaseIcon from '@/components/general/BaseIcon.vue';
import { useAuthorizationStore } from '@/stores/authorization';
import { TRANSLATED_JOB_TITLES } from '@/configs/constants/JobTitles';
import FileTag from '@/components/form-controls/FileTag.vue';
import { APPROVED, EVALUATION } from '@/configs/constants/Status';
import { useSetAndMergeSalesStatusObject } from '@/composables/records/UseAssessmentCommittee';
import { useAmountSuffixConvertCentsToAmount } from '@/composables/UseNumberManipulation';
import { useSetToast } from '@/composables/UseToast';
import apiClient from '@/services/ApiClient';
import BaseModal from '@/components/BaseModal.vue';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import { useGetCompany, useIsOwner, useSetProspectCreditProposalStatus } from '@/composables/UseProspectViewData';
import CreditProposalAssessmentCommitteeOrganogramForm from '@/forms/sales/sales-status/credit-proposal/CreditProposalAssessmentCommitteeOrganogramForm.vue';
import { COMPANY, SHAREHOLDER } from '@/configs/constants/OrganogramTypes';
import { useEnumerationsStore } from '@/stores/enumerations';
import { BaseSpinner, BaseRow, BaseColumn } from '@impact-factoring/impact-branding';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import axios from 'axios';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  revisionRecord: {
    type: Object,
    default: () => ({}),
  },
  documentFiles: {
    type: Object,
    default: () => ({}),
  },
  contactPersons: {
    type: Object,
    default: () => ({}),
  },
  creditProposalContactPerson: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['save']);

const router = useRouter();
const { t } = useI18n();
const authorizationStore = useAuthorizationStore();
const enums = useEnumerationsStore();

const companyRecord = ref(null);
const organogramCompanyRecord = ref(null);
const organogramShareholderRecord = ref(null);
const debtorHeaders = ref([]);
const creditorHeaders = ref([]);
const currentFinancialFacilityHeaders = ref([]);
const hasDeviatingProvisionsDifference = ref(false);
const hasCertaintiesDifference = ref(false);
const hasDebtorAnalysesDifference = ref(false);
const hasCreditorAnalysesDifference = ref(false);
const hasFinancialFacilitiesDifference = ref(false);
const hasAnnualFiguresDifference = ref(false);
const hasDocumentFilesDifference = ref(false);
const loadingCompanyOrganogram = ref(false);
const loadingShareholderOrganogram = ref(false);
const loadingSendToCommission = ref(false);
const showSendToCommissionModal = ref(false);
const evaluationData = ref({
  users: getAssessmentCommitteeUserIds(), // todo set evaluation committee members via permissions
  email_description: null,
});

const yesNoOptions = {
  YES: t('ui.yes'),
  NO: t('ui.no'),
  UNKNOWN: t('ui.unknown'),
};
const costsLimitOptions = {
  STANDARD: t('sales.credit_proposal.costs_limit_options.standard'),
  DISCOUNT: t('sales.credit_proposal.costs_limit_options.discount'),
};
const billingIntervalOptions = {
  DAILY: t('ui.time.daily'),
  WEEKLY: t('ui.time.weekly'),
  MONTHLY: t('ui.time.monthly'),
};
const natureOfBillingOptions = {
  INSTALLMENT_BILLING: t('sales.credit_proposal.nature_of_billing_options.installment_billing'),
  SUBSCRIPTIONS: t('sales.credit_proposal.nature_of_billing_options.subscriptions'),
  ADVANCE_BILLING: t('sales.credit_proposal.nature_of_billing_options.advance_billing'),
  BACK_BILLING: t('sales.credit_proposal.nature_of_billing_options.back_billing'),
};
const formatOptions = {
  style: 'decimal',
  minimumFractionDigits: 2,
};

const hasFinancialFacilities = computed(() => {
  const financialFacility = record.value.sales_status.customer_data.customer_data_financial_facilities[0];
  if (
    (financialFacility?.facility_types && Object.keys(financialFacility?.facility_types)?.length) ||
    financialFacility?.facility_amount ||
    financialFacility?.financial_facility.name
  ) {
    return true;
  }
  return false;
});

const hasAnnualFigures = computed(() => {
  const annualFigures = record.value.sales_status.annual_figures;
  return annualFigures.findIndex((annualFigure) => {
    if (
      annualFigure.ebitda_amount ||
      annualFigure.gross_margin_percentage ||
      annualFigure.gross_profit_amount ||
      annualFigure.net_profit_amount ||
      annualFigure.opex_amount ||
      annualFigure.revenue_amount
    ) {
      return true;
    }
  });
});

const record = computed(() => {
  return props.initialRecord;
});

const topFiveDebtors = computed(() => {
  return record.value.sales_status.debtor_analyses.debtor_analyse_rules;
});

const topFiveCreditors = computed(() => {
  return record.value.sales_status.creditor_analyses.customer_creditor_analyse;
});

onBeforeMount(async () => {
  companyRecord.value = useGetCompany();

  debtorHeaders.value = [
    t('sales.debtor_analyses.debtor'),
    t('sales.debtor_analyses.grade'),
    t('sales.debtor_analyses.creditsafe_score_shorthand'),
    t('sales.credit_proposal.proposal.total_balance', { currency: record.value.sales_status.currency }),
    '%',
    t('sales.credit_proposal.credit_limit', { currency: record.value.sales_status.currency }),
    t('sales.debtor_analyses.particularities'),
  ];

  creditorHeaders.value = [
    t('sales.creditor_analyses.creditor'),
    t('sales.credit_proposal.proposal.total_balance', { currency: record.value.sales_status.currency }),
    t('sales.creditor_analyses.nature_of_product_or_service'),
    t('sales.creditor_analyses.particularities'),
  ];

  currentFinancialFacilityHeaders.value = [
    t('sales.credit_proposal.proposal.financier'),
    t('sales.credit_proposal.assessment_committee.current_facility', { currency: record.value.sales_status.currency }),
    t('customer_data.facility_type'),
  ];

  evaluationData.value.email_description = t('email.credit_proposal_message', {
    company: companyRecord.value.name_plus_legal_form,
    user: authorizationStore.user.name,
  });

  try {
    loadingCompanyOrganogram.value = true;
    const response = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/organogram/${COMPANY.toLowerCase()}`
    );

    if (response) {
      organogramCompanyRecord.value = response;
    } else {
      organogramCompanyRecord.value = { meta: null };
    }
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_organogram') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching organogram: ', error);
  } finally {
    loadingCompanyOrganogram.value = false;
  }

  try {
    loadingShareholderOrganogram.value = true;
    const response = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${
        router.currentRoute.value.params.sales_status_id
      }/organogram/${SHAREHOLDER.toLowerCase()}`
    );

    if (response) {
      organogramShareholderRecord.value = response;
    } else {
      organogramShareholderRecord.value = { meta: null };
    }
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_organogram') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching organogram: ', error);
  } finally {
    loadingShareholderOrganogram.value = false;
  }
});

function getAssessmentCommitteeUserIds() {
  return Object.keys(enums.data.users).map((userId) => parseInt(userId));
}

function numberFormatter(value, decimals = 2) {
  if (!value || Number.isInteger(value)) {
    return;
  }
  if (decimals !== 2) {
    formatOptions.minimumFractionDigits = decimals;
  }
  const number = formatNumber(value.replace('.', ','), formatOptions);
  formatOptions.minimumFractionDigits = 2;
  return number;
}

function goToPage(routerName) {
  router.push({ name: routerName, params: { sales_status_id: record.value.sales_status.id } });
}

function handleEmitSave(data) {
  emit('save', data);
}

function getSelectedFile(id) {
  return props.documentFiles.find((file) => {
    return file.id === id;
  });
}

function compareKeyWithRevision(key) {
  if (!Object.keys(props.revisionRecord).length) {
    return;
  }

  const recordValue = _.get(record.value.sales_status, key);
  let revisionValue = _.get(props.revisionRecord.data, key);

  if (recordValue !== revisionValue) {
    return 'text-danger';
  }
}

function compareKeyWithRevisionById(recordValue, revisionValue, field, id) {
  if (!Object.keys(props.revisionRecord).length) {
    return;
  }

  recordValue = recordValue?.find((object) => object.id === id)?.[field];
  revisionValue = revisionValue?.find((object) => object.id === id)?.[field];

  if (recordValue !== revisionValue) {
    return 'text-danger';
  }
}

function compareRecordWithRevisionLength(key) {
  if (!Object.keys(props.revisionRecord).length) {
    return;
  }

  let recordValue = _.get(record.value.sales_status, key);
  let revisionValue = _.get(props.revisionRecord.data, key);

  if (recordValue?.length !== revisionValue?.length) {
    return true;
  }
  return false;
}

function compareArrayKeyWithRevision(key, id) {
  if (!Object.keys(props.revisionRecord).length) {
    return true;
  }

  const revisionValue = _.get(props.revisionRecord.data, key);
  const filteredValue = revisionValue?.filter((value) => value === id);

  return !!filteredValue?.length;
}

function compareDeviatingProvisionDebtorsWithRevision(key, field, id) {
  if (!Object.keys(props.revisionRecord).length) {
    return;
  }

  const recordValue = _.get(record.value.sales_status, key);
  const revisionValue = _.get(props.revisionRecord.data, key);

  const cssClass = compareKeyWithRevisionById(recordValue, revisionValue, field, id);

  if (cssClass) {
    hasDeviatingProvisionsDifference.value = true;
  }

  return cssClass;
}

function compareFinancialFacilitiesWithRevisionLength() {
  if (!Object.keys(props.revisionRecord).length) {
    return false;
  }

  const key = 'customer_data.customer_data_financial_facilities';
  let recordValue = _.get(record.value.sales_status, key);
  let revisionValue = _.get(props.revisionRecord.data, key);

  const facilityTypesLengthDifference = recordValue.findIndex(
    (provision, key) => recordValue?.[key].facility_types.length !== revisionValue?.[key]?.facility_types.length
  );

  if (facilityTypesLengthDifference !== -1) {
    return true;
  }

  return compareRecordWithRevisionLength(key);
}

function compareDeviatingProvisionsRecordWithRevisionLength() {
  if (!Object.keys(props.revisionRecord).length) {
    return false;
  }

  const key = 'credit_proposal.deviating_provisions';
  let recordValue = _.get(record.value.sales_status, key);
  let revisionValue = _.get(props.revisionRecord.data, key);

  const deviatingDebtorsLengthDifference = recordValue.findIndex(
    (provision, key) => recordValue?.[key].deviating_debtors.length !== revisionValue?.[key]?.deviating_debtors.length
  );

  if (deviatingDebtorsLengthDifference !== -1) {
    return true;
  }

  return compareRecordWithRevisionLength(key);
}

function compareDeviatingProvisionsWithRevision(key, field) {
  if (!Object.keys(props.revisionRecord).length) {
    return;
  }

  const recordValue = _.get(record.value.sales_status, key);
  const revisionValue = _.get(props.revisionRecord.data, key);

  if (recordValue[field] !== revisionValue?.[field]) {
    hasDeviatingProvisionsDifference.value = true;
    return 'text-danger';
  }
}

function compareCertaintiesWithRevision(key, id) {
  if (!Object.keys(props.revisionRecord).length) {
    return;
  }

  let recordValue = _.get(record.value.data.sales_status, key);
  let revisionValue = _.get(props.revisionRecord.data, key);

  recordValue = Object.entries(recordValue).find((object) => {
    return object[1].certainty_rule_id === id;
  });
  revisionValue = Object.entries(revisionValue).find((object) => object[1].certainty_rule_id === id);

  if (
    recordValue[1]['title_certainty_parsed'] !== revisionValue?.[1]['title_certainty_parsed'] ||
    !!recordValue[1]['selected'] !== !!revisionValue?.[1]['selected']
  ) {
    hasCertaintiesDifference.value = true;
    return 'text-danger';
  }
}
function compareDebtorAnalysesWithRevision(key, field, id) {
  if (!Object.keys(props.revisionRecord).length) {
    return;
  }

  const recordValue = _.get(record.value.sales_status, key);
  const revisionValue = _.get(props.revisionRecord.data, key);

  const cssClass = compareKeyWithRevisionById(recordValue, revisionValue, field, id);

  if (cssClass) {
    hasDebtorAnalysesDifference.value = true;
  }

  return cssClass;
}
function compareCreditorAnalysesWithRevision(key, field, id) {
  if (!Object.keys(props.revisionRecord).length) {
    return;
  }

  const recordValue = _.get(record.value.sales_status, key);
  const revisionValue = _.get(props.revisionRecord.data, key);

  const cssClass = compareKeyWithRevisionById(recordValue, revisionValue, field, id);

  if (cssClass) {
    hasCreditorAnalysesDifference.value = true;
  }

  return cssClass;
}
function compareFinancialFacilitiesWithRevision(key) {
  const cssClass = compareKeyWithRevision(key);

  if (cssClass) {
    hasFinancialFacilitiesDifference.value = true;
  }

  return cssClass;
}
function compareAnnualFiguresWithRevision(key, field, id) {
  if (!Object.keys(props.revisionRecord).length) {
    return;
  }

  const recordValue = _.get(record.value.sales_status, key);
  const revisionValue = _.get(props.revisionRecord.data, key);

  const cssClass = compareKeyWithRevisionById(recordValue, revisionValue, field, id);

  if (cssClass) {
    hasAnnualFiguresDifference.value = true;
  }

  return cssClass;
}
function compareDocumentFilesWithRevision(key, id) {
  if (!Object.keys(props.revisionRecord).length) {
    return true;
  }

  const isEqual = compareArrayKeyWithRevision(key, id);

  if (!isEqual) {
    hasDocumentFilesDifference.value = true;
  }

  return isEqual;
}

function compareKeyWithRevisionCheckboxValue(key) {
  if (!Object.keys(props.revisionRecord).length) {
    return;
  }

  const recordValue = !!_.get(record.value.sales_status, key);
  let revisionValue = !!_.get(props.revisionRecord.data, key);

  if (recordValue !== revisionValue) {
    return true;
  }

  return false;
}

function openFile(url) {
  url = axios.defaults.baseURL + url;
  window.open(url, '_blank', 'noopener');
}

function toggleSendToCommissionModal() {
  showSendToCommissionModal.value = !showSendToCommissionModal.value;
}

async function handleSendToCommission() {
  try {
    loadingSendToCommission.value = true;

    const users = evaluationData.value.users.filter((userId) => userId !== authorizationStore.user.id);
    let evaluations = users.map((userId) => {
      return {
        customer_credit_proposal_id: record.value.data.id || null,
        user_id: userId,
        owner: false,
        status: null,
      };
    });

    const currentUserEvaluation = {
      customer_credit_proposal_id: record.value.data.id || null,
      user_id: authorizationStore.user.id,
      status: APPROVED,
      owner: true,
    };

    const revisionData = structuredClone(
      useSetAndMergeSalesStatusObject(
        useAmountSuffixConvertCentsToAmount({ sales_status: structuredClone(record.value.sales_status) })
      )
    );

    let response;
    evaluations = [...evaluations, currentUserEvaluation];
    try {
      response = await apiClient.request(
        'post',
        `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/assessment_committee_evaluation`,
        null,
        {
          evaluations: evaluations,
          revision_data: JSON.stringify(revisionData),
          email_description: evaluationData.value.email_description,
        }
      );

      record.value.data.sales_status.credit_proposal = {
        id: response.credit_proposal.id,
        evaluations: response.data,
      };
    } catch (error) {
      useSetToast(
        'error',
        t('toast.error.creating_credit_proposal_evaluations') + ':' + '<br>' + error?.response?.data?.message
      );
      console.error('Error while creating credit proposal assessment committee evaluations: ', error);
      return;
    }

    useSetToast('success', t('toast.success.credit_proposal_evaluation_successfully_requested'));
    emit('save', { customerLogs: response.sales_status.customer_log });

    toggleSendToCommissionModal();

    useSetProspectCreditProposalStatus({
      id: response.data?.[0]?.customer_credit_proposal_id,
      status: EVALUATION,
      owner: useIsOwner(response.data),
    });
  } catch (error) {
    useSetToast(
      'error',
      t('toast.error.sending_credit_proposal_to_commission') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while creating evaluations: ', error);
  } finally {
    loadingSendToCommission.value = false;
  }
}
</script>
