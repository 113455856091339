import TabsView from '@/views/Router/TabsView.vue';
import DebtorAnalysesView from '@/views/Sales/SalesStatus/DebtorCreditorAnalyses/DebtorAnalysesView.vue';
import CreditorAnalysesView from '@/views/Sales/SalesStatus/DebtorCreditorAnalyses/CreditorAnalysesView.vue';

export default {
  path: 'debtor-creditor-analyses',
  name: 'debtor and creditor analyses',
  component: TabsView,
  redirect: { name: 'debtor analyses' },
  meta: {
    menutype: 'tabs',
  },
  children: [
    {
      path: 'debtors',
      name: 'debtor analyses',
      component: DebtorAnalysesView,
      meta: {
        title: 'debtor_analyses',
        menutype: 'tab',
      },
    },
    {
      path: 'creditors',
      name: 'creditor analyses',
      component: CreditorAnalysesView,
      meta: {
        title: 'creditor_analyses',
        menutype: 'tab',
      },
    },
  ],
};
