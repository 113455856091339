<template>
  <LabelAmountInput
    v-if="props.label"
    v-model="computedModelValue"
    :label="props.label"
    :post-label="props.postLabel"
    :inner-label="props.innerLabel"
    :tooltip-content="props.tooltipContent"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    :wrapper-class="props.wrapperClass"
    :errors="props.errors"
    @paste="onPaste"
    @click="onClick"
    @keydown="onKeyDown"
    @keyup="onKeyUp"
    @focus="onFocus"
    @blur="onBlur"
    @keydown.enter="onKeyDownEnter"
  />
  <BaseAmountInput
    v-else
    v-model="computedModelValue"
    :errors="props.errors"
    :show-error-messages="props.showErrorMessages"
    :inner-label="props.innerLabel"
    @paste="onPaste"
    @click="onClick"
    @keydown="onKeyDown"
    @keyup="onKeyUp"
    @focus="onFocus"
    @blur="onBlur"
    @keydown.enter="onKeyDownEnter"
  />
</template>

<script setup>
import LabelAmountInput from '@/components/form/LabelAmountInput.vue';
import BaseAmountInput from '@/components/form/BaseAmountInput.vue';
import { useOnClick, useOnFocus, useOnKeyDown, useOnKeyUp, useOnPaste } from '@/composables/UseValidateNumberInput';
import { formatNumber } from '@/modules/formatters';
import { computed, ref } from 'vue';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  label: {
    type: String,
    default: null,
  },
  innerLabel: {
    type: String,
    default: 'EUR',
  },
  postLabel: {
    type: String,
    default: null,
  },
  tooltipContent: {
    type: String,
    default: '',
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  wrapperClass: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
  showErrorMessages: {
    type: Boolean,
    default: true,
  },
  decimals: {
    type: Number,
    default: 2,
  },
});

const emit = defineEmits(['update:modelValue', 'enter']);
const hasFocus = ref(false);

const formatOptions = {
  style: 'decimal',
  minimumFractionDigits: props.decimals,
};

const computedModelValue = computed({
  get() {
    if (props.modelValue) {
      if (!hasFocus.value) {
        return formatNumber(props.modelValue.replace('.', ','), formatOptions);
      }
      return props.modelValue.replace('.', ',');
    }

    return props.modelValue;
  },
  set(value) {
    if (!value) {
      emit('update:modelValue', '');
      return;
    }

    if (!hasFocus.value) {
      value = formatNumber(value.replace('.', ','), formatOptions).replaceAll('.', '').replace(',', '.');
    } else {
      value = value.replaceAll('.', '').replace(',', '.');
    }

    emit('update:modelValue', value);
  },
});

function onBlur(event) {
  hasFocus.value = false;
  computedModelValue.value = event.target.value;
}
function onFocus(event) {
  useOnFocus(event);
  hasFocus.value = true;
}

function onPaste(event) {
  let validatedText = useOnPaste(event, props.decimals);
  if (validatedText) {
    computedModelValue.value = validatedText;
  }
}
function onClick(event) {
  useOnClick(event, props.decimals);
}
function onKeyUp(event) {
  useOnKeyUp(event, props.decimals);
}
function onKeyDown(event) {
  computedModelValue.value = useOnKeyDown(event, props.decimals);
}
function onKeyDownEnter(event) {
  hasFocus.value = false;
  computedModelValue.value = event.target.value;
  hasFocus.value = true;
  emit('enter');
}
</script>
