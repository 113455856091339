<template>
  <div class="file-tag cursor-pointer">
    <div
      class="d-flex align-items-center position-relative wrapper"
      :class="{ 'tag-error': props.error }"
    >
      <BaseIcon :icon="`far ${getFileIcon(props.fileName)}`" />
      <span class="align-items-center ms-2 tag-title">
        <span class="text-truncate">
          {{ getFileNameWithoutExtension(props.fileName) }}
        </span>
        <span v-if="props.fileName.includes('.')">.{{ getExtension(props.fileName) }}</span>
      </span>
      <span
        v-if="props.allowRemove"
        class="position-absolute cursor-pointer close"
        @click.prevent.stop="removeFile(props.fileKey)"
      >
        <BaseIcon icon="fa-solid fa-xmark icon-remove" />
      </span>
    </div>
  </div>
</template>

<script setup>
import BaseIcon from '@/components/general/BaseIcon.vue';
import getClassNameForExtension from 'font-awesome-filetypes';

const props = defineProps({
  fileKey: {
    type: Number,
    default: null,
  },
  fileName: {
    type: String,
    default: '',
  },
  error: {
    type: Boolean,
    default: false,
  },
  allowRemove: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['remove']);

function getFileIcon(fileName) {
  const extension = getExtension(fileName);
  return getClassNameForExtension(extension);
}
function getFileNameWithoutExtension(fileName) {
  const index = fileName.lastIndexOf('.');
  return fileName.substring(0, index);
}
function getExtension(fileName) {
  const index = fileName.lastIndexOf('.');
  return fileName.substring(index + 1, fileName.length);
}
function removeFile(key) {
  emit('remove', key);
}
</script>
