<template>
  <div
    :disabled="$attrs.disabled"
    :readonly="$attrs.readonly"
    :class="[groupClasses, { 'is-invalid': errors.length }]"
  >
    <span
      v-if="innerLabel && !innerLabelRight"
      :class="['input-group-text']"
    >
      {{ innerLabel }}
    </span>
    <BaseInput
      v-bind="$attrs"
      v-model="computedModelValue"
      :class="{ 'is-invalid': errors.length }"
      :type="type"
      @input="updateValue"
    />
    <span
      v-if="innerLabel && innerLabelRight"
      :class="['input-group-text']"
    >
      {{ innerLabel }}
    </span>
  </div>
  <div
    v-if="errors.length && props.showErrorMessages"
    class="form-text text-danger error-messages"
  >
    {{ errors.join('\r\n') }}
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import BaseInput from './BaseInput';
import useModelWrapper from '@/composables/UseComputedMethods';
import { computed, useAttrs } from 'vue';

const props = defineProps({
  innerLabel: {
    type: String,
    default: '',
  },
  innerLabelRight: {
    type: Boolean,
    default: false,
  },
  groupClass: {
    type: String,
    default: null,
  },
  notNarrow: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
  showErrorMessages: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue']);
const attrs = useAttrs();
const computedModelValue = useModelWrapper(props, emit, 'modelValue');

const type = computed(() => {
  return attrs.type ?? 'text';
});

const groupClasses = computed(() => {
  let innerLabelClass = '';
  if (props.innerLabel) {
    innerLabelClass = props.innerLabelRight ? 'input-group-text-right' : 'input-group-text-left';
  }

  return [
    'input-group',
    props.groupClass ?? '',
    innerLabelClass ?? '',
    {
      'input-group--narrow': !props.notNarrow,
    },
  ];
});

function updateValue(event) {
  emit('update:modelValue', event.target.value);
}
</script>
