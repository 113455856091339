<template>
  <FormControlWrapper
    v-bind="formControlProps"
    :for="id"
    :align-label-center="false"
  >
    <BaseTextarea
      v-bind="$attrs"
      :id="id"
      v-model="computedModelValue"
      :errors="errors"
    />
    <template #extra>
      <slot name="extra" />
    </template>
  </FormControlWrapper>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import FormControlWrapper from './FormControlWrapper';
import BaseTextarea from './BaseTextarea';
import { nanoid } from 'nanoid';
import { computed, onBeforeMount, reactive, ref, useAttrs } from 'vue';

const props = defineProps({
  labelContent: {
    type: String,
    required: true,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  extraColumns: {
    type: String,
    default: null,
  },
  wrapperClass: {
    type: [String, Array, Object],
    default: null,
  },
  modelValue: {
    type: String,
    default: null,
  },
  notInline: {
    type: Boolean,
    default: false,
  },
  notNarrow: {
    type: Boolean,
    default: false,
  },
  tooltipContent: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['update:modelValue']);

const id = ref(null);
const attrs = useAttrs();
const formControlProps = reactive({
  wrapperClass: props.wrapperClass,
  labelContent: props.labelContent,
  labelColumns: props.labelColumns,
  inputColumns: props.inputColumns,
  extraColumns: props.extraColumns,
  notInline: props.notInline,
  notNarrow: props.notNarrow,
  tooltipContent: props.tooltipContent,
});

const computedModelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (!value) {
      emit('update:modelValue', null);
      return;
    }
    emit('update:modelValue', value);
  },
});

onBeforeMount(() => {
  id.value = attrs.id ?? nanoid();
});
</script>
