<template>
  <LabelInput
    v-model="record.name"
    :errors="errorMessages['name']"
    :label-content="$t('general.name')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    @enter="handleSave"
  />
  <LabelInput
    v-model="record.company_registration_number"
    :errors="errorMessages['company_registration_number']"
    :label-content="$t('general.company_registration_number')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    @enter="handleSave"
  />
  <LabelInput
    v-model="record.registered_office"
    :errors="errorMessages['registered_office']"
    :label-content="$t('address.registered_office')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    @enter="handleSave"
  />
  <LabelInput
    v-model="record.address.address"
    :errors="errorMessages['company_address_address']"
    :label-content="$t('address.address_information')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    @enter="handleSave"
  />
  <LabelInput
    v-model="record.address.postal_code"
    :errors="errorMessages['company_address_postal_code']"
    :label-content="$t('address.postal_code')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    @enter="handleSave"
  />
  <LabelInput
    v-model="record.address.city"
    :errors="errorMessages['company_address_city']"
    :label-content="$t('address.city')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    @enter="handleSave"
  />
  <BaseRow>
    <BaseColumn class="col-8">
      <FormFooter
        :pending="loading"
        class="footer-secondary justify-content-end mt-4 pt-1"
        :disabled="_.isEqual(record, originalRecord) || loading"
        @save="handleSave"
        @cancel="handleCancel"
      >
      </FormFooter>
    </BaseColumn>
  </BaseRow>
</template>

<script setup>
import LabelInput from '@/components/form/LabelInput.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import apiClient from '@/services/ApiClient';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { useSetToast } from '@/composables/UseToast';
import { useSetOriginalProspectRecord, useSetProspectRecord } from '@/composables/UseIsDirty';
import { CONTRACT } from '@/configs/constants/CompanyTypes';
import { BaseColumn, BaseRow } from '@impact-factoring/impact-branding';
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({
      id: null,
      name: null,
      type: CONTRACT,
      company_registration_number: null,
      registered_office: null,
      address: {
        address: null,
        postal_code: null,
        city: null,
      },
    }),
  },
  initialValidationRules: {
    type: Object,
    default: () => ({}),
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
});

const { t } = useI18n();
const emit = defineEmits(['save', 'update', 'beforeMount']);
const loading = ref(false);
const formId = nanoid();
const errorMessages = ref([]);
const record = ref(props.initialRecord);
const originalRecord = ref({});
const router = useRouter();
let validationRules = [];

onBeforeMount(async () => {
  emit('beforeMount', formId);

  record.value = useSetProspectRecord(record.value, formId);
  originalRecord.value = useSetOriginalProspectRecord(record.value, formId);

  if (record.value.id) {
    validationRules = useMergeDefaultVuelidateValidationRules(props.initialValidationRules, record.value);
    errorMessages.value = await useVuelidateValidation(validationRules, record.value);
  }
});

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  validationRules = useMergeDefaultVuelidateValidationRules(props.initialValidationRules, record.value);
  errorMessages.value = await useVuelidateValidation(props.initialValidationRules, record.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  try {
    loading.value = true;

    let recordCopy = structuredClone(record.value);
    let savedCompany;
    let response;

    if (!record.value.id) {
      response = await saveCompany(recordCopy);
      useSetToast('success', t('toast.success.company_successfully_created'));
      emit('save', { customerLogs: response.sales_status.customer_log, company: response.data });
      savedCompany = response[response.length - 1];
    } else {
      response = await updateCompany(recordCopy);
      useSetToast('success', t('toast.success.company_successfully_updated'));
      emit('update', { customerLogs: response.sales_status.customer_log, company: response.data });
      savedCompany = response.data;
    }

    record.value = useSetProspectRecord(savedCompany, formId);
    originalRecord.value = useSetOriginalProspectRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };

    if (!record.value.id) {
      useSetToast('error', t('toast.error.creating_company') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while creating company: ', error);
    } else {
      useSetToast('error', t('toast.error.updating_company') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while updating company: ', error);
    }
  } finally {
    loading.value = false;
  }
}

function handleCancel() {
  record.value = useSetProspectRecord(originalRecord.value, formId);
  errorMessages.value = [];
}

function saveCompany(data) {
  return apiClient.request(
    'post',
    `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/company`,
    null,
    data
  );
}

function updateCompany(data) {
  return apiClient.request(
    'put',
    `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/company/${record.value.id}`,
    null,
    data
  );
}
</script>
