import i18n from '@/lang/i18n-config';

const { t } = i18n.global;

const INTERMEDIAIR = t('constants.lead_channels.intermediair');
const WORTH_OF_MOUTH_ADVERTISING = t('constants.lead_channels.worth_of_mouth_advertising');
const OUR_WEBSITE = t('constants.lead_channels.our_website');
const INTERNET_SEARCH_ENGINE = t('constants.lead_channels.internet_search_engine');
const FACEBOOK = t('constants.lead_channels.facebook');
const INSTAGRAM = t('constants.lead_channels.instagram');
const BY_CUSTOMER = t('constants.lead_channels.by_customer');

export {
  INTERMEDIAIR,
  WORTH_OF_MOUTH_ADVERTISING,
  OUR_WEBSITE,
  INTERNET_SEARCH_ENGINE,
  FACEBOOK,
  INSTAGRAM,
  BY_CUSTOMER,
};
export default {
  INTERMEDIAIR,
  WORTH_OF_MOUTH_ADVERTISING,
  OUR_WEBSITE,
  INTERNET_SEARCH_ENGINE,
  FACEBOOK,
  INSTAGRAM,
  BY_CUSTOMER,
};
