<template>
  <FormControlWrapper
    v-bind="props"
    :for="id"
  >
    <InputGroup
      v-bind="attrs"
      :id="id"
      :inner-label="props.innerLabel"
      :inner-label-right="props.innerLabelRight"
      :group-class="props.groupClass"
      :errors="props.errors"
      :not-narrow="props.notNarrow"
      :model-value="props.modelValue"
      @input="updateValue"
      @wheel.prevent
    />
    <slot name="extra" />
  </FormControlWrapper>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import FormControlWrapper from './FormControlWrapper.vue';
import InputGroup from './InputGroup.vue';
import { nanoid } from 'nanoid';
import { ref, onMounted, useAttrs } from 'vue';

const props = defineProps({
  labelContent: {
    type: String,
    required: true,
  },
  innerLabel: {
    type: String,
    default: '',
  },
  innerLabelRight: {
    type: Boolean,
    default: false,
  },
  groupClass: {
    type: String,
    default: null,
  },
  wrapperClass: {
    type: String,
    default: null,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  extraColumns: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  notInline: {
    type: Boolean,
    default: false,
  },
  notNarrow: {
    type: Boolean,
    default: false,
  },
  tooltipContent: {
    type: String,
    default: '',
  },
  postLabel: {
    type: String,
    default: '',
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const attrs = useAttrs();
const id = ref(null);

onMounted(() => {
  id.value = attrs.id ?? nanoid();
});

function updateValue(event) {
  emit('update:modelValue', event.target.value);
}
</script>
