<template>
  <div class="pagination">
    <button
      :disabled="!pagination.prev_page_url"
      class="pagination-button"
      @click="changePage(pagination.prev_page_url)"
    >
      <i class="fa-solid fa-chevron-left"></i>
    </button>
    <span
      v-for="link in pagination.links"
      :key="link.label"
    >
      <button
        v-if="link.url && !link.label.includes('&')"
        class="pagination-button"
        :class="{ active: link.active }"
        @click="changePage(link.url)"
      >
        {{ link.label }}
      </button>
      <button
        v-if="link.label.includes('...')"
        class="pagination-inbetween-button"
      >
        {{ link.label }}
      </button>
    </span>
    <button
      :disabled="!pagination.next_page_url"
      class="pagination-button"
      @click="changePage(pagination.next_page_url)"
    >
      <i class="fa-solid fa-chevron-right"></i>
    </button>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  pagination: {
    type: Object,
    required: true,
  },
});

const { pagination } = toRefs(props);
const emit = defineEmits(['pageChange']);

function changePage(url) {
  if (url) {
    const page = new URL(url).searchParams.get('page');
    emit('pageChange', page);
  }
}
</script>
