<template>
  <BaseButton button-style="secondary">
    <slot />
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton';

export default {
  name: 'SecondaryButton',
  components: {
    BaseButton,
  },
};
</script>
