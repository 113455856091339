import i18n from '@/lang/i18n-config';

const { t } = i18n.global;

const DIRECTOR = 'DIRECTOR';
const DIRECTOR_SHAREHOLDER = 'DIRECTOR_SHAREHOLDER';
const SHAREHOLDER = 'SHAREHOLDER';
const ADMINISTRATION = 'ADMINISTRATION';

const TRANSLATED_JOB_TITLES = {
  [DIRECTOR]: t('constants.job_titles.director'),
  [DIRECTOR_SHAREHOLDER]: t('constants.job_titles.director_and_shareholder'),
  [SHAREHOLDER]: t('constants.job_titles.shareholder'),
  [ADMINISTRATION]: t('constants.job_titles.administration'),
};

export { TRANSLATED_JOB_TITLES, DIRECTOR, DIRECTOR_SHAREHOLDER, SHAREHOLDER, ADMINISTRATION };
export default { TRANSLATED_JOB_TITLES, DIRECTOR, DIRECTOR_SHAREHOLDER, SHAREHOLDER, ADMINISTRATION };
