<template>
  <label class="switch">
    <input
      :id="labelId"
      v-model="computedModelValue"
      type="checkbox"
    />
    <span class="slider round" />
  </label>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: [Boolean, Number],
    default: false,
  },
  labelId: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const computedModelValue = computed({
  get() {
    return !!props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>
