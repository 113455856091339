import { computed } from 'vue';

// copied from https://vanoneang.github.io/article/v-model-in-vue3.html#turn-it-into-a-composable
export default function useModelWrapper(props, emit, name = 'modelValue') {
  return computed({
    get: () => {
      return props[name];
    },
    set: (value) => {
      emit(`update:${name}`, value);
    },
  });
}
