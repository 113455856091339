<template>
  <LabelNumberInput
    v-if="props.labelContent"
    v-bind="props"
    v-model="computedModelValue"
    @keydown.enter="onKeyDownEnter"
  />
  <BaseNumberInput
    v-else
    v-bind="props"
    v-model="computedModelValue"
    @keydown.enter="onKeyDownEnter"
  />
</template>

<script setup>
import BaseNumberInput from '@/components/form/BaseNumberInput.vue';
import LabelNumberInput from '@/components/form/LabelNumberInput.vue';
import { computed } from 'vue';

const props = defineProps({
  labelContent: {
    type: String,
    default: null,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  extraColumns: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  notInline: {
    type: Boolean,
    default: false,
  },
  notNarrow: {
    type: Boolean,
    default: false,
  },
  description: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
  decimals: {
    type: Number,
    default: 0,
  },
  textPosition: {
    type: String,
    default: 'end',
  },
});

const emit = defineEmits(['update:modelValue', 'enter']);

const computedModelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (!value) {
      emit('update:modelValue', null);
      return;
    }
    emit('update:modelValue', parseInt(value));
  },
});

function onKeyDownEnter() {
  emit('enter');
}
</script>
