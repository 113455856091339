<template>
  <li
    class="nav-item"
    role="presentation"
  >
    <RouterLink
      v-bind="$attrs"
      class="nav-link"
      :class="{ disabled: disabled }"
      active-class="active"
      type="button"
      role="tab"
      :to="{ name: route }"
    >
      <slot />
    </RouterLink>
  </li>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
defineProps({
  route: {
    required: true,
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
</script>
