<template>
  <InputGroup
    v-bind="$attrs"
    :id="id"
    :inner-label="innerLabel"
    :inner-label-right="innerLabelRight"
    :group-class="groupClass"
    :errors="errors"
    :not-narrow="notNarrow"
    :model-value="modelValue"
    @input="updateValue"
    @wheel.prevent
  />
</template>

<script>
import InputGroup from '@/components/form/InputGroup.vue';
import { nanoid } from 'nanoid';

export default {
  name: 'NumberInputGroup',
  components: {
    InputGroup,
  },
  inheritAttrs: false,
  props: {
    innerLabel: {
      type: String,
      default: '',
    },
    innerLabelRight: {
      type: Boolean,
      default: false,
    },
    groupClass: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    notNarrow: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this.$attrs.id ?? nanoid();
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>
