import i18n from '@/lang/i18n-config';
import router from '@/router';
import { ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import _ from 'lodash';

const { t } = i18n.global;
const originalRecords = ref({});
const originalProspectRecords = ref({});
const records = ref({});
const prospectRecords = ref({});

const confirm = ref({
  show: false,
  title: t('ui.confirm'),
  message: t('modal.warning_text_unsaved_changes_when_leaving_page'),
});
let routerTo = null;

export function useSetShowConfirm(boolean) {
  confirm.value.show = boolean;
  return boolean;
}
export function useSetProspectRecord(record, formId) {
  prospectRecords.value[formId] = structuredClone(record);
  return prospectRecords.value[formId];
}
export function useSetOriginalProspectRecord(record, formId) {
  originalProspectRecords.value[formId] = structuredClone(record);
  return originalProspectRecords.value[formId];
}
export function useSetRecord(record, formId) {
  records.value[formId] = structuredClone(record);
  return records.value[formId];
}
export function useSetOriginalRecord(record, formId) {
  originalRecords.value[formId] = structuredClone(record);
  return originalRecords.value[formId];
}

export function useGetConfirm() {
  return confirm;
}
export function useGetProspectRecord(formId) {
  return prospectRecords.value[formId];
}
export function useGetOriginalProspectRecord(formId) {
  return originalProspectRecords.value[formId];
}
export function useGetOriginalRecord(formId) {
  return originalRecords.value[formId];
}

export function useRemoveProspectFormId(formId) {
  delete originalProspectRecords.value[formId];
  delete prospectRecords.value[formId];
}
export function useRemoveFormId(formId) {
  delete originalRecords.value[formId];
  delete records.value[formId];
}
export function useRemoveAllFormIds() {
  originalRecords.value = {};
  records.value = {};
}

export function useConfirmLeavingPage() {
  useRemoveAllFormIds();
  router.push(routerTo);
}
export function useBeforeRouteLeave() {
  onBeforeRouteLeave((to, from, next) => {
    let isEqual = true;
    Object.keys(records.value).forEach((key) => {
      if (!_.isEqual(records.value[key], originalRecords.value[key])) {
        isEqual = false;
      }
    });

    if (isEqual) {
      useRemoveAllFormIds();
      next();
      return;
    }

    routerTo = to;
    confirm.value.show = true;
  });
}
