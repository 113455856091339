<template>
  <div>
    <BaseModal
      v-if="record"
      :show="showModal"
      :modal-title="$t('modal.creditsafe_retrieve_company')"
      min-height="680px"
      modal-dialog-classes="creditsafe-debtor-modal"
      custom-footer
      @close="closeModal"
    >
      <MultiselectSearchInput
        v-model="record.countries"
        :entities="countriesKeyValue"
        :label-content="$t('address.country')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        :tooltip-content="$t('tooltip.creditsafe_select_all_countries')"
        :errors="errorMessages['record.countries']"
      />
      <LabelInput
        v-model="record.regNo"
        :errors="errorMessages['company_registration_number']"
        :label-content="$t('general.company_registration_number')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        :disabled="!!record.vatNo"
      />
      <LabelInput
        v-model="record.vatNo"
        :label-content="$t('general.vat_number')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        :disabled="!!record.regNo"
      />
      <div
        v-if="errorMessages['record.all']"
        class="form-text text-danger error-messages mt-4"
      >
        {{ errorMessages['record.all'][0] }}
      </div>
      <BaseRow>
        <BaseColumn class="col-8">
          <div
            :class="companies.length <= 1 ? 'flex-grow-1' : 'mb-4'"
            class="d-flex footer-secondary justify-content-end mt-4"
          >
            <PrimaryButton
              :loading="loadingCompanies"
              :disabled="loadingCompanies"
              @click="handleFetchCreditsafeCompanies"
            >
              {{ $t('ui.button_search') }}
            </PrimaryButton>
          </div>
        </BaseColumn>
      </BaseRow>
      <template #modalFooter>
        <PrimaryButton
          v-if="showNextButton"
          class="ms-auto"
          @click="goForward"
        >
          {{ $t('ui.next') }}
        </PrimaryButton>
      </template>
      <CreditsafeSearchCompaniesResults
        v-if="companies.length > 1"
        :record="companies"
        :loading="loadingCompany"
        @submit="fetchCreditsafeCompany"
      />
    </BaseModal>
  </div>
</template>

<script setup>
import MultiselectSearchInput from '@/components/form-controls/Multiselect/MultiselectSearchInput.vue';
import LabelInput from '@/components/form/LabelInput.vue';
import { useEnumerationsStore } from '@/stores/enumerations';
import BaseModal from '@/components/BaseModal.vue';
import apiClient from '@/services/ApiClient';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import { useSetToast } from '@/composables/UseToast';
import CreditsafeSearchCompaniesResults from '@/forms/sales/sales-status/creditsafe/CreditsafeSearchCompaniesResults.vue';
import { BaseColumn, BaseRow } from '@impact-factoring/impact-branding';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  initialCompanies: {
    type: Array,
    default: () => [],
  },
  showNextButton: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['loadCompany', 'loadCompanies', 'close', 'next']);

const record = ref({
  countries: [],
  regNo: null,
  vatNo: null,
});

const { t } = useI18n();
const enums = useEnumerationsStore();

const companies = ref(props.initialCompanies);
const errorMessages = ref([]);
const showModal = ref(true);
const loadingCompanies = ref(false);
const loadingCompany = ref(false);

const labelColumnClasses = 'col-md-3 col-12';
const inputColumnClasses = 'col-md-5 col-12';

const regNoOrVatNo = computed(() => {
  return !!(record.value.regNo || record.value.vatNo);
});

const oldSearchValues = computed(() => {
  return props.initialRecord?.countries?.length || props.initialRecord?.regNo || props.initialRecord?.vatNo;
});

const countriesKeyValue = computed(() => {
  if (!enums.data.countries) {
    return [];
  }
  let countries = mapCountries();
  countries = {
    PLC: t('general.all_countries'),
    ...countries,
  };
  return countries;
});

onBeforeMount(() => {
  record.value.countries = record.value.countries.map((country) => {
    return country.id;
  });

  const countryId = props.initialRecord?.country_id;
  let selectedCountry;
  if (countryId) {
    const completeCountryObject = enums.data.countries.find((country) => country.id === parseInt(countryId));
    selectedCountry = { id: completeCountryObject.id, name: completeCountryObject.translation };
  }

  if (countryId || props.initialRecord?.company_registration_number) {
    record.value = {
      countries: countryId ? [selectedCountry] : [],
      regNo: props.initialRecord?.company_registration_number || '',
      vatNo: '',
    };
  }

  if (oldSearchValues.value) {
    record.value = props.initialRecord;
  }
});

function closeModal() {
  showModal.value = false;
  emit('close', props.initialRecord);
}

function mapCountries() {
  const countries = {};
  enums.data.countries.map((country) => {
    countries[country.id] = country.translation;
  });
  return countries;
}

async function handleFetchCreditsafeCompanies() {
  if (!validation()) {
    return;
  }

  const urlParameters = generateUrlParameters();
  try {
    loadingCompanies.value = true;
    const creditSafeCompanies = await getSearchedCreditsafeCompany(urlParameters);
    if (!creditSafeCompanies[0]) {
      loadingCompanies.value = false;
      return;
    }
    companies.value = creditSafeCompanies;
    if (creditSafeCompanies.length > 1) {
      emit('loadCompanies', creditSafeCompanies);
      loadingCompanies.value = false;
      return;
    } else {
      emit('loadCompanies', []);
    }
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.retrieving_creditsafe_companies') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching creditsafe companies: ', error);
    loadingCompanies.value = false;
    return;
  }
  await fetchCreditsafeCompany(companies.value[0]);
}

async function fetchCreditsafeCompany(creditsafeCompany) {
  loadingCompany.value = true;
  try {
    const creditSafeCompany = await getCreditsafeCompany(creditsafeCompany);
    if (!creditSafeCompany) {
      loadingCompany.value = false;
      return;
    }
    emit('loadCompany', { company: creditSafeCompany, searchModalValues: record.value });
    loadingCompany.value = false;
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.retrieving_creditsafe_company') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching creditsafe company: ', error);
    loadingCompany.value = false;
  }
}

function goForward() {
  emit('next');
}

function getSearchedCreditsafeCompany(urlParameters) {
  return apiClient.request('get', '/ifapi/integrations/creditsafe/companies', {
    requestSource: urlParameters,
  });
}

function getCreditsafeCompany(creditsafeCompany) {
  return apiClient.request('get', `/ifapi/integrations/creditsafe/companies/${creditsafeCompany.id}`, {
    requestSource: getUrlParameters(creditsafeCompany),
  });
}

function getUrlParameters(company) {
  const language = company.country === 'NL' ? 'NL' : 'EN';
  return `?language=${language}`;
}

function validation() {
  if (!record.value.countries.length) {
    errorMessages.value['record.countries'] = [t('validation.value_is_required')];
  } else {
    delete errorMessages.value['record.countries'];
  }

  if (!regNoOrVatNo.value) {
    errorMessages.value['record.all'] = [t('validation.kvk_or_btw_is_required')];
  } else {
    delete errorMessages.value['record.all'];
  }
  if (!record.value.countries.length || !regNoOrVatNo.value) {
    return false;
  }
  return true;
}

function generateUrlParameters() {
  let filteredParameters = filterFilledFields();
  const urlParameters = createUrlFromParameters(filteredParameters);
  return urlParameters;
}

function filterFilledFields() {
  return Object.entries(record.value).filter((field) => {
    return field[1]?.length;
  });
}

function createUrlFromParameters(parameters) {
  let urlParameters = '';
  parameters.forEach((parameter, index) => {
    if (index !== 0) {
      urlParameters += '&';
    }
    if (parameter[0] === 'countries') {
      urlParameters += createCountriesUrl(parameter);
      return;
    }
    if (parameter[0] === 'vatNo' && parameter[1].startsWith('NL')) {
      urlParameters += parameter[0] + '=' + stripNLPrefixFromVatNo(parameter[1]);
      return;
    }

    urlParameters += parameter[0] + '=' + parameter[1];
  });
  return '?' + urlParameters;
}

function createCountriesUrl(countries) {
  let enumCountry;
  let parameterCountryUrl = countries[0] + '=';

  countries[1].forEach((country, index) => {
    enumCountry = enums.data.countries.find((enumCountry) => {
      return enumCountry.id === country.id;
    });

    if (country.id === 'PLC') {
      enumCountry = { iso: country.id };
    }

    parameterCountryUrl += enumCountry.iso;

    if (index !== countries[1].length - 1) {
      parameterCountryUrl += ',';
    }
  });
  return parameterCountryUrl;
}

function stripNLPrefixFromVatNo(string) {
  return string.replace('NL', '');
}
</script>
