<template>
  <ul
    class="nav nav-tabs px-5 mx-4"
    role="tablist"
  >
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'TabList',
};
</script>
