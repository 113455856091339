<template>
  <FormControlWrapper
    :for="labelId"
    :label-content="props.label"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    :wrapper-class="props.wrapperClass"
    :tooltip-content="props.tooltipContent"
  >
    <BaseAmountInput
      v-bind="$attrs"
      :id="labelId"
      v-model="computedModelValue"
      :inner-label="props.innerLabel"
      :errors="props.errors"
    />
    <template #extra>
      <div
        v-if="props.postLabel"
        class="form-text text-body"
      >
        {{ props.postLabel }}
      </div>
      <slot
        v-if="$slots.extra"
        name="extra"
      />
    </template>
  </FormControlWrapper>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import FormControlWrapper from '@/components/form/FormControlWrapper.vue';
import BaseAmountInput from '@/components/form/BaseAmountInput.vue';
import useModelWrapper from '@/composables/UseComputedMethods';
import { nanoid } from 'nanoid';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  postLabel: {
    type: String,
    default: '',
  },
  innerLabel: {
    type: String,
    default: 'EUR',
  },
  tooltipContent: {
    type: String,
    default: '',
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  wrapperClass: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['update:modelValue']);

const computedModelValue = useModelWrapper(props, emit, 'modelValue');
const labelId = nanoid();
</script>
