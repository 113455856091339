<template>
  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <DocumentForm
    v-else-if="record"
    :initial-record="record"
    @save="updateLogbook"
  />
</template>

<script setup>
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import DocumentForm from '@/forms/sales/sales-status/document/DocumentForm.vue';
import { useSetLogbookItems } from '@/composables/UseProspectViewData';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();
const record = ref(null);
const loading = ref(false);

onBeforeMount(async () => {
  try {
    loading.value = true;
    record.value = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/documents`
    );

    if (!record.value?.data) {
      record.value['data'] = {};
      record.value.data['files'] = [];
    }
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_documents') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching documents: ', error);
  } finally {
    loading.value = false;
  }
});

function updateLogbook(object) {
  useSetLogbookItems(object.customerLogs);
}
</script>
