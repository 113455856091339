import TabsView from '@/views/Router/TabsView';
import CreditProposalDetailsView from '@/views/Sales/SalesStatus/CreditProposal/CreditProposalDetailsView.vue';
import CreditProposalNotesView from '@/views/Sales/SalesStatus/CreditProposal/CreditProposalNotesView.vue';
import CreditProposalAssessmentCommitteeView from '@/views/Sales/SalesStatus/CreditProposal/CreditProposalAssessmentCommitteeView.vue';

export default {
  path: 'credit-proposal',
  name: 'credit proposal',
  component: TabsView,
  redirect: { name: 'credit proposal details' },
  meta: {
    preview: 'credit_proposal',
    menutype: 'tabs',
  },
  children: [
    {
      path: 'details',
      name: 'credit proposal details',
      component: CreditProposalDetailsView,
      meta: {
        title: 'details',
        menutype: 'tab',
      },
    },
    {
      path: 'evaluation-committee',
      name: 'credit proposal and assessment committee',
      component: CreditProposalAssessmentCommitteeView,
      meta: {
        title: 'assessment_committee',
        menutype: 'tab',
      },
    },
    {
      path: 'notes',
      name: 'credit proposal notes',
      component: CreditProposalNotesView,
      meta: {
        title: 'notes',
        menutype: 'tab',
      },
    },
  ],
};
