import MainView from '@/views/Router/MainView';
import IntermediariesListView from '@/views/Intermediaries/IntermediariesListView.vue';
import IntermediaryView from '@/views/Intermediaries/IntermediaryView.vue';

export default {
  path: '/intermediaries',
  component: MainView,
  redirect: { name: 'list intermediaries' },
  children: [
    {
      path: '',
      name: 'list intermediaries',
      component: IntermediariesListView,
    },
    {
      path: ':id',
      name: 'edit intermediaries',
      component: IntermediaryView,
    },
  ],
};
