import { useRoute } from 'vue-router';

class MenuService {
  filterTabsFromParentRoute(parentRoute) {
    return parentRoute.value.children.filter((route) => route.meta?.menutype === 'tab');
  }

  findInRoute(type, to = null) {
    const currentRoute = to ?? useRoute();
    return currentRoute.matched.find((route) => route.meta?.menutype === type);
  }
}

export default new MenuService();
