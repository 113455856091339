<template>
  <FormControlWrapper
    v-bind="props"
    :for="id"
  >
    <BaseNumberInput
      v-bind="attrs"
      :id="id"
      v-model="computedModelValue"
      :errors="props.errors"
      :not-narrow="props.notNarrow"
      :text-position="props.textPosition"
      @focus="useOnFocus"
      @paste="onPaste"
      @click="onClick"
      @keydown="onKeyDown"
      @keyup="onKeyUp"
      @blur="onBlur"
    />
    <template #extra>
      <slot name="extra" />
    </template>
  </FormControlWrapper>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import FormControlWrapper from './FormControlWrapper.vue';
import BaseNumberInput from '@/components/form/BaseNumberInput.vue';
import { useOnClick, useOnFocus, useOnKeyDown, useOnKeyUp, useOnPaste } from '@/composables/UseValidateNumberInput';
import useModelWrapper from '@/composables/UseComputedMethods';
import { nanoid } from 'nanoid';
import { ref, onMounted, useAttrs } from 'vue';

const props = defineProps({
  labelContent: {
    type: String,
    default: null,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  extraColumns: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  notInline: {
    type: Boolean,
    default: false,
  },
  notNarrow: {
    type: Boolean,
    default: false,
  },
  tooltipContent: {
    type: String,
    default: '',
  },
  postLabel: {
    type: String,
    default: '',
  },
  errors: {
    type: Array,
    default: () => [],
  },
  decimals: {
    type: Number,
    default: 0,
  },
  textPosition: {
    type: String,
    default: 'end',
  },
});

const emit = defineEmits(['update:modelValue']);
const computedModelValue = useModelWrapper(props, emit, 'modelValue');

const attrs = useAttrs();
const id = ref(null);
// const formatOptions = {
//   style: 'decimal',
//   maximumFractionDigits: props.decimals,
// };

onMounted(() => {
  id.value = attrs.id ?? nanoid();

  // computedModelValue.value = formatNumber(computedModelValue.value.toString(), formatOptions);
  // emit('update:modelValue', computedModelValue.value);
});

function onPaste(event) {
  let validatedText = useOnPaste(event, props.decimals);
  if (validatedText) {
    computedModelValue.value = validatedText;
  }
}
function onClick(event) {
  useOnClick(event, props.decimals);
}
function onKeyUp(event) {
  useOnKeyUp(event, props.decimals);
}
function onKeyDown(event) {
  computedModelValue.value = useOnKeyDown(event, props.decimals);
}
function onBlur(event) {
  // formatAmount(event);
}

// function formatAmount(event) {
//   computedModelValue.value = formatNumberOnBlur(event.target.value.toString(), formatOptions);
//   emit('update:modelValue', computedModelValue.value);
// }
</script>
