<template>
  <BaseModal
    v-if="showConfirmModal"
    :show="showConfirmModal"
    :modal-title="$t('ui.confirm')"
    size="sm"
    @confirm="handleSaveCompany"
    @close="closeConfirmModal"
  >
    <span>{{ t('modal.are_you_sure_you_want_to_save_data') }}</span>

    <template v-if="companyRecord?.type === PROSPECT">
      <br />
      <span
        v-safe-html="
          t('modal.warning_text_when_updating_prospect_company', {
            companyName: `<b>${companyRecord.name_plus_legal_form}</b>`,
            companyId: `<b>${companyRecord?.sales_status?.id}</b>`,
          })
        "
      />
    </template>

    <template v-if="!!getProspectOrganogramString()">
      <br />
      <span>{{ t('modal.warning_text_when_updating_prospect_organogram_company') }}</span>
      <span v-safe-html="getProspectOrganogramString()" />
    </template>
  </BaseModal>

  <BaseModal
    v-if="!showConfirmModal"
    :show="!showConfirmModal"
    :modal-title="$t('modal.add_company_or_person')"
    modal-dialog-classes="creditsafe-debtor-modal"
    custom-footer
    @close="closeModal"
  >
    <LabelToggleSwitch
      v-model="isPerson"
      :label-content="$t('modal.add_person')"
    />
    <template v-if="isPerson">
      <div class="creditsafe-modal-subtitle mt-4 pt-2 mb-2">{{ $t('modal.select_person') }}</div>
      <SearchInput
        v-model="personRecord.person.id"
        field-name="id"
        :entities="computedContactPersons"
        :label-content="$t('contact_persons.last_name')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @select-item="handleSetPersonRecord"
      />
      <div class="creditsafe-modal-subtitle mt-4 pt-2 mb-2">
        <template v-if="!Number.isInteger(personRecord.person.id)">
          {{ $t('modal.add_person') }}
        </template>
        <template v-else>
          {{ $t('modal.update_person') }}
        </template>
      </div>
      <LabelSelect
        v-model="personRecord.person.salutation"
        :options="personSalutations"
        :errors="personErrorMessages['person.salutation']"
        field-name="value"
        :label-content="$t('contact_persons.salutation')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <LabelInput
        v-model="personRecord.person.initials"
        :errors="personErrorMessages['person.initials']"
        :label-content="$t('contact_persons.initials')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSavePerson"
      />
      <LabelInput
        v-model="personRecord.person.first_name"
        :errors="personErrorMessages['person.first_name']"
        :label-content="$t('contact_persons.first_name')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSavePerson"
      />
      <LabelInput
        v-model="personRecord.person.last_name"
        :errors="personErrorMessages['person.last_name']"
        :label-content="$t('contact_persons.last_name')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSavePerson"
      />
      <LabelInput
        v-model="personRecord.contact_detail.phone_primair"
        :errors="personErrorMessages['contact_detail.phone_primair']"
        :label-content="$t('general.phone')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSavePerson"
      />
      <LabelInput
        v-model="personRecord.contact_detail.email_address"
        :errors="personErrorMessages['contact_detail.email_address']"
        :label-content="$t('general.email')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSavePerson"
      />
      <LabelSelect
        v-model="personRecord.job_title"
        :errors="personErrorMessages['job_title']"
        :label-content="$t('contact_persons.job_title')"
        :options="TRANSLATED_JOB_TITLES"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <EnumSelect
        v-model="personRecord.person.competence"
        :errors="personErrorMessages['person.competence']"
        enum-name="competences"
        :label="$t('contact_persons.competence')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <MultiselectSearchInput
        v-model="personRecord.contact_types"
        :errors="personErrorMessages['contact_types']"
        :entities="enums.data['contact_types']"
        :label-content="$t('contact_persons.contact_type')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <BaseRow>
        <BaseColumn class="col-8">
          <FormFooter
            :pending="loadingPerson"
            class="d-flex footer-secondary justify-content-end mt-4 pt-2"
            :label-save="computedSavePersonButtonText"
            :disabled="_.isEqual(personRecord, originalPersonRecord) || loadingPerson"
            @save="handleSavePerson"
            @cancel="handleCancelPerson"
          />
        </BaseColumn>
      </BaseRow>
    </template>
    <template v-if="!isPerson">
      <div class="creditsafe-modal-subtitle mt-4 pt-2 mb-2">{{ $t('modal.creditsafe_retrieve_company') }}</div>
      <MultiselectSearchInput
        v-model="creditsafeRecord.countries"
        :entities="countriesKeyValue"
        :label-content="$t('address.country')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        :tooltip-content="$t('tooltip.creditsafe_select_all_countries')"
        :errors="creditsafeErrorMessages['countries']"
      />
      <LabelInput
        v-model="creditsafeRecord.regNo"
        :label-content="$t('general.company_registration_number')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        :errors="creditsafeErrorMessages['company_registration_number']"
        :disabled="!!creditsafeRecord.vatNo"
      />
      <LabelInput
        v-model="creditsafeRecord.vatNo"
        :label-content="$t('general.vat_number')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        :disabled="!!creditsafeRecord.regNo"
      />
      <div
        v-if="creditsafeErrorMessages['record.all']"
        class="form-text text-danger error-messages mt-4"
      >
        {{ creditsafeErrorMessages['record.all'][0] }}
      </div>
      <BaseRow>
        <BaseColumn class="col-8">
          <div class="d-flex footer-secondary justify-content-end mt-3">
            <PrimaryButton
              :loading="loadingCompanies"
              @click="handleFetchCreditsafeCompanies"
            >
              {{ $t('general.retrieve') }}
            </PrimaryButton>
          </div>
        </BaseColumn>
      </BaseRow>
      <CreditsafeSearchCompaniesResults
        v-if="creditsafeFetchedCompanies.length > 1"
        :record="creditsafeFetchedCompanies"
        :loading="loadingGetCreditsafeCompany"
        @submit="handleFetchCreditsafeCompany"
      />
      <div class="creditsafe-modal-subtitle mt-4 pt-2 mb-2">{{ $t('modal.select_company') }}</div>
      <SearchInput
        v-model="companyRecord.id"
        field-name="id"
        :entities="computedCompanies"
        :label-content="$t('general.company_name')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @select-item="handleSetCompanyRecord"
      />
      <div class="creditsafe-modal-subtitle mt-5 mb-2">
        <template v-if="!Number.isInteger(companyRecord.id)">
          {{ $t('modal.add_company') }}
        </template>
        <template v-else>
          {{ $t('modal.update_company') }}
        </template>
        <BaseSpinner
          v-if="loadingGetCompany"
          type="primary"
          small
          class="ms-1"
        />
      </div>
      <LabelInput
        v-model="companyRecord.name"
        :errors="companyErrorMessages['name']"
        :label-content="$t('general.company_name')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        :disabled="!!creditsafeRecord.regNo"
        @enter="handleSaveCompany"
      />
      <SearchInput
        v-model="companyRecord.legal_form.name"
        :entities="computedLegalForms"
        :errors="companyErrorMessages['legal_form.name']"
        :disallow-custom-entry="false"
        :label-content="$t('company.legal_form')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <CompanyRegistrationNumberNumberInput
        v-model="companyRecord.company_registration_number"
        :label-content="$t('general.company_registration_number')"
        :errors="companyErrorMessages['company_registration_number']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSaveCompany"
      />
      <LabelInput
        v-model="companyRecord.registered_office"
        :label-content="$t('address.registered_office')"
        :errors="companyErrorMessages['registered_office']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSaveCompany"
      />
      <LabelInput
        v-model="companyRecord.address.address"
        :label-content="$t('address.address_information')"
        :errors="companyErrorMessages['address.address']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSaveCompany"
      />
      <LabelInput
        v-model="companyRecord.address.postal_code"
        :label-content="$t('address.postal_code')"
        :errors="companyErrorMessages['address.postal_code']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSaveCompany"
      />
      <LabelInput
        v-model="companyRecord.address.city"
        :label-content="$t('address.city')"
        :errors="companyErrorMessages['address.city']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSaveCompany"
      />
      <SearchInput
        v-model="companyRecord.address.country_id"
        field-name="id"
        :entities="mapCountries()"
        :errors="companyErrorMessages['address.country_id']"
        :label-content="$t('address.country')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <LabelToggleSwitch
        v-model="companyRecord.directorIsAPerson"
        :label-content="$t('modal.director_is_a_person')"
      />
      <SearchInput
        v-if="companyRecord.directorIsAPerson"
        v-model="companyRecord.director_id"
        :errors="companyErrorMessages['director_id']"
        field-name="id"
        :entities="computedDirectorContactPersons"
        :label-content="$t('modal.person')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <BaseRow>
        <BaseColumn class="col-8">
          <FormFooter
            :pending="loadingSaveCompany"
            class="d-flex footer-secondary justify-content-end mt-4 pt-2"
            :label-save="computedSaveCompanyButtonText"
            :disabled="_.isEqual(companyRecord, originalCompanyRecord) || loadingSaveCompany"
            @save="handleSaveCompany"
            @cancel="handleCancelCompany"
          />
        </BaseColumn>
      </BaseRow>
    </template>
  </BaseModal>
</template>

<script setup>
import MultiselectSearchInput from '@/components/form-controls/Multiselect/MultiselectSearchInput.vue';
import LabelInput from '@/components/form/LabelInput.vue';
import { useEnumerationsStore } from '@/stores/enumerations';
import BaseModal from '@/components/BaseModal.vue';
import { useSetToast } from '@/composables/UseToast';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import apiClient from '@/services/ApiClient';
import LabelToggleSwitch from '@/components/form/LabelToggleSwitch.vue';
import SearchInput from '@/components/form-controls/SearchInput/SearchInput.vue';
import CompanyRegistrationNumberNumberInput from '@/components/form-controls/CompanyRegistrationNumberNumberInput.vue';
import CreditsafeSearchCompaniesResults from '@/forms/sales/sales-status/creditsafe/CreditsafeSearchCompaniesResults.vue';
import LabelSelect from '@/components/form/LabelSelect.vue';
import EnumSelect from '@/components/form-controls/EnumSelect.vue';
import PersonSalutationConstants from '@/configs/constants/PersonSalutations';
import { DIRECTOR, DIRECTOR_SHAREHOLDER, SHAREHOLDER, TRANSLATED_JOB_TITLES } from '@/configs/constants/JobTitles';
import { MISC } from '@/configs/constants/ContactTypes';
import {
  useMapContactPersons,
  useSetCompanyContactPersons,
  useSetLogbookItems,
} from '@/composables/UseProspectViewData';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import { ORGANOGRAM, PROSPECT } from '@/configs/constants/CompanyTypes';
import { BaseColumn, BaseRow, BaseSpinner } from '@impact-factoring/impact-branding';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { email, required } from '@vuelidate/validators';
import { useRouter } from 'vue-router';

const props = defineProps({
  initialCompanyId: {
    type: Number,
    default: null,
  },
  initialPersonId: {
    type: Number,
    default: null,
  },
  initialSalesStatusCompanyId: {
    type: Number,
    default: null,
  },
  initialContactPersons: {
    type: Object,
    default: () => ({}),
  },
  cardId: {
    type: String,
    default: null,
  },
});
const emit = defineEmits(['selectContactPerson', 'selectCompany', 'close']);

const router = useRouter();
const { t } = useI18n();
const enums = useEnumerationsStore();

const creditsafeFetchedCompanies = ref([]);
const creditsafeErrorMessages = ref([]);
const companyErrorMessages = ref([]);
const personErrorMessages = ref([]);
const loadingCompanies = ref(false);
const loadingSaveCompany = ref(false);
const loadingGetCompany = ref(false);
const loadingGetCreditsafeCompany = ref(false);
const loadingPerson = ref(false);
const countriesKeyValue = ref({});
const isPerson = ref(false);
const salesStatusCompanyId = ref(props.initialSalesStatusCompanyId);
const showConfirmModal = ref(false);

const personSalutations = Object.entries(PersonSalutationConstants).map((entry) => {
  return { id: entry[0], value: entry[1] };
});
const labelColumnClasses = 'col-md-3 col-12';
const inputColumnClasses = 'col-md-5 col-12';
const contactPersons = ref(props.initialContactPersons);
const originalCompanyRecord = ref({});
const originalPersonRecord = ref({});
let companyValidationRules = {};
let personValidationRules = {};

const companyRules = {
  name: { required },
};
const personRules = {
  person: {
    last_name: { required },
  },
  contact_detail: {
    email_address: { email },
  },
};

const companyRecord = ref({
  id: null,
  type: ORGANOGRAM,
  name: null,
  company_registration_number: null,
  registered_office: null,
  director_id: null,
  address: {
    address: null,
    postal_code: null,
    city: null,
    country_id: null,
  },
  legal_form: {
    name: null,
  },
  directorIsAPerson: false,
});

const creditsafeRecord = ref({
  countries: [],
  regNo: null,
  vatNo: null,
  creditsafe_company: {},
});

const personRecord = ref({
  id: null,
  job_title: null,
  person: {
    id: null,
    salutation: null,
    initials: null,
    first_name: null,
    last_name: null,
    competence: null,
  },
  contact_detail: {
    phone_primair: null,
    email_address: null,
  },
  contact_types: [],
});

const computedContactPersons = computed(() => {
  return useMapContactPersons(contactPersons.value);
});

const computedDirectorContactPersons = computed(() => {
  return getDirectorContactPersons();
});

const computedCompanies = computed(() => {
  return Object.values(enums.data.companies)
    .filter((company) => company.type === ORGANOGRAM || company.type === PROSPECT)
    .map((company) => {
      let companyObject = {
        id: company.id,
        name: company.name,
      };

      const duplicateName = Object.values(enums.data.companies).filter(
        (filteredCompany) => filteredCompany.name === company.name
      );

      if (duplicateName.length > 1) {
        companyObject = {
          ...companyObject,
          uniqueIdentifier: company.company_registration_number,
        };
      }
      return companyObject;
    });
});

const computedLegalForms = computed(() => {
  return Object.entries(enums.data.legal_forms).map((legalForm) => {
    return {
      id: parseInt(legalForm[0]),
      name: legalForm[1],
    };
  });
});

const computedSaveCompanyButtonText = computed(() => {
  if (!Number.isInteger(companyRecord.value?.id)) {
    return t('modal.create');
  }

  return t('modal.update');
});

const computedSavePersonButtonText = computed(() => {
  if (!Number.isInteger(personRecord.value?.person?.id)) {
    return t('modal.create');
  }

  return t('modal.update');
});

const companyOrganogramCardsExcludingCurrentCard = computed(() => {
  return companyRecord.value.organogram_cards?.filter((card) => card.card_id !== props.cardId);
});

onBeforeMount(async () => {
  originalCompanyRecord.value = structuredClone(companyRecord.value);
  originalPersonRecord.value = structuredClone(personRecord.value);

  const contactTypes = Object.entries(enums.data['contact_types']).filter((contactType) => {
    return contactType[1] === MISC;
  });
  personRecord.value.contact_types = [{ id: parseInt(contactTypes[0][0]), name: contactTypes[0][1] }];

  if (props.initialCompanyId) {
    await handleSetCompanyRecord(props.initialCompanyId);
  }

  if (props.initialPersonId) {
    isPerson.value = true;
    await handleSetPersonRecord(props.initialPersonId);
  }

  countriesKeyValue.value = getCountries();

  creditsafeRecord.value.countries = [
    {
      id: 24,
      name: 'Nederland',
    },
  ];

  originalCompanyRecord.value = structuredClone(companyRecord.value);
  originalPersonRecord.value = structuredClone(personRecord.value);
});

async function handleSaveCompany() {
  if (_.isEqual(companyRecord.value, originalCompanyRecord.value) || loadingSaveCompany.value) {
    return;
  }

  await setCompanyErrorMessages();

  if (Object.keys(companyErrorMessages.value).length) {
    return;
  }

  if (
    !showConfirmModal.value &&
    companyRecord.value?.id &&
    (companyRecord.value?.sales_status?.id || !!getProspectOrganogramString())
  ) {
    openConfirmModal();
    return;
  }

  try {
    loadingSaveCompany.value = true;

    const index = Object.entries(enums.data.legal_forms).findIndex((legalForm) => {
      return legalForm[1] === companyRecord.value.legal_form.name;
    });

    if (index === -1) {
      companyRecord.value.legal_form.id = null;
    }

    if (!companyRecord.value.directorIsAPerson) {
      companyRecord.value.director_id = null;
    }

    let response;
    const recordCopy = structuredClone(companyRecord.value);

    if (!recordCopy.id) {
      response = await saveCompany(recordCopy);
      useSetToast('success', t('toast.success.company_successfully_created'));
    } else {
      response = await updateCompany(recordCopy, recordCopy.id);
      useSetToast('success', t('toast.success.company_successfully_updated'));
    }

    useSetLogbookItems(response.sales_status.customer_log);
    emit('selectCompany', { id: response.data.id, name: response.data.name });
    addOrUpdateCompaniesEnum(response);
    enums.addOrUpdateEnumKeyValue('legal_forms', response.data.legal_form.id, response.data.legal_form.name);

    companyRecord.value = response.data;
    companyRecord.value.directorIsAPerson = !!companyRecord.value.director_id;
    originalCompanyRecord.value = structuredClone(companyRecord.value);

    closeConfirmModal();
    closeModal();
  } catch (error) {
    companyErrorMessages.value = { ...companyErrorMessages.value, ...error?.response?.data?.errors };
    if (!companyRecord.value.id) {
      useSetToast('error', t('toast.error.creating_company') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while creating company: ', error);
    } else {
      useSetToast('error', t('toast.error.updating_company') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while updating company: ', error);
    }
  } finally {
    showConfirmModal.value = false;
    loadingSaveCompany.value = false;
  }
}

function handleCancelCompany() {
  companyRecord.value = structuredClone(originalCompanyRecord.value);
  companyErrorMessages.value = [];
}

async function handleSavePerson() {
  if (_.isEqual(personRecord.value, originalPersonRecord.value) || loadingPerson.value) {
    return;
  }

  await setPersonErrorMessages();

  if (Object.keys(personErrorMessages.value).length) {
    return;
  }

  try {
    loadingPerson.value = true;
    let recordCopy = structuredClone(personRecord.value);

    recordCopy = {
      contact_detail: recordCopy.contact_detail,
      contact_types: recordCopy.contact_types,
      job_title: recordCopy.job_title,
      ...recordCopy.person,
    };

    let savedContactPerson;
    let response;
    if (!personRecord.value?.person?.id) {
      delete recordCopy.id;
      response = await saveContactPerson(recordCopy);
      useSetToast('success', t('toast.success.person_successfully_created'));

      contactPersons.value = mapContactPersons(response.contact_persons);
      savedContactPerson = contactPersons.value[contactPersons.value.length - 1];
    } else {
      response = await updateContactPerson(recordCopy, personRecord.value.id);
      useSetToast('success', t('toast.success.person_successfully_updated'));

      contactPersons.value = mapContactPersons(response.contact_persons);
      savedContactPerson = contactPersons.value.find((contactPerson) => contactPerson.person.id === recordCopy.id);
    }

    emit('selectContactPerson', { id: savedContactPerson.person.id, name: savedContactPerson.person.name });

    setContactPersonsAndLogbook({
      customerLogs: response.customer_log,
      contactPersons: contactPersons.value,
    });

    personRecord.value = savedContactPerson;
    originalPersonRecord.value = structuredClone(personRecord.value);

    closeModal();
  } catch (error) {
    personErrorMessages.value = { ...personErrorMessages.value, ...error?.response?.data?.errors };
    if (!personRecord.value.id) {
      useSetToast('error', t('toast.error.creating_the_person') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while creating contact person: ', error);
    } else {
      useSetToast('error', t('toast.error.updating_the_person') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while updating contact person: ', error);
    }
  } finally {
    loadingPerson.value = false;
  }
}

function handleCancelPerson() {
  personRecord.value = structuredClone(originalPersonRecord.value);
  personErrorMessages.value = [];
}

async function handleSetCompanyRecord(companyId) {
  if (!companyId) {
    if (!originalCompanyRecord.value.id) {
      return;
    }

    companyRecord.value.type = ORGANOGRAM;
    companyRecord.value.director_id = null;
    companyRecord.value.address_id = null;
    emit('selectCompany', { id: null, name: null });
    return;
  }

  try {
    loadingGetCompany.value = true;
    let response = await getCompany(companyId);

    if (response?.data) {
      let organogramCards = [];

      response.organogram_cards.forEach((card) => {
        organogramCards = [...organogramCards, card];
      });

      response.data.organogram_cards = organogramCards;
      response = response.data;
    }

    if (response) {
      personRecord.value.person.id = null;
      originalPersonRecord.value.person.id = null;

      companyRecord.value = response;
      companyRecord.value.directorIsAPerson = !!companyRecord.value.director_id;

      originalCompanyRecord.value = structuredClone(companyRecord.value);
      await setCompanyErrorMessages();
    }

    emit('selectCompany', { id: companyRecord.value.id, name: companyRecord.value.name });
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_company') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching company: ', error);
  } finally {
    loadingGetCompany.value = false;
  }
}

async function handleSetPersonRecord(personId) {
  if (!personId) {
    if (!originalPersonRecord.value.person?.id) {
      return;
    }

    personRecord.value.contact_detail.id = null;
    emit('selectContactPerson', { id: null, name: null });
    return;
  }

  const contactPerson = structuredClone(contactPersons.value).find((contactPerson) => {
    return contactPerson.person.id === personId;
  });

  if (!contactPerson?.person?.id) {
    personRecord.value.contact_detail.id = null;
    emit('selectContactPerson', { id: null, name: null });
    return;
  }

  companyRecord.value.id = null;
  originalCompanyRecord.value.id = null;

  personRecord.value = contactPerson;

  originalPersonRecord.value = structuredClone(personRecord.value);
  await setPersonErrorMessages();

  emit('selectContactPerson', { id: personRecord.value.person.id, name: personRecord.value.person.name });
}

async function getCompany(companyId) {
  return await apiClient.request('get', `/ifapi/company/${companyId}`);
}

function saveContactPerson(data) {
  return apiClient.request('post', `/ifapi/companies/${salesStatusCompanyId.value}/contactpersons`, null, data);
}

function updateContactPerson(data) {
  return apiClient.request(
    'put',
    `/ifapi/companies/${salesStatusCompanyId.value}/contactpersons/${data.id}`,
    null,
    data
  );
}

function saveCompany(data) {
  return apiClient.request(
    'post',
    `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/company`,
    null,
    data
  );
}

function updateCompany(data, companyId) {
  return apiClient.request(
    'put',
    `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/company/${companyId}`,
    null,
    data
  );
}

function mapContactPersons(contactPersons) {
  return contactPersons.map((contactPerson) => {
    contactPerson.person.competence = contactPerson.person.competence?.competence;
    return contactPerson;
  });
}

function mapCountries() {
  const countries = {};
  enums.data.countries.map((country) => {
    countries[country.id] = country.translation;
  });
  return countries;
}

function getCountries() {
  if (!enums.data.countries) {
    return [];
  }
  let countries = mapCountries();
  countries = {
    PLC: t('general.all_countries'),
    ...countries,
  };
  return countries;
}

async function setCompanyErrorMessages() {
  companyValidationRules = useMergeDefaultVuelidateValidationRules(companyRules, companyRecord.value);
  companyErrorMessages.value = await useVuelidateValidation(companyValidationRules, companyRecord.value);
}

async function setPersonErrorMessages() {
  personValidationRules = useMergeDefaultVuelidateValidationRules(personRules, personRecord.value);
  personErrorMessages.value = await useVuelidateValidation(personValidationRules, personRecord.value);
}

function setContactPersonsAndLogbook(object) {
  useSetCompanyContactPersons(object.contactPersons);
  useSetLogbookItems(object.customerLogs);
}

function addOrUpdateCompaniesEnum(response) {
  const companyEnum = {
    id: response.data.id,
    type: response.data.type,
    name: response.data.name,
    company_registration_number: response.data.company_registration_number,
  };

  enums.addOrUpdateEnumObject('companies', companyEnum);
}

function closeModal() {
  emit('close');
}

async function handleFetchCreditsafeCompanies() {
  if (!validation()) {
    return;
  }

  try {
    loadingCompanies.value = true;

    const urlParameters = generateUrlParameters();
    const creditSafeCompanies = await getFoundCreditsafeCompanies(urlParameters);

    if (!creditSafeCompanies[0]) {
      loadingCompanies.value = false;
      return;
    }

    if (creditSafeCompanies.length > 1) {
      creditsafeFetchedCompanies.value = creditSafeCompanies;
      loadingCompanies.value = false;
      return;
    }

    const company = mapSalesStatusObject(creditSafeCompanies[0]);
    companyRecord.value = _.merge(companyRecord.value, company);
  } catch (error) {
    creditsafeErrorMessages.value = { ...creditsafeErrorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.retrieving_creditsafe_companies') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching creditsafe companies: ', error);
  } finally {
    loadingCompanies.value = false;
  }
}

async function handleFetchCreditsafeCompany(creditsafeCompany) {
  if (!validation()) {
    return;
  }

  try {
    loadingGetCreditsafeCompany.value = true;
    let company = await getCreditsafeCompany(creditsafeCompany);
    company = mapSalesStatusObject(company);
    companyRecord.value = _.merge(companyRecord.value, company);
  } catch (error) {
    creditsafeErrorMessages.value = { ...creditsafeErrorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.retrieving_creditsafe_company') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching creditsafe company: ', error);
  } finally {
    loadingGetCreditsafeCompany.value = false;
  }
}

function getFoundCreditsafeCompanies(urlParameters) {
  return apiClient.request('get', '/ifapi/integrations/creditsafe/companies', {
    requestSource: urlParameters,
  });
}

function getCreditsafeCompany(company) {
  return apiClient.request('get', `/ifapi/integrations/creditsafe/companies/${company.id}`, {
    requestSource: getUrlParameters(company),
  });
}

function getUrlParameters(company) {
  const language = company.country === 'NL' ? 'NL' : 'EN';
  return `?language=${language}`;
}

function validation() {
  if (!creditsafeRecord.value.countries.length) {
    creditsafeErrorMessages.value['record.countries'] = [t('validation.value_is_required')];
    return false;
  } else {
    delete creditsafeErrorMessages.value['record.countries'];
  }

  if (!(creditsafeRecord.value.regNo || creditsafeRecord.value.vatNo)) {
    creditsafeErrorMessages.value['record.all'] = [t('validation.kvk_or_btw_is_required')];
    return false;
  } else {
    delete creditsafeErrorMessages.value['record.all'];
  }
  return true;
}

function generateUrlParameters() {
  const parameters = filterFilledFields();
  return createUrlParameters(parameters);
}

function filterFilledFields() {
  return Object.entries(companyRecord.value).filter((field) => {
    return field[1]?.length;
  });
}

function createUrlParameters(parameters) {
  let urlParameters = '';
  parameters.forEach((parameter, index) => {
    if (index !== 0) {
      urlParameters += '&';
    }
    if (parameter[0] === 'countries') {
      urlParameters += createCountriesUrl(parameter);
      return;
    }
    if (parameter[0] === 'vatNo' && parameter[1].startsWith('NL')) {
      urlParameters += parameter[0] + '=' + stripNLPrefixFromVatNo(parameter[1]);
      return;
    }

    urlParameters += parameter[0] + '=' + parameter[1];
  });
  return '?' + urlParameters;
}

function createCountriesUrl(countries) {
  let enumCountry;
  let parameterCountryUrl = countries[0] + '=';

  countries[1].forEach((country, index) => {
    enumCountry = enums.data.countries.find((enumCountry) => {
      return enumCountry.id === country.id;
    });

    if (country.id === 'PLC') {
      enumCountry = { iso: country.id };
    }

    parameterCountryUrl += enumCountry.iso;

    if (index !== countries[1].length - 1) {
      parameterCountryUrl += ',';
    }
  });
  return parameterCountryUrl;
}

function stripNLPrefixFromVatNo(string) {
  return string.replace('NL', '');
}

function getCountry(creditsafeCompany) {
  return enums.data.countries.find((country) => country.iso === creditsafeCompany.country);
}

function mapSalesStatusObject(creditsafeCompany) {
  const country = getCountry(creditsafeCompany);
  const legalForm = Object.entries(enums.data.legal_forms).find((legalForm) => {
    return legalForm[1] === creditsafeCompany.legalForm;
  });
  let address = creditsafeCompany?.street;

  if (creditsafeCompany?.houseNumber) {
    address = creditsafeCompany?.street + ' ' + creditsafeCompany?.houseNumber;
  }

  return {
    name: creditsafeCompany.name,
    legal_form: {
      id: legalForm?.[0] ? parseInt(legalForm[0]) : null,
      name: creditsafeCompany.legalForm,
    },
    registered_office: creditsafeCompany.registeredOffice,
    phone: creditsafeCompany.telephone,
    company_registration_number: creditsafeCompany.regNo,
    address: {
      address: address,
      postal_code: creditsafeCompany.postalCode,
      city: creditsafeCompany.city,
      country_id: country?.id ? parseInt(country.id) : null,
    },
  };
}

function openConfirmModal() {
  showConfirmModal.value = true;
}

function closeConfirmModal() {
  showConfirmModal.value = false;
}

function getProspectOrganogramString() {
  let string = '';
  _.uniqBy(companyOrganogramCardsExcludingCurrentCard.value, 'customer_organogram.sales_status.id')
    .filter((card) => card.card_id !== props.cardId)
    .forEach((card, key) => {
      string += '<b>' + card.customer_organogram.sales_status.company.name_plus_legal_form + '</b>';

      if (
        key !==
        _.uniqBy(companyOrganogramCardsExcludingCurrentCard.value, 'customer_organogram.sales_status.id')?.length - 1
      ) {
        string += ', ';
      }
      string += '<br />';
    });

  return string;
}

function getDirectorContactPersons() {
  if (companyRecord.value.type === PROSPECT) {
    const contactPersons = isDirectorOrShareholder(companyRecord.value.contact_persons);
    return useMapContactPersons(contactPersons);
  }

  if (companyRecord.value.type === ORGANOGRAM) {
    let contactPersons = props.initialContactPersons;

    companyRecord.value?.organogram_cards
      ?.filter((card) => card?.customer_organogram?.sales_status?.company?.contact_persons?.length)
      .forEach((card) => {
        contactPersons = [...contactPersons, ...card.customer_organogram.sales_status.company.contact_persons];
      });

    contactPersons = isDirectorOrShareholder(contactPersons);
    return useMapContactPersons(contactPersons);
  }

  return [];
}

function isDirectorOrShareholder(contactPersons) {
  return contactPersons.filter(
    (contactPerson) =>
      contactPerson.job_title === DIRECTOR ||
      contactPerson.job_title === DIRECTOR_SHAREHOLDER ||
      contactPerson.job_title === SHAREHOLDER
  );
}
</script>
