<template>
  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <CreditProposalDetailsForm
    v-else-if="record"
    :initial-record="record"
    :contact-persons="companyRecord.contact_persons"
    @save="updateLogbookAndPreview"
  />
</template>

<script setup>
import apiClient from '@/services/ApiClient';
import CreditProposalDetailsForm from '@/forms/sales/sales-status/credit-proposal/CreditProposalDetailsForm.vue';
import { useSetToast } from '@/composables/UseToast';
import {
  useGetCompany,
  useGetCreditProposalPreview,
  useSetCreditProposalPreview,
  useSetLogbookItems,
} from '@/composables/UseProspectViewData';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();

const record = ref(null);
const companyRecord = ref(null);
const loading = ref(false);

watch(
  () => router.currentRoute.value,
  (to, from) => {
    if (from.meta.preview !== 'credit_proposal') {
      getAndSetCreditProposalPreview();
    }
    if (to.meta.preview !== 'credit_proposal') {
      useSetCreditProposalPreview('');
    }
  }
);

onBeforeMount(async () => {
  try {
    loading.value = true;
    record.value = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal`
    );
    companyRecord.value = useGetCompany();

    if (!record.value.data) {
      record.value.data = createCreditProposalRecord();
    }
  } catch (error) {
    useSetToast(
      'error',
      t('toast.error.retrieving_credit_proposal_details') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching credit proposal details: ', error);
  } finally {
    loading.value = false;
  }

  if (!useGetCreditProposalPreview()) {
    await getAndSetCreditProposalPreview();
  }
});

async function getAndSetCreditProposalPreview() {
  try {
    const creditProposalPreviewRecord = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/preview`
    );
    useSetCreditProposalPreview(creditProposalPreviewRecord);
  } catch (error) {
    useSetToast(
      'error',
      t('toast.error.retrieving_credit_proposal_preview') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching credit proposal preview: ', error);
  }
}

function createCreditProposalRecord() {
  return {
    person_id: null,
    limit_costs: null,
    deviating_provisions: [],
    seasonal_influences: null,
    seasonal_influences_description: null,
    billing_interval: null,
    debtors_appear_as_creditors: null,
    debtors_appear_as_creditors_description: null,
    nature_of_billing: null,
    bonus_or_payment_discounts: null,
    bonus_or_payment_discounts_description: null,
    credit_insured_debtors: null,
    credit_insured_debtors_description: null,
    write_offs_debtors_past_three_years: null,
    doubtful_debtors: null,
    doubtful_debtors_description: null,
    general_delivery_and_payment_conditions: null,
    portfolio_concentrations: null,
    portfolio_concentrations_description: null,
    order_to_cash_process: null,
    general_description: null,
    funding_request_reason: null,
    current_funder_description: null,
    requested_funding_description: null,
    financials_description: null,
    background_request_description: null,
    main_risks: null,
    pricing_description: null,
    decision_description: null,
    actions_to_be_taken: null,
    file_ids: null,
  };
}

function updateLogbookAndPreview(object) {
  useSetLogbookItems(object.customerLogs);
  if (object?.creditProposalPreview) {
    useSetCreditProposalPreview(object.creditProposalPreview);
  }
}
</script>
