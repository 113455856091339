<template>
  <div
    :class="wrapperClasses"
    @click="handleEmitClick"
  >
    <label
      v-if="props.labelContent"
      :class="[labelClasses, { 'd-flex align-items-center': alignLabelCenter }]"
      :for="$attrs.for"
    >
      {{ props.labelContent }}
      <BaseTooltip
        class="ms-1"
        :content="props.tooltipContent"
      />
    </label>
    <div :class="inputClasses">
      <slot />
    </div>
    <div
      v-if="props.extraColumns"
      :class="extraColumnClasses"
    >
      <div
        v-if="props.postLabel"
        class="form-text text-body mt-lg-0"
      >
        {{ props.postLabel }}
      </div>
      <slot name="extra" />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import BaseTooltip from '@/components/general/BaseTooltip.vue';
import { computed } from 'vue';

const props = defineProps({
  labelContent: {
    type: String,
    default: null,
  },
  postLabel: {
    type: String,
    default: null,
  },
  wrapperClass: {
    type: [String, Array, Object],
    default: null,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  extraColumns: {
    type: String,
    default: null,
  },
  alignLabelCenter: {
    type: Boolean,
    default: true,
  },
  notInline: {
    type: Boolean,
    default: false,
  },
  notNarrow: {
    type: Boolean,
    default: false,
  },
  tooltipContent: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['click']);

const wrapperClasses = computed(() => {
  return [props.wrapperClass, { row: !props.notInline }];
});
const labelClasses = computed(() => {
  let columns = props.labelColumns ?? 'col-12 col-lg-4 col-xl-2';
  return {
    'form-label': props.notInline,
    [`col-form-label ${columns}`]: !props.notInline,
    'form-label--narrow': !props.notNarrow,
  };
});
const inputClasses = computed(() => {
  let columns = props.inputColumns ?? 'col-12 col-lg-3 col-xl-2';
  return {
    'ms-auto': props.notInline,
    [`align-self-top ${columns}`]: !props.notInline,
    'w-100': !props.labelContent,
  };
});
const extraColumnClasses = computed(() => {
  let columns = props.extraColumns ?? 'col-auto';
  return {
    [`align-self-top ${columns}`]: !props.notInline,
    'ps-lg-0': props.postLabel,
  };
});

function handleEmitClick() {
  emit('click');
}
</script>
