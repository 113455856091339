<template>
  <BaseTable
    :headers="headers"
    :hoverable="hoverable"
    :bordered="bordered"
    :borderless="borderless"
    :striped="striped"
  >
    <slot name="headers" />
    <slot v-if="ready" />
    <BaseSpinner
      v-if="!ready"
      type="primary"
      class="mt-2"
    />
  </BaseTable>
</template>

<script setup>
import BaseTable from '@/components/tables/BaseTable.vue';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onMounted, ref } from 'vue';

const ready = ref(false);

const props = defineProps({
  headers: {
    type: Array,
    default: () => [],
  },
  config: {
    type: Object,
    default: null,
  },
  hoverable: {
    type: Boolean,
    default: true,
  },
  bordered: {
    type: Boolean,
    default: false,
  },
  borderless: {
    type: Boolean,
    default: false,
  },
  striped: {
    type: Boolean,
    default: false,
  },
});

onMounted(async () => {
  if (props.config) {
    await datalist.list(props.config);
  }
  ready.value = true;
});
</script>
