<template>
  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <div
    v-else-if="record"
    class="debtor-analyses d-flex flex-column body-height-minus-tabs h-100"
  >
    <CreditorAnalysesForm
      :initial-record="record"
      @save="updateLogbook"
    />
  </div>
</template>

<script setup>
import CreditorAnalysesForm from '@/forms/sales/sales-status/debtor-creditor-analyses/CreditorAnalysesForm.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import { useAmountSuffixConvertCentsToAmount } from '@/composables/UseNumberManipulation';
import { useSetLogbookItems } from '@/composables/UseProspectViewData';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();

const record = ref(null);
const loading = ref(false);

onBeforeMount(async () => {
  try {
    loading.value = true;
    record.value = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/creditor_analyses`
    );

    record.value = useAmountSuffixConvertCentsToAmount(record.value);
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_creditor_analyses') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching creditor analyses: ', error);
  } finally {
    loading.value = false;
  }
});

function updateLogbook(object) {
  useSetLogbookItems(object.customerLogs);
}
</script>
