<template>
  <TransitionGroup name="toast">
    <div
      v-if="useToasts.length"
      key="toastContainer"
      class="toast-container position-fixed bottom-0 end-0 p-4"
    >
      <TransitionGroup name="toast">
        <template
          v-for="(toast, key) in useToasts"
          :key="key"
        >
          <BaseToast
            :toast-key="key"
            :type="toast.type"
            :message="toast.message"
            :class="{ 'toast-visiblity-hidden': !toast.show }"
            @mount="showToast"
          />
        </template>
      </TransitionGroup>
    </div>
  </TransitionGroup>
</template>

<script setup>
import BaseToast from '@/components/toasts/BaseToast.vue';
import { useGetToasts, useSetToasts } from '@/composables/UseToast';
import { ref } from 'vue';

const useToasts = ref(useGetToasts());

function showToast(key) {
  useToasts.value[key].show = true;
  setTimeout(() => {
    useToasts.value[key].show = false;
  }, 2000);
  setTimeout(() => {
    const toastShowIndex = useToasts.value.findIndex((toast) => toast.show);
    if (toastShowIndex === -1) {
      useSetToasts([]);
    }
  }, 2300);
}
</script>
