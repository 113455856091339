<template>
  <LabelInput
    v-if="props.labelContent"
    v-model="computedModelValue"
    :label-content="props.labelContent"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    :wrapper-class="props.wrapperClass"
    :tooltip-content="props.tooltipContent"
    :not-inline="props.notInline"
    :not-narrow="props.notNarrow"
    :errors="props.errors"
    @keydown.enter="onKeyDownEnter"
  />
  <BaseInput
    v-else
    v-model="computedModelValue"
    :not-narrow="props.notNarrow"
    :model-value="props.modelValue"
    :errors="props.errors"
    @keydown.enter="onKeyDownEnter"
  />
</template>

<script setup>
import LabelInput from '@/components/form/LabelInput.vue';
import BaseInput from '@/components/form/BaseInput.vue';
import { computed } from 'vue';

const props = defineProps({
  labelContent: {
    type: String,
    default: '',
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  wrapperClass: {
    type: [String, Array, Object],
    default: null,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  notInline: {
    type: Boolean,
    default: false,
  },
  notNarrow: {
    type: Boolean,
    default: false,
  },
  tooltipContent: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue', 'enter']);

const computedModelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (!value) {
      emit('update:modelValue', null);
      return;
    }
    emit('update:modelValue', value);
  },
});

function onKeyDownEnter() {
  emit('enter');
}
</script>
