<template>
  <BaseModal
    :show="showModal"
    :modal-title="$t('modal.creditsafe_data')"
    min-height="530px"
    custom-footer
    @close="closeModal"
  >
    <div>
      <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.id') }}</div>
      <div class="d-inline-flex ms-4 creditsafe-modal-value">{{ props.record.id }}</div>
    </div>
    <div>
      <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.company_name') }}</div>
      <div class="d-inline-flex ms-4 creditsafe-modal-value">{{ props.record.name }}</div>
    </div>
    <div>
      <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.registration_name') }}</div>
      <div class="d-inline-flex ms-4 creditsafe-modal-value">{{ props.record.name }}</div>
    </div>
    <div>
      <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('client.legal_form') }}</div>
      <div class="d-inline-flex ms-4">{{ props.record.legalForm }}</div>
    </div>
    <div>
      <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.registration_number') }}</div>
      <div class="d-inline-flex ms-4 creditsafe-modal-value">{{ props.record.regNo }}</div>
    </div>
    <div>
      <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.vat_number') }}</div>
      <div class="d-inline-flex ms-4 creditsafe-modal-value">{{ props.record.vatNo }}</div>
    </div>
    <div class="d-flex">
      <div class="creditsafe-modal-subtitle">{{ $t('address.contact_address') }}</div>
      <div class="ms-4">
        <div class="text-break">{{ props.record.street }} {{ props.record.houseNumber }}</div>
        <div class="text-break">{{ props.record.postalCode }} {{ props.record.city }}</div>
      </div>
    </div>
    <div>
      <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('ui.status') }}</div>
      <div class="d-inline-flex ms-4">{{ props.record.status }}</div>
    </div>
    <div class="flex-grow-1">
      <div class="d-inline-flex creditsafe-modal-subtitle">{{ $t('general.last_adjustment') }}</div>
      <div class="d-inline-flex ms-4">{{ props.record.dateOfLatestChange }}</div>
    </div>
    <div class="text-description text-secondary pb-3">
      {{ $t('description.creditsafe_modal_description') }}
    </div>
    <template #modalFooter>
      <PrimaryButton @click="submit">
        <template v-if="!isCreditsafeData">
          {{ $t('customer_data.retrieve_company') }}
        </template>
        <template v-else>
          {{ $t('ui.next') }}
        </template>
      </PrimaryButton>
    </template>
  </BaseModal>
</template>

<script setup>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import BaseModal from '@/components/BaseModal.vue';
import { ref } from 'vue';

const props = defineProps({
  record: {
    type: Object,
    default: () => ({}),
  },
  isCreditsafeData: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close', 'submit', 'cancel']);

const showModal = ref(true);

function closeModal() {
  showModal.value = false;
  emit('close', showModal.value);
}

function submit() {
  showModal.value = false;
  emit('submit');
}
</script>
