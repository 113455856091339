import App from '@/App.vue';
import router from '@/router';
import i18n from '@/lang/i18n-config';
import '@/assets/scss/main.scss';
import 'bootstrap/dist/js/bootstrap.bundle';
import { sanitizeHtml } from '@/modules/directives';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import vClickOutside from 'click-outside-vue3';
import axios from 'axios';

const app = createApp(App);
const pinia = createPinia();

axios.defaults.baseURL = process.env.VUE_APP_IMPACTFACTORING_API_URL;
axios.defaults.withCredentials = true;

app.use(pinia);
app.use(router);
app.use(vClickOutside);
app.use(i18n);

app.mount('#app');

/** Sanitize raw HTML before injecting it into the DOM using DOMPurify */
app.directive('safe-html', sanitizeHtml);
