import { isMac } from '@/modules/utils';

let isCursorBeforeDecimalSeparator = null;
const allowedKeyboardNumberKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];

const allowedKeyboardControlKeys = [
  'ArrowRight',
  'ArrowLeft',
  'Backspace',
  'Delete',
  'Tab',
  'Home',
  'End',
  'PageUp',
  'PageDown',
  'F5',
];

const allowKeyboardControlCombinationKeys = ['a', 'c', 'v', 'z', 'y', 'x', 'r'];

export function useOnFocus(event) {
  event.target.select();
}

export function useOnClick(event, decimalAmount = 0) {
  return setIsCursorBeforeDecimalSeparator(event, decimalAmount);
}

export function useOnPaste(event, decimalAmount = 0) {
  return validatePastedText(event, decimalAmount);
}

export function useOnKeyUp(event, decimalAmount = 0) {
  setIsCursorBeforeDecimalSeparator(event, decimalAmount);
  return validateKeyUpInput(event, decimalAmount);
}

export function useOnKeyDown(event, decimalAmount = 0) {
  return validateKeyDownInput(event, decimalAmount);
}

function validatePastedText(event, decimalAmount = 0) {
  const pastedText = event.clipboardData.getData('text');
  const replacedText = replaceSelectedText(event, pastedText);
  if (numbersAndDecimalsAreAllowed(replacedText, decimalAmount) || onlyNumbersAreAllowed(pastedText, decimalAmount)) {
    return event.target.value;
  }
  event.preventDefault();
  return false;
}

function validateKeyUpInput(event, decimalAmount = 0) {
  if (
    (!getAllowedKeyboardControlKeys(event) && !getAllowedKeyboardNumberKeys(event)) ||
    (decimalAmount && event.key !== ',')
  ) {
    event.preventDefault();
  }
  return event.target.value;
}

function validateKeyDownInput(event, decimalAmount = 0) {
  if (
    (!getAllowedKeyboardControlKeys(event) && !getAllowedKeyboardNumberKeys(event)) ||
    (getCtrlOrMetaKey(event) && !allowKeyboardControlCombinationKeys.includes(event.key))
  ) {
    if (insertIsDecimalAllowed(event, decimalAmount)) {
      return event.target.value;
    }
    event.preventDefault();
  }

  if (!decimalAmount) {
    return validateKeyboardKeys(event);
  } else {
    return validateNumbersAndDecimalsInput(event, decimalAmount);
  }
}

function validateNumbersAndDecimalsInput(event, decimalAmount) {
  if (
    (!getAllowedKeyboardControlKeys(event) && !getAllowedKeyboardNumberKeys(event)) ||
    (getAllowedKeyboardNumberKeys(event) && !insertNumberAndIsDecimalAllowed(event, decimalAmount))
  ) {
    event.preventDefault();
  }
  return event.target.value;
}

function validateKeyboardKeys(event) {
  if (!getAllowedKeyboardControlKeys(event) && !getAllowedKeyboardNumberKeys(event)) {
    event.preventDefault();
  }
  return event.target.value;
}

export function getAllowedKeyboardControlKeys(event) {
  return (
    allowedKeyboardControlKeys.includes(event.key) ||
    (getCtrlOrMetaKey(event) && allowKeyboardControlCombinationKeys.includes(event.key))
  );
}

export function getAllowedKeyboardNumberKeys(event) {
  return allowedKeyboardNumberKeys.includes(event.key);
}

function setIsCursorBeforeDecimalSeparator(event, decimalAmount) {
  isCursorBeforeDecimalSeparator = getIsCursorBeforeDecimalSeparator(event, decimalAmount);
}

function getInputSelection(event) {
  return event.target.value.substring(event.target.selectionStart, event.target.selectionEnd);
}

function replaceSelectedText(event, newText) {
  const val = event.target.value.toString();
  return val.slice(0, event.target.selectionStart) + newText + val.slice(event.target.selectionEnd, val.length);
}

function getIsCursorBeforeDecimalSeparator(event, decimalAmount = 0) {
  return event.target.value.length - (decimalAmount + 1) >= event.target.selectionStart;
}

function insertIsDecimalAllowed(event, decimalAmount = 0) {
  let isDecimalAllowed = false;
  if (event.key === ',') {
    isDecimalAllowed = event.target.value.length - event.target.selectionEnd <= decimalAmount;
  }

  return (
    !!decimalAmount &&
    event.key === ',' &&
    (!event.target.value.toString().includes(',') || getInputSelection(event).includes(',')) &&
    isDecimalAllowed
  );
}

function insertNumberAndIsDecimalAllowed(event, decimalAmount = 0) {
  const regexNumbersAndDecimals = new RegExp(`^[\\d.]*,\\d{${decimalAmount},}$`, 'g');
  return (
    !regexNumbersAndDecimals.test(event.target.value) || !!getInputSelection(event) || isCursorBeforeDecimalSeparator
  );
}

function onlyNumbersAreAllowed(pastedText, decimalAmount = 0) {
  const regexNumbersOnly = new RegExp(/^[\d.]*$/, 'g');
  return !decimalAmount && regexNumbersOnly.test(pastedText);
}

function numbersAndDecimalsAreAllowed(replacedTextString, decimalAmount = 0) {
  const regexAllowNumbersAndDotsAndDecimalsPlusOne = new RegExp(`^[\\d+.]*,\\d{${decimalAmount + 1},}$`, 'g');
  let regexNumbersAndOneCommaOnly = new RegExp(`^[\\d.]*\\,?[\\d{${decimalAmount + 1}]*$`, 'g');

  return (
    decimalAmount &&
    !regexAllowNumbersAndDotsAndDecimalsPlusOne.test(replacedTextString) &&
    regexNumbersAndOneCommaOnly.test(replacedTextString)
  );
}

function getCtrlOrMetaKey(event) {
  return !isMac() ? event.ctrlKey : event.metaKey;
}
